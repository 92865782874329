import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'typescript-cookie'
import http,{baseUrl, baseUrl2} from "../../../http-common";
import { toast, ToastContainer } from "react-toastify";
import ReactPlayer from 'react-player';

export default function Content(){
    const [getCart, setCart] = useState([]);
    const [getUserId, SetUserId] = useState();
    const [getUseremail, setUsereMAIL] = useState(getCookie('user_email'));
    let MainValue = 0;
    const [getUserType, setUserTpe] = useState('');


    // console.log(getUseremail);
    let unique_id = getCookie('unique_id');    
    useEffect(() => {
        if(!getCookie('user_email') || getCookie('user_email') == '' || getCookie('user_email') == "undefined"){
            http.get('/cart/byuniqueid/'+unique_id).then(Response => {
                setCart(Response.data);
                // console.log(Response);
            }).catch(err => console.log(err));
        }else{            
            http.get('/users/byUserEmail/'+getUseremail).then(Response => {
                SetUserId(Response.data.id);
                SetUserId(Response.data.id);
                setUserTpe(Response.data.user_type);
                // console.log(Response.data);
                http.get('/cart/byuserid/'+Response.data.id).then(Response1 => {
                    setCart(Response1.data);
                    console.log(Response1.data);
                }).catch(err => console.log(err));
            }).catch(err => console.log(err));
        }       
    }, [])
    const deletecart = async (id:any, key:any) => {
        // console.log(id)
        let state = {...getCart};
        http.delete('/cart/deleteCart/'+id).then(Response => {
            toast.success('Successfully Deleted!',{position:toast.POSITION.TOP_RIGHT});
            console.log('Delted Sucssesfully');
            setTimeout(() => {
                window.location.reload();
            }, 2000); 
        })
        .catch(err => console.log(err));
    }

    const deleteProduct = async (id:any, key:any) => {
        // console.log(id)
        http.delete('/cart/deleteCartProduct/'+id).then(Response => {
            toast.success('Successfully Deleted!',{position:toast.POSITION.TOP_RIGHT});
            console.log('Delted Sucssesfully');
            setTimeout(() => {
                window.location.reload();
            }, 2000); 
        })
        .catch(err => console.log(err));
    }
    // console.log(getCart)
    return(
        <>                     
            {/*section start*/}
            <section className="section-b-space">
                <div className="container">
                    <div className="checkout-page">
                        <div className="checkout-form">
                            <form>
                                {getCart.length != 0 ?
                                    <> 
                                        {getCart?.map ? getCart.map((cart:any, i, MrpValue:any) => (
                                            <div className="row mx-0 mb-5">
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <div className="checkout-details" style={{borderRadius:"8px"}}>
                                                        <div className="order-box">
                                                            {/* <div className="col-lg-12 title-box"> */}
                                                            <div className="col-lg-12">
                                                                <div className="table-responsive">
                                                                    <table className="table cart-table table-hover table-borderless">
                                                                        <thead style={{borderBottom:'1px dashed #777777'}}>
                                                                            <tr>
                                                                                <th>Product Details</th>
                                                                                <th className="text-center">Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {/* Displaying Products */}
                                                                            <p style={{display:'none'}}>{MrpValue = 0}</p>
                                                                            {cart.cart_products?.map ? cart.cart_products.map((cartProducts:any, k:0) => (
                                                                                <>
                                                                                    {cartProducts.products?.map ? cartProducts.products.map((products:any, value:any, mrp:any, value2:any) => (
                                                                                    <tr style={{borderBottom:'1px dashed #777777'}}>
                                                                                        <td>
                                                                                            <p style={{display:'none'}}>{value=0}{mrp=0} {value2=0}</p>
                                                                                            <div className="d-flex">
                                                                                                <div className="pr-2 my-auto">
                                                                                                    <Link to={'#'}>
                                                                                                            <> 
                                                                                                                {/* <div className="product-right-slick"> */}
                                                                                                                {/* <iframe style={{ width: "100%", height: "100%", minHeight: "250px", maxHeight: "450px" }} src="https://www.youtube.com/embed/rMtMuBh-tP0" allow="autoplay; encrypted-media" allowFullScreen /> */}
                                                                                                                {products.image != '' ?
                                                                                                                    <>
                                                                                                                        <img width={"50px"} src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt=""/>
                                                                                                                        
                                                                                                                    </>
                                                                                                                :
                                                                                                                    <>
                                                                                                                        <img height={"50px"} src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt=""/>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                        
                                                                                                    </Link>
                                                                                                </div>
                                                                                                <div className="border-dashed-2px-lt pl-2">                                                                                
                                                                                                    <h6 className="mb-0"><b>TITLE</b> - {products.product_name}</h6>
                                                                                                    <p className="mb-1"><b>{products.short_description}</b></p>                                                                                
                                                                                                    <div className="row">
                                                                                                        <p style={{display:'none'}}>{value = Number(value) + Number(products.product_price)}</p>
                                                                                                        {cartProducts.cart_product_details?.map ? cartProducts.cart_product_details.map((cartProductDetails:any) => 
                                                                                                            <>
                                                                                                                {cartProductDetails.product_option_values?.map ? cartProductDetails.product_option_values.map((ProductOptionValues:any) => 
                                                                                                                    <> 
                                                                                                                        <p className="col-12 mb-0"> 
                                                                                                                            {ProductOptionValues.product_option.option_name} - {ProductOptionValues.value_name}
                                                                                                                        </p>
                                                                                                                        <p style={{display:'none'}}>{value = Number(value) + Number(ProductOptionValues.price)}</p>
                                                                                                                    </>
                                                                                                                ) : ''}
                                                                                                            </>
                                                                                                        ) : ''}
                                                                                                        {cartProducts.cart_product_languages?.map ? cartProducts.cart_product_languages.map((cartProductLanguages:any, j:Number) => 
                                                                                                            <>  
                                                                                                                <p style={{display:'none'}}>{j = Number(j) + 1}</p>
                                                                                                                <p className="col-12" style={{ marginBottom: "0",color:"#474747"}}> 
                                                                                                                Language - {cartProductLanguages.language.language_name}
                                                                                                                </p>
                                                                                                                {
                                                                                                                    j == 1 ? 
                                                                                                                    <>                                                                                    
                                                                                                                        <p style={{display:'none'}}>{value2 = Number(value2) + Number(value)}</p>
                                                                                                                    </> :
                                                                                                                    <>                                                                                      
                                                                                                                        <p style={{display:'none'}}>{value2 = Number(value2) + Number((Number(cartProductLanguages.product_language_option.price_percentage) * Number(value))/100)}</p>
                                                                                                                    </>
                                                                                                                }                                                                                                          
                                                                                                            </>
                                                                                                        ) : ''}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="my-auto">
                                                                                                <h6 className="col-12">
                                                                                                    {getUserType == 'ResellerType' ?
                                                                                                        <>₹ {cartProducts.rs_price}.00 /- &nbsp;&nbsp;</>
                                                                                                    :
                                                                                                        <>₹ {cartProducts.price}.00 /- &nbsp;&nbsp; </>                                                                                                        
                                                                                                    }                                                                                                       
                                                                                                    {cartProducts.mrp != cartProducts.price && 
                                                                                                        <>
                                                                                                            <b className="m-auto"> you save </b>&nbsp;&nbsp;
                                                                                                            <del className="text-color-pink font-size-15px m-auto">₹ {mrp = cartProducts.mrp}</del> &nbsp;&nbsp;
                                                                                                            {getUserType == 'ResellerType' ?
                                                                                                                <span className="text-color-green font-size-11px m-auto"> {Math.round(((Number(mrp) - Number(cartProducts.rs_price))/Number(mrp))*100)}% off</span>
                                                                                                            :
                                                                                                                <span className="text-color-green font-size-11px m-auto"> {Math.round(((Number(mrp) - Number(cartProducts.price))/Number(mrp))*100)}% off</span>                                                                                                                
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                    &nbsp;&nbsp; 
                                                                                                </h6>
                                                                                                {getUserType == 'ResellerType' ? 
                                                                                                    <p style={{display:'none'}}>{MainValue = Number(MainValue) + Number(cartProducts.rs_price)}</p>
                                                                                                :
                                                                                                    <p style={{display:'none'}}>{MainValue = Number(MainValue) + Number(cartProducts.price)}</p>
                                                                                                }
                                                                                                <p style={{display:'none'}}>{MrpValue = Number(MrpValue) + Number(mrp)}</p>
                                                                                                <br/>
                                                                                                <button className="btn rounded float-right" type="button"   onClick={ () => deleteProduct(cartProducts.id, i)} > <i className="fa fa-trash-o" style={{color:"red", fontSize:"18px"}}></i> </button>
                                                                                                <Link to={'/single-product/'+cartProducts.product_id} className="btn rounded float-right"> <i className="fa fa-edit" style={{color:'#777777', fontSize:"17px"}}></i> </Link>
                                                                                            </div>                                                                
                                                                                        </td>
                                                                                    </tr>
                                                                                    )) : ''}
                                                                                </>
                                                                            )) : ''}
                                                                        </tbody>
                                                                    </table>
                                                                </div> 
                                                             </div>                                             
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4  col-md-4 col-sm-12 col-xs-12">
                                                    <div className="checkout-details" style={{borderRadius:"8px"}}>
                                                        <div className="order-box">
                                                            <div className="title-box">
                                                                <div>PRICE DETAILS</div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-6 mt-3">
                                                                    <h6>Total MRP </h6>
                                                                    {MainValue - MrpValue < 0 && 
                                                                        <>
                                                                            <h6 className="text-color-grey">You Save </h6>
                                                                            <h6>Total Price </h6>
                                                                        </>
                                                                    }
                                                                    {/* <h3>Delivery in 72 hours </h3> */}
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div className="text-right">
                                                                        <h6 className="font-weight-bold">₹ {MrpValue}.00 /-</h6>
                                                                        {MainValue - MrpValue < 0 && 
                                                                            <>
                                                                                <h6 className="font-weight-bold text-color-pink">₹ {MrpValue - MainValue}.00 /-</h6>
                                                                                <h6 className="font-weight-bold">₹ {MainValue}.00 /-</h6>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="payment-box">
                                                            <div className="text-center"><Link to ="/check-out" className="btn-solid btn">Checkout</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : ''}
                                    </>                                    
                                    : 
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-4">
                                                <div className="card-header text-white text-center">Your cart is empty!</div>
                                                <div className="card-body text-white text-center">Add Items now</div> 
                                                <div className="card-footer text-white text-center">
                                                    <Link to="/live_shop" className="btn-solid btn">Shop now</Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                            </div>
                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/*section end*/}
            <ToastContainer autoClose={1500}/>
        </>
    )
}