import { useState, useEffect } from 'react';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl, baseUrl2 } from "../../../http-common";
import "./admin.css";


const MyVideos: React.FC = () => {
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const [getProducts, setData] = useState([] as any);

    useEffect(() => {
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
            // console.log(Response);
            http.get("/products/productbyuserid/" + Response.data.id + '/' + 0)
            .then((res: any) => {
                // console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            })            
        })        
    }, []);

    return(
        <div className='container-fluid'>
            <div className='row'>
                {getProducts?.map ? getProducts.map((order:any, i:0) => 
                    <>
                        {order.cart_products?.map ? order.cart_products.map((cart:any, k:0) => 
                        <>
                            {cart.products?.map ? cart.products.map((por:any, j:0) => <>
                                <div key={i} className='col-sm-6 col-md-4 col-lx-2'>
                                    {/* <div className="product-right-slick"> */}
                                        {/* <iframe style={{ width: "100%", height: "100%", minHeight: "250px", maxHeight: "450px" }} src="https://www.youtube.com/embed/rMtMuBh-tP0" allow="autoplay; encrypted-media" allowFullScreen /> */}
                                        {por.video != '' ?
                                            <>
                                                <iframe style={{ width: "100%",height: "100%", minHeight: "150px", maxHeight: "450px" }} src={''+baseUrl2+'/'+por.video+''} allowFullScreen />
                                            </>
                                        :
                                        por.image != '' ?
                                            <>
                                                <div className="front">
                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url('+baseUrl2+'/'+por.image+')', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "450px", maxHeight: "650px"}}>
                                                        <img src={''+baseUrl2+'/'+por.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                    </a>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className="front">
                                                        <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "450px", maxHeight: "650px"}}>
                                                            <img src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                        </a>
                                                </div>
                                            </>
                                        }
                                        <h6 className='card-title'>{por.product_name}</h6>
                                </div>
                            </>) : ''}
                        </>) : ''}
                    </>
                ) : ''}
            </div>
        </div>
    );
};

export default MyVideos;