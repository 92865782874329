import { useNavigate, useParams } from "react-router-dom";
import {useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import http, { baseUrl } from "../../../http-common";
import 'react-dropzone-uploader/dist/styles.css';
import './style.css';
import Dropzone, { defaultClassNames, IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
      <div>
        {previews}
  
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
  
        {files.length > 0 && submitButton}
      </div>
    )
}

export default function Content(){
    const params = useParams();
    const getCartProductID = params.order_id;
    // console.log(getCartProductID);
    const [getCartProduct, setCartProduct] = useState([] as any);
    const [getfiles, setFiles] = useState([] as any);
    const navigate = useNavigate();
    const [getOrderDetails, setOrderDetails] = useState([] as any);
    const [getDiscard, setDiscard] = useState(true);

    useEffect(() => {
        http.get("/cartproduct/cartproductId/"+getCartProductID)
            .then((res: any) => {
                setCartProduct(res.data);
                http.get("/order_details/cart_id/"+res.data.cart_id).then((res:any) => {
                    setOrderDetails(res.data);
                }).catch(err => {
                    console.log(err);
                })
            })
            .catch((err) => {
                console.log("error", err);
            })
    }, []);
    console.log(getCartProduct);
    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (file:any,meta:any,allFiles:any) => {
       console.log(allFiles);
       setFiles(allFiles);
    }

    const initialValues = {
        order_id:0,
        cart_product_id:0,
        event_title:'',
        primary_name:'',  
        secondary_name:'',  
        event_date:'',  
        event_venue:'',  
        best_wishes:'',  
        video_for:'',  
        content_summary:'',  
        created_by:0,  
        updated_by:0,
        status:''
    }

    const validationSchema = Yup.object().shape({
        event_title:Yup.string().required("Title Required"),  
        primary_name:Yup.string().required("Name Required"),  
        secondary_name:Yup.string().required("Name Required"),  
        event_date:Yup.date().required("Date Required"),  
        event_venue:Yup.string().required("Event Venue Required"),  
        best_wishes:Yup.string().required("Best Wishes Required"),  
        video_for:Yup.string().required("Video For Required")
    })

    const SubmitForm = (data:any) =>{
        // console.log(getOrderDetails);
        data.cart_product_id = getCartProduct.id;
        data.order_id = getOrderDetails.id;
        data.created_by = getCartProduct.id;
        data.updated_by = getCartProduct.id;
        console.log(data.order_id);

        let formdata:any = new FormData();
        //formdata.append("imagefiles", data.FileList);
        getfiles.forEach((f:any) => {
            formdata.append('fileList', f.file, f.name);
        });
        formdata.append("order_id", data.order_id);
        formdata.append("cart_product_id", data.cart_product_id);
        formdata.append("event_title", data.event_title);
        formdata.append("primary_name", data.primary_name);
        formdata.append("secondary_name", data.secondary_name);
        formdata.append("event_date", data.event_date);
        formdata.append("event_venue", data.event_venue);
        formdata.append("best_wishes", data.best_wishes);
        formdata.append("video_for", data.video_for);
        formdata.append("content_summary", data.content_summary);
        formdata.append("created_by", data.created_by);
        formdata.append("updated_by", data.updated_by);
        formdata.append("status", data.status);
        formdata.append("discard", getDiscard);
        console.log(data, getDiscard);
        http.post("/order_aasets", formdata)
        .then(res => {
            toast.success('Added Successfully !',{position:toast.POSITION.TOP_RIGHT});        
            console.log(res);
            setTimeout(() => {
                //window.location.reload();
                navigate('/order_view/'+ getOrderDetails.id);
            }, 2000);
        }).catch(err => {
            console.log(err);
        })
    }
    const  Change = (e:any) =>{
        console.log(e);
    }
    return(
        <>
        <section className="m-3 p-3">
        {/* Container-fluid starts */}
        <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5><b>Assets Upload</b></h5>
                                        <Link to={"/order_view/"+ getOrderDetails.id} className="btn btn-info">Back to Order</Link>
                                    </div>
                                    <div className="card-body" style={{ padding: "10px 35px" }}>
                                        <div className="row product-adding">
                                            <div className="col-xl-12" >
                                                <Formik
                                                    initialValues={initialValues}
                                                    onSubmit={SubmitForm}
                                                    validationSchema={validationSchema}>
                                                    <Form className="needs-validation add-product-form">
                                                        <div className="form row">
                                                            <div className="col-md-6 p-5" style={{borderRight: '#c1b7b7 1px solid'}}>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Title :</label>
                                                                    <Field className="form-control" id="event_title" type="text" name="event_title" />
                                                                    <ErrorMessage name='event_title' component="span" className='text-danger'/>                                                                
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Date :</label>
                                                                    <Field type="date" name="event_date" className="form-control " id="event_date" />
                                                                    <ErrorMessage name='event_date' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Name/Bridegroom :</label>
                                                                    <Field className="form-control" id="primary_name" name="primary_name" type="text" />
                                                                    <ErrorMessage name='primary_name' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Bride Name:</label>
                                                                    <Field className="form-control " id="secondary_name" name="secondary_name" type="text" />
                                                                    <ErrorMessage name='secondary_name' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Venue :</label>
                                                                    <Field className="form-control " id="event_venue" name="event_venue" type="text" />
                                                                    <ErrorMessage name='event_venue' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Best Wishes :</label>
                                                                    <Field className="form-control " id="best_wishes" name="best_wishes" type="text" />
                                                                    <ErrorMessage name='best_wishes' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="title" className="">Video For :</label>
                                                                    <Field as="select" name="video_for" id="video_for" className="form-control ">
                                                                        <option value="">Please Select</option>
                                                                        <option title="for Bridegroom">Bridegroom</option>
                                                                        <option title="for Bride">Bride</option>
                                                                        <option title="for both Bride and Bridegroom">Both</option>
                                                                    </Field>
                                                                    <ErrorMessage name='video_for' component="span" className='text-danger'/>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="description" className="">Description :</label>
                                                                    <Field as="textarea" name="content_summary" className="form-control" rows={5} cols={5} ></Field>
                                                                </div>                                                            
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Dropzone
                                                                    //getUploadParams={getUploadParams}
                                                                    LayoutComponent={Layout}
                                                                    //onSubmit={handleSubmit}
                                                                    onChangeStatus={handleChangeStatus}
                                                                    classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                                                                    inputContent="Add files"
                                                                />
                                                            </div>  
                                                        </div>
                                                            <div className="col-xl-12 mt-3" style={{textAlign: "center"}}>
                                                                <div className="d-flex justify-content-between">
                                                                    <Field type="submit" onClick={() => setDiscard(true)} name="status" value="Discard" className="btn btn-primary mr-2"/>
                                                                    <Field type="submit" onClick={() => setDiscard(false)} name="status" value="Submit" className="btn btn-primary"/>
                                                                </div>
                                                            </div>
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <>
                                    {getCartProduct.Product != null &&
                                        
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-sm-12" style={{ paddingRight: "0px" }}>
                                                <li> 
                                                    {getCartProduct.Product.image_type == "Video" ? <iframe style={{ width: "100%", height: "40vh", maxHeight:'40vh;' }} src={''+baseUrl+'/'+getCartProduct.Product.image+''} allowFullScreen /> :
                                                    getCartProduct.Product.image_type == "Url" ? <iframe style={{ width: "100%", height: "40vh", maxHeight:'40vh;' }} src={''+baseUrl+'/'+getCartProduct.Product.image+''} allowFullScreen /> :
                                                    getCartProduct.Product.image_type == "Image" ? 
                                                    <><div className="front">
                                                        <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")',height: "40vh", maxHeight:'40vh;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                            <img src={''+baseUrl+'/'+getCartProduct.Product.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                        </a>
                                                    </div>
                                                    </>
                                                    :
                                                    <><div className="front">
                                                        <a href="#" className="bg-size blur-up lazyloaded">
                                                            <img src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt="" width={'150px'}/>
                                                        </a>
                                                    </div></>}
                                                </li>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-sm-12 mt-3">
                                                <h4><b>TITLE</b> - {getCartProduct.Product.product_name}</h4>
                                                <p><b>{getCartProduct.Product.short_description}</b></p>
                                                <div className="row">
                                                {getCartProduct.cart_product_details?.map ? getCartProduct.cart_product_details.map((cartProductDetails:any) => 
                                                    <>
                                                        {cartProductDetails.product_option_values?.map ? cartProductDetails.product_option_values.map((ProductOptionValues:any) => 
                                                            <>
                                                                
                                                                        <p className="col-6" style={{ marginBottom: "0",color:"#474747" }}> 
                                                                            {ProductOptionValues.product_option.option_name} - {ProductOptionValues.value_name}
                                                                        </p><br/>
                                                            </>
                                                        ) : ''}
                                                    </>
                                                ) : ''}
                                                    {/* <p className="col-12" style={{ marginBottom: "0",color:"#474747"}}> 
                                                        Languages - &nbsp;
                                                        // {getCartProduct.cart_product_languages?.map ? getCartProduct.cart_product_languages.map((cartProductLanguages:any, j:Number) => 
                                                        //     <>  
                                                        //           {cartProductLanguages.language.language_name}, &nbsp;
                                                                                                                                                                      
                                                        //     </>
                                                        // ) : ''}
                                                    </p> */}
                                                <br/>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container-fluid End */}
            </section>
        </>
    )
}