import moment from 'moment';
import {useEffect, useRef, useState, useCallback } from 'react';
import Countdown from 'react-countdown';
import ReactPlayer from 'react-player';
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl, baseUrl2 } from "../../../http-common";
import './style.css';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

//Dropzone
import Dropzone, { defaultClassNames, IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';

//Accordian 
const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

//Assets upload
// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
      <div>
        {previews}
  
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
  
        {files.length > 0 && submitButton}
      </div>
    )
}

export default function Content(){
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const params = useParams();
    const getOrderID = params.order_id;
    const [getOrdersData, setData] = useState([]);
    const [getOrderId, setOrderId] = useState(0);
    const [getfiles, setFiles] = useState([] as any);
    const [getVersion, setVersion] = useState([]);
    const [getVersionId, setVersionId] = useState(0);
    const [getVLanguage, setVLanguage] = useState('');
    const [getVersonnumber, setVersonNumber] = useState('');
    const [getVideoLink, setVideoLink] = useState('');
    const [getVideoStatus, setVideoStatus] = useState('');
    const [played, setPlayed] = useState(0);
    // const [onReady , setOnReady ] = useState(0);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const [getTimeToStart, setTimeToStart] = useState(0);
    const [getCartProducts, setCartProducts] = useState([]);

    //Accordian
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        };
    //Accordian

    //Assets upload
    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (file:any,meta:any,allFiles:any) => {
        console.log(allFiles);
        setFiles(allFiles);
    }

    const listInnerRef:any = useRef();
    const scrollToBottom = () => {
        listInnerRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    //video playing at particular time
    const playerRef:any = useRef();
    const onReady:any = useCallback(() => {
        if (!isReady) {
            const timeToStart = getTimeToStart/100;
            console.log(timeToStart);
            playerRef.current.seekTo(timeToStart, "seconds");
            setIsReady(true);
        }
    }, [isReady]);
    
    useEffect(() => {
        http.get("/order_details/getByOrderID/"+getOrderID)
            .then((res: any) => {
                if(res.data.length != 0){
                    res.data.map((p_data:any) => {
                        if((172800000 - (new Date().getTime() - new Date(p_data.createdAt).getTime())) <= 0 && p_data.status == 'Ordered'){
                            cancle_order(p_data.id);
                        }
                        if(p_data.cart_products.length != 0){
                            p_data.cart_products.map((crtp:any, t:0) => {
                                if(crtp.products_versions.length != 0){
                                    crtp.products_versions.map((pv:any) => {
                                        setVersionId(pv.id);
                                        setVersonNumber(pv.version_number);
                                        // setVideoLink(pv.assets_name);
                                        setVideoStatus(pv.status);
                                    })
                                }
                            })
                        }

                        var cartProducts = p_data.cart_products;
                        const uniqueCartProducts:any = [];
                        const cartProducts1 = cartProducts.filter((cpData:any) => {
                            const isDuplicate = uniqueCartProducts.includes(cpData.product_id);
                            if (!isDuplicate) {
                                uniqueCartProducts.push(cpData.product_id);
                          
                                return true;
                            }
                            return false;
                        })
                        setCartProducts(cartProducts1);
                    })

                
                }
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            });
            http.get('/users/byUserEmail/'+email).then(Response => {
                SetUserId(Response.data.id);
            }).catch(error => {
                console.log(error);
            })      
    }, []);

    useEffect(() => {
        http.get('/verson_comments/byVersonID/'+getVersionId)
            .then(Response => {
                setVersion(Response.data);
                scrollToBottom();
            })
        .catch(err => console.log(err));
    }, [getVersionId])

    function pad(num:any, size:any) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }
    function cancle_order(id:any){
        const body = {
            order_id:id,
            cancelled_at:Date.now()
        }
        http.post("/order_details/updateOrderById", body)
        .then((Response) => {
            // console.log(Response)            
            toast.info('Order Cancelled Successfully!', { position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
        .catch(error => console.log(error))
    }
    function re_order(id:any){
        const body = {
            order_id:id,
            re_ordered_at:Date.now()
        }
        http.post("/order_details/reOrderById", body)
        .then((Response) => {
            // console.log(Response)            
            toast.success('Re-Ordered Successfully!', { position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
        .catch(error => console.log(error))
    }

    function handleSubmit(event:any, cart_product_id:any) {
        // alert('An essay was submitted: ' + event);
        event.preventDefault();
        
        let formdata:any = new FormData();
        formdata.append("order_id", getOrderID);
        formdata.append("cart_product_id", cart_product_id);
        formdata.append('event_title', event.target.event_title.value);
        formdata.append('primary_name', event.target.g_name.value);
        formdata.append('secondary_name', event.target.b_name.value);
        formdata.append('event_date', event.target.event_date.value);
        formdata.append('event_time', event.target.event_time.value);
        formdata.append('event_venue', event.target.c_address.value);        
        formdata.append('content_summary', event.target.content_summary.value);
        formdata.append('video_for', event.target.video_for.value);
        formdata.append('g_surname', event.target.g_surname.value);        
        formdata.append('g_f_name', event.target.g_f_name.value);        
        formdata.append('g_m_name', event.target.g_m_name.value);        
        formdata.append('c_details', event.target.c_details.value);        
        formdata.append('b_surname', event.target.b_surname.value);
        formdata.append('b_f_name', event.target.b_f_name.value); 
        formdata.append('b_m_name', event.target.b_m_name.value);
        formdata.append('content_summary1', event.target.content_summary1.value);
        formdata.append('created_by', getUserId);
        formdata.append('updated_by', getUserId);
        formdata.append("status", 'Active');
        getfiles.forEach((f:any) => {
            formdata.append('fileList', f.file, f.name);
        });
        
        http.post("/order_aasets", formdata)
        .then(res => {
            toast.success('Added Successfully !',{position:toast.POSITION.TOP_RIGHT});        
            console.log(res);
            setTimeout(() => {
                window.location.reload();
                //navigate('/order_view/'+ getOrderDetails.id);
            }, 2000);
        }).catch(err => {
            console.log(err);
            alert('something went wrong');
        })
    }

    function submitComment(getId:any){
        let aid:any = document.getElementById(getId);
        // console.log(played);
        if(aid == ''){
    
        }else{
            console.log(aid.value);
            const data = {
                user_id : getUserId,
                admin_id: 0,
                comment : aid.value,
                version_id : getVersionId,
                user_type : 'user',
                comment_time : played,
                created_by : getUserId
            }
            http.post('/verson_comments', data).then(response => {
                setVersion(response.data);
                aid.value= '';
                scrollToBottom();
            })
        }
    }
    function onClickVersion(vid:any, vnm:any, vlng:any, vlink:any, vstatus:any){
        console.log(vid);
        setVersionId(vid);
        setVersonNumber(vnm);
        setVLanguage(vlng);
        setVideoLink(vlink);
        setVideoStatus(vstatus);
        setIsPlaying(false);
        setTimeToStart(0);
    }

    function setMediaTime(e:any, mediaTime:any, videoLink:any){
        e.preventDefault();
        console.log(mediaTime);
        // setVersionId(getVersionId);
        // setVersonNumber(getVersonnumber);
        // setVLanguage(getVLanguage);
        setVideoLink('');
        // setVideoStatus(getVideoStatus);
        // setIsPlaying(false);
        // setTimeToStart(Number(mediaTime));
        setIsReady(false);
        setTimeToStart(mediaTime);
        setIsReady(false);
        
        setTimeout(() => {
            setVideoLink(videoLink);
        }, 2000); 

    }

    function VersionStatus(status:any, version_id:any){
        // alert(status);
        let data = {
            'status' : status,
            'version_id' : version_id,
            updatedAt : Date.now()
        }
        http.post('/verson_comments/change_status', data).then(response => {
            setVersion(response.data);
            toast.info('Your Action Completed!',{position:toast.POSITION.TOP_RIGHT});
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
    }
    return(
        <>
            {/* section start */}
            <section className="ratio_asos my-2 py-2" >
                <div className="collection-wrapper">
                    <div className="collection-content">
                        <div className="page-main-content">
                            <div className="container-fluid">
                                <div className='row'>
                                    {getOrdersData.length != 0 ? 
                                    <div className='col-md-12'>
                                        {getOrdersData?.map ? getOrdersData.map((data:any,i) => 
                                            <div key={i}>
                                                {/* <!-- Nav tabs --> */}
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active fw-600 p-3" data-toggle="tab" href="#home">Order Details</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link fw-600 p-3" data-toggle="tab" href="#menu1">Documents</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link fw-600 p-3" data-toggle="tab" href="#menu2">Video Download</a>
                                                    </li>
                                                </ul>
                                                {/* <!-- Tab panes --> */}
                                                <div className="tab-content bg-white py-2">
                                                    <div id="home" className="container tab-pane active p-0" style={{maxWidth:'100%'}}><br/>
                                                        <h6 className='w-600'>Order Details</h6>
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-md-4 p-1' >
                                                                <table className='table rounded' style={{border:'solid 1px #dee2e6'}}>                                                                
                                                                    <tr>
                                                                        <th colSpan={2} style={{borderTop:'none'}}><h6 className='w-600'>ORDER ID - <span className='text-info'>OR{pad(data.id, 5)}</span></h6></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date </td>
                                                                        <td>{moment(data.createdAt).format('MMMM Do, YYYY')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Total</td>
                                                                        <td> <h6>₹ {data.order_total}.00 /-</h6></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>TXN ID</td>
                                                                        <td>{data.payment_order_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Method</td>
                                                                        <td>{data.payment_type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Status</td>
                                                                        <td>{data.payment_status}</td>
                                                                    </tr>                                                                    
                                                                </table>                                                                
                                                            </div>
                                                            <div className='col-md-4 p-1'>
                                                                <table className='table rounded' style={{border:'solid 1px #dee2e6'}}>
                                                                    <tr>
                                                                        <th colSpan={2} style={{borderTop:'none'}}><h6>Product Details</h6></th>
                                                                    </tr>
                                                                    {data.cart_products?.map ? data.cart_products.map((cartProducts:any, k:0) =>
                                                                        <>
                                                                            {cartProducts.products?.map ? cartProducts.products.map((products:any) => 
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                                {products.image != '' ?
                                                                                                    <Link to={"/single-product/"+products.id} style={{backgroundImage:'url('+baseUrl2+'/'+products.image+')', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}>
                                                                                                        <img src={baseUrl2+'/'+products.image} width="80px"/>                                                                                                            
                                                                                                    </Link>
                                                                                                    :
                                                                                                    <Link to={"/single-product/"+products.id} style={{backgroundImage:'url(/assets2/images/pro3/35.jpg)', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}>
                                                                                                        <img src="/assets2/images/pro3/35.jpg" width="80px"/>
                                                                                                    </Link>
                                                                                                }
                                                                                        </td>
                                                                                        <td>
                                                                                            <Link to={"/single-product/"+products.id}>
                                                                                                <h6 className='mb-2 f-600'>{products.product_name}</h6>
                                                                                                <p className='mb-2 f-500'>{products.short_description}</p>
                                                                                                {cartProducts.cart_product_details?.map ? cartProducts.cart_product_details.map((cartProductDetails:any) => 
                                                                                                    <>  
                                                                                                        {cartProductDetails.product_option_values?.map ? cartProductDetails.product_option_values.map((ProductOptionValues:any) => 
                                                                                                            <>  
                                                                                                                <p style={{color:'#9a9a9a', marginBottom: "0"}}> 
                                                                                                                    {ProductOptionValues.product_option.option_name} - {ProductOptionValues.value_name}                                                                                                                        
                                                                                                                </p>
                                                                                                            </>
                                                                                                        ) : ''}
                                                                                                    </>
                                                                                                ) : ''}
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                </> 
                                                                            ) : ''}
                                                                        </>
                                                                    ) : ''}
                                                                    
                                                                </table>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <table className='table rounded' style={{border:'solid 1px #dee2e6'}}>
                                                                    <tr>
                                                                        <th colSpan={2}>
                                                                            <h6>Order Status</h6>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td>
                                                                            {data.status == 'Ordered' && <span className='badge badge-dark p-2'>Pending</span>}
                                                                            {data.status == 'Processing' && <span className='badge badge-primary p-2'>{data.status}</span>}
                                                                            {data.status == 'Completed' && <span className='badge badge-info p-2'>{data.status}</span>}
                                                                            {data.status == 'Rejected' && <span className='badge badge-danger p-2'>{data.status}</span>}
                                                                            {data.status == 'Downloaded' && <span className='badge badge-success p-2'>{data.status}</span>}
                                                                            {data.status == 'Cancelled' && <span className='badge badge-danger p-2'>{data.status}</span>}
                                                                        </td>
                                                                    </tr>
                                                                    {data.status == 'Ordered' &&
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                Documents Upload Time   
                                                                            </td>
                                                                            <td>
                                                                                <h6 className='badge badge-danger text-white'><Countdown date={Date.now() + (86400000 - (new Date().getTime() - new Date(data.createdAt).getTime()))} /></h6>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                {(86400000 - (new Date().getTime() - new Date(data.createdAt).getTime())) > 0 && data.status == 'Ordered' ?
                                                                                    <>                                                                                                
                                                                                        <button type="button" data-toggle="modal" data-target="#Cancle_Order" className="btn btn-sm btn-danger mt-2 text-white rounded">Cancle Order</button><br/>
                                                                                        {/* The Modal */}
                                                                                        <div className="modal fade" id="Cancle_Order">
                                                                                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                                                                                <div className="modal-content">
                                                                                                    {/* Modal Header */}
                                                                                                    <div className="modal-header bg-info">
                                                                                                        <h4 className="modal-title text-white">CANCEL ORDER</h4>
                                                                                                        <button type="button" className="close text-white" data-dismiss="modal">
                                                                                                            ×
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    {/* Modal body */}
                                                                                                    <div className="modal-body">
                                                                                                        <div className='row p-4'>
                                                                                                            <div className='col-md-12 text-center'>
                                                                                                                <h3 className='text-danger font-weight-bold'>
                                                                                                                    DO YOY WANT CANCEL ORDER
                                                                                                                </h3>
                                                                                                                <p className='text-muted'>Order Cancel Before With In 24 Hours. 99% Amount Will Be Refunded.</p>
                                                                                                                <p className='text-muted'>If Cancel Order Amount Will be Refunded Working Days 48 To 72 Hours. </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* Modal footer */}
                                                                                                    <div className="modal-footer">
                                                                                                        <button
                                                                                                            onClick={() => cancle_order(data.id)}
                                                                                                            type="button"
                                                                                                            className="btn btn-info"
                                                                                                        >
                                                                                                            Cancle Order
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            data-dismiss="modal"
                                                                                                        >
                                                                                                            Close
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                :
                                                                                (172800000 - (new Date().getTime() - new Date(data.createdAt).getTime())) > 0 && data.status == 'Ordered' ?
                                                                                    <>                                                                                                
                                                                                        <button type="button" data-toggle="modal" data-target="#Cancle_Order" className="btn btn-sm btn-danger mt-2 text-white rounded">Cancle Order</button><br/>
                                                                                        {/* The Modal */}
                                                                                        <div className="modal fade" id="Cancle_Order">
                                                                                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                                                                                <div className="modal-content">
                                                                                                    {/* Modal Header */}
                                                                                                    <div className="modal-header bg-info">
                                                                                                        <h4 className="modal-title text-white">EXPRIED CANCEL ORDER</h4>
                                                                                                        <button type="button" className="close text-white" data-dismiss="modal">
                                                                                                            ×
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    {/* Modal body */}
                                                                                                    <div className="modal-body">
                                                                                                        <div className='row p-4'>
                                                                                                            <div className='col-md-12 text-center'>
                                                                                                                <h3 className='text-danger font-weight-bold'>
                                                                                                                    DO YOY WANT EXPRIED CANCEL ORDER
                                                                                                                </h3>
                                                                                                                <p className='text-muted'>Order Cancel Before With In 48 Hours. 70% Amount Will Be Refunded.</p>
                                                                                                                <p className='text-muted'>If Cancel Order Amount Will be Refunded Working Days 48 To 72 Hours. </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* Modal footer */}
                                                                                                    <div className="modal-footer">
                                                                                                        <button
                                                                                                            onClick={() => cancle_order(data.id)}
                                                                                                            type="button"
                                                                                                            className="btn btn-info"
                                                                                                        >
                                                                                                            Cancle Order
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            data-dismiss="modal"
                                                                                                        >
                                                                                                            Close
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <a className="btn btn-sm btn-warning mt-2 text-white rounded">Expired</a>
                                                                                    </>
                                                                                :
                                                                                data.status == 'Cancelled' &&
                                                                                <>  
                                                                                    <a className="btn btn-sm btn-danger mt-2 text-white rounded">Order Cancelled</a>
                                                                                    <a onClick={() => re_order(data.id)} className="btn btn-sm btn-warning mt-2 text-white rounded">Reorder</a>
                                                                                </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                    }                                                                 
                                                                </table>
                                                            </div>
                                                        </div>                                            
                                                    </div>
                                                    <div id="menu1" className="container tab-pane fade p-0" style={{maxWidth:'100%'}}><br/>
                                                        <h6 className='pl-3'>Documents</h6>
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-md-12'>                                                                
                                                                {getCartProducts?.map ? getCartProducts.map((cartProducts:any, k) =>
                                                                    <div key={k}>
                                                                        {cartProducts.products?.map ? cartProducts.products.map((products:any) => 
                                                                        <>  
                                                                            <Accordion expanded={expanded === 'panel'+k} onChange={handleChange('panel'+k)}>
                                                                                <AccordionSummary aria-controls={"panel"+k+"d-content"} id={"panel"+k+"d-header"}>
                                                                                    <Typography>
                                                                                        <h6 className='mb-1 fw-600'>{products.product_name}, <span className='fw-500'>{products.short_description}</span></h6>
                                                                                        
                                                                                        {cartProducts.cart_product_details?.map ? cartProducts.cart_product_details.map((cartProductDetails:any) => 
                                                                                            <>  
                                                                                                {cartProductDetails.product_option_values?.map ? cartProductDetails.product_option_values.map((ProductOptionValues:any) => 
                                                                                                    <>  
                                                                                                        <p style={{color:'#9a9a9a', marginBottom: "0"}}> 
                                                                                                            {ProductOptionValues.product_option.option_name} - {ProductOptionValues.value_name}                                                                                                                        
                                                                                                        </p>
                                                                                                    </>
                                                                                                ) : ''}
                                                                                            </>
                                                                                        ) : ''}
                                                                                    </Typography>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Typography>
                                                                                        {cartProducts.orders_content == null || cartProducts.orders_content.length == 0 ? 
                                                                                        <form onSubmit={(e)=>handleSubmit(e, cartProducts.id)} action="#">
                                                                                            <div className='row p-0 m-0'>                                                                                            
                                                                                                <div className='col-md-4'>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="eventDate"><span className='text-danger'>*</span> Event Date : </label>
                                                                                                        <input type="date" name='event_date' className="form-control form-control-sm" placeholder="Enter Date" id="eventDate" required/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="eventTime"><span className='text-danger'>*</span> Event Time : </label>
                                                                                                        <input type="time" name='event_time' className="form-control form-control-sm" placeholder="Enter time" id="eventTime" required/>
                                                                                                    </div>
                                                                                                    <label style={{fontSize:'16px'}} className='fw-600'><i className="fas fa-male"></i> Groom Detailes / Other Function details:</label>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="ename"> Event Title : </label>
                                                                                                        <input type="text" name='event_title' className="form-control form-control-sm" placeholder="Enter Event Title" id="ename"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="gsurname"> Surname : </label>
                                                                                                        <input type="text" name='g_surname' className="form-control form-control-sm" placeholder="Enter Surname" id="gsurname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="gname"> Groom Name / Event Person Name / Birthday Person Name : </label>
                                                                                                        <input type="text" name='g_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="gname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="gfname"> Father Name : </label>
                                                                                                        <input type="text" name='g_f_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="gfname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="gmname"> Mother Name : </label>
                                                                                                        <input type="text" name='g_m_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="gmname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="event_venue"> Function Location : </label>
                                                                                                        <textarea name='event_venue' className="form-control form-control-sm" placeholder="Function Location" id="event_venue"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="c_address"> Communication Address : </label>
                                                                                                        <textarea name='c_address' className="form-control form-control-sm" placeholder="Communication Address" id="c_address"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="c_details"> Function Contact Details : </label>
                                                                                                        <textarea name='c_details' className="form-control form-control-sm" placeholder="Function Contact Details like name phone number" id="c_details"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="content_summary"> Comments : </label>
                                                                                                        <textarea name='content_summary' className="form-control form-control-sm" placeholder="Enter Comments" id="content_summary"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-md-4'>
                                                                                                    <label style={{fontSize:'16px'}} className='fw-600 d-flex'><i className="fas fa-female"></i> Bride Detailes</label>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="bsurname">Bride Surname : </label>
                                                                                                        <input type="text" name='b_surname' className="form-control form-control-sm" placeholder="Enter Surname" id="bsurname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="bname"> Bride Name </label>
                                                                                                        <input type="text" name='b_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="bname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="bfname"> Father Name : </label>
                                                                                                        <input type="text" name='b_f_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="bfname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="bmname"> Mother Name : </label>
                                                                                                        <input type="text" name='b_m_name' className="form-control form-control-sm" placeholder="Enter Name with designation like John Deo, B.tech etc..." id="bmname"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="content_summary1"> Comments : </label>
                                                                                                        <textarea name='content_summary1' className="form-control form-control-sm" placeholder="Enter Comments" id="content_summary1"/>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="video_for">Video For :</label>
                                                                                                        <select name="video_for" id="video_for" className="form-control form-control-sm">
                                                                                                            <option value="">Please Select</option>
                                                                                                            <option title="for Bridegroom">Bridegroom</option>
                                                                                                            <option title="for Bride">Bride</option>
                                                                                                            <option title="for both Bride and Bridegroom">Both</option>
                                                                                                        </select>                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-md-4'>
                                                                                                    <Dropzone
                                                                                                        //getUploadParams={getUploadParams}
                                                                                                        LayoutComponent={Layout}
                                                                                                        //onSubmit={handleSubmit}
                                                                                                        onChangeStatus={handleChangeStatus}
                                                                                                        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                                                                                                        inputContent="Upload images"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='col-md-12'>
                                                                                                    <button type='submit' className='btn btn-solid btn-sm float-right text-end'>Submit</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                        :
                                                                                         <div className='row'>
                                                                                            <div className='col-md-1'></div>
                                                                                            <div className='col-md-5'>
                                                                                                <h6><i className='fas fa-male'></i> Groom Details / Function Details</h6>
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <p className='mb-2'>Event Title :</p>
                                                                                                    <p className='mb-2'>{cartProducts.orders_content.event_title}</p>
                                                                                                </div>
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <p className='mb-2'>Event Date :</p>
                                                                                                    <p className='mb-2'>{moment(cartProducts.orders_content.event_date).format('MMMM Do, YYYY')}</p>
                                                                                                </div>
                                                                                                <div style={{borderBottom:'1px dashed #777'}} className='d-flex justify-content-between mb-3'>
                                                                                                    <p className='mb-2'>Time :</p>
                                                                                                    <p className='mb-2'>{moment(cartProducts.orders_content.event_date).format('LT')}</p>
                                                                                                </div>
                                                                                                {cartProducts.orders_content.g_surname != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Surname</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.g_surname}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.primary_name != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Groom Name / Event Person Name / Birthday Person Name :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.primary_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.g_f_name != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Father Name :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.g_f_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.g_m_name != '' && 
                                                                                                    <div style={{borderBottom:'1px dashed #777'}} className='d-flex justify-content-between mb-3'>
                                                                                                        <p className='mb-2'>Mother Name :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.g_m_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.content_summary != '' && 
                                                                                                    <div>
                                                                                                        <p className='mb-2'>Comments :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.content_summary}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.event_venue != '' && 
                                                                                                    <div>
                                                                                                        <p className='mb-2'>Function Location :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.event_venue}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.c_details != '' && 
                                                                                                    <div>
                                                                                                        <p className='mb-2'>Communatite Address :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.c_details}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.c_details != '' && 
                                                                                                    <div>
                                                                                                        <p className='mb-2'>Contact Details :</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.c_details}</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='col-md-5'>
                                                                                                <h6><i className='fas fa-female'></i> Bride Detailes</h6>
                                                                                                {cartProducts.orders_content.b_surname != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Surname</p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.b_surname}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.secondary_name != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Bride Name </p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.secondary_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.b_f_name != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Father Name </p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.b_f_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.b_m_name != '' && 
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <p className='mb-2'>Mother Name </p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.b_m_name}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                {cartProducts.orders_content.content_summary1 != '' && 
                                                                                                    <div>
                                                                                                        <p className='mb-2'>Comments </p>
                                                                                                        <p className='mb-2'>{cartProducts.orders_content.content_summary1}</p>
                                                                                                    </div>
                                                                                                }
                                                                                                <h6 className='fw-600'>Uploaded Imges</h6>
                                                                                                <div style={{maxHeight:'200px', overflowY:'auto'}}>
                                                                                                    {cartProducts.orders_content.orders_assets?.map ? cartProducts.orders_content.orders_assets.map((assets:any) => 
                                                                                                        <>
                                                                                                            <div className='d-flex justify-content-between'>
                                                                                                                <div className='mb-2'>                                                                                                                
                                                                                                                    <a href={baseUrl+'/'+assets.assets_name} target="_blank"><img width={'100px'} src={baseUrl+'/'+assets.assets_name} className="img-fluid"/></a>
                                                                                                                </div>
                                                                                                                <div className='mb-2'>
                                                                                                                    <a className='btn btn-sm btn-secondary' href={baseUrl+'/'+assets.assets_name} target="_blank">
                                                                                                                        <i className="fas fa-arrow-down"></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : ''}
                                                                                                </div>                                                                                                   
                                                                                            </div>
                                                                                            <div className='col-md-1'></div>
                                                                                         </div>
                                                                                        }
                                                                                    </Typography>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </>) : ''}
                                                                    </div>) : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="menu2" className="container tab-pane fade p-0" style={{maxWidth:'100%'}}><br/>
                                                        <h6 className='pl-2 fw-600'>Product Videos</h6>
                                                        <div className='container'>
                                                            <div className='row'>
                                                                <div className="col-md-7 col-sm-12">
                                                                    <ReactPlayer ref={playerRef}
                                                                                onProgress={(e) => setPlayed(e.playedSeconds)} width="100%" height="auto" 
                                                                                 controls={true} 
                                                                                 playing={isPlaying} 
                                                                                 url={''+baseUrl2+'/'+getVideoLink}
                                                                                 onReady={onReady}
                                                                                 />
                                                                    <div className='row pt-2 pb-2'>
                                                                        <div className='col-md-12 col-sm-12 d-flex justify-content-between'>
                                                                            <div>                                                
                                                                                <label htmlFor="lng_id" className='fw-500'>Status : &nbsp;
                                                                                    {getVideoStatus == 'Completed' && <span style={{fontSize:'12px'}} className='badge badge-info p-2'>{getVideoStatus}</span>}
                                                                                    {getVideoStatus == 'Rejected' && <span style={{fontSize:'12px'}} className='badge badge-danger p-2'>{getVideoStatus}</span>}
                                                                                    {getVideoStatus == 'Accepted' && <span style={{fontSize:'12px'}} className='badge badge-success p-2'>{getVideoStatus}</span>}
                                                                                </label>
                                                                            </div>
                                                                            {getVideoStatus == 'Completed' &&
                                                                                <> 
                                                                                    <div>
                                                                                        <button onClick={() => VersionStatus('Rejected', getVersionId)} className='btn btn-solid btn-sm'>Reject</button>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button onClick={() => VersionStatus('Accepted', getVersionId)} className='btn btn-solid btn-sm'>Accept</button>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {getVideoStatus == 'Accepted' &&
                                                                                <> 
                                                                                    <div>
                                                                                        <a download="" href={baseUrl2+'/'+getVideoLink} className='btn btn-solid btn-sm' target="_blank">Download Video</a>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-5 rounded' style={{backgroundColor:'#ffffff'}}>
                                                                    <div className='card'>
                                                                        <div className='card-header px-5'>
                                                                            <div className='card-title pb-0 mb-0'>
                                                                                <div className='d-flex justify-content-between pb-0 mb-0'>
                                                                                    <h6 className='fw-700 pb-0 mb-0'>Video Comments</h6>
                                                                                    <p className='fw-600 pb-0 mb-0'>Version {getVersonnumber}</p>
                                                                                </div>
                                                                            </div>                                                                            
                                                                        </div>
                                                                        <div className='card-body' style={{maxHeight:'350px', overflowY:'auto' }}>
                                                                            {getVersion?.map ? getVersion.map((chatt:any,i) => 
                                                                                <div key={i}>
                                                                                    {chatt.user_type == 'user' && 
                                                                                        <div className='d-flex justify-content-start mb-3'>
                                                                                            <div className='d-flex flex-column align-items-start'>
                                                                                                <div className='d-flex align-items-center mb-2'>
                                                                                                    <div className='symbol symbol-35px symbol-circle'>
                                                                                                        <img className='rounded-circle' width={"50px"} alt="Pic" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg" />
                                                                                                    </div>
                                                                                                    <div className='ml-3 fw-500'><b>{chatt.user != null && chatt.user.user_name} </b> <span className='text-secondary'>{moment.utc(chatt.createdAt).local().startOf('seconds').fromNow()}</span></div>
                                                                                                </div>
                                                                                                
                                                                                                <div style={{backgroundColor:'#dcdae3'}} className="px-2 py-3 rounded text-dark text-start fw-500">
                                                                                                    {chatt.comment_time !== '0' && chatt.comment_time !== '' &&
                                                                                                            <a href='#' onClick={(e) => setMediaTime(e, chatt.comment_time, getVideoLink)}>{parseFloat(chatt.comment_time).toFixed(2)} sec</a>
                                                                                                    } &nbsp; | &nbsp;
                                                                                                    {chatt.comment}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {chatt.user_type === 'admin' && 
                                                                                        <div className='d-flex justify-content-end mb-3'>
                                                                                            <div className='d-flex flex-column align-items-end'>
                                                                                                <div className='d-flex align-items-center mb-2'>
                                                                                                    <div className='symbol symbol-35px symbol-circle'>
                                                                                                        <img className='rounded-circle' width={"50px"} alt="Pic" src="/assets/images/logo.jpg" />
                                                                                                    </div>
                                                                                                    <div className='ml-3 fw-500'><b>Gowish </b> <span className='text-secondary'>{moment.utc(chatt.createdAt).local().startOf('seconds').fromNow()}</span></div>
                                                                                                </div>
                                                                                                <div style={{backgroundColor:'#cedfe9'}} className="px-5 py-3 rounded text-dark text-start fw-500">{chatt.comment}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {chatt.user_type === 'employee' && 
                                                                                        <div className='d-flex justify-content-end mb-3'>
                                                                                            <div className='d-flex flex-column align-items-end'>
                                                                                                <div className='d-flex align-items-center mb-2'>
                                                                                                    <div className='symbol symbol-35px symbol-circle'>
                                                                                                        <img className='rounded-circle' width={"50px"} alt="Pic" src="/assets/images/logo.jpg" />
                                                                                                    </div>
                                                                                                    <div className='ml-3 fw-500'><b>Gowish </b> <span className='text-secondary'>{moment.utc(chatt.createdAt).local().startOf('seconds').fromNow()}</span></div>
                                                                                                </div>
                                                                                                <div style={{backgroundColor:'#cedfe9'}} className="px-5 py-3 rounded text-dark text-start fw-500">{chatt.comment}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            ) : ''}
                                                                            {/* <div ref={listInnerRef} /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='card-footer pt-4'>
                                                                        <textarea id="chatt-admin" style={{backgroundColor:'#bbd5e9'}} className="rounded form-control form-control-flush mb-3" rows={2} placeholder="Type a message" />
                                                                        <div className="text-right">                                                                                               
                                                                            <button onClick={() => submitComment("chatt-admin")} className="btn btn-solid btn-sm" type="button" data-kt-element="send">Send</button>                                                                                                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <h6 className='fw-600'>Media</h6>
                                                                    {data.cart_products?.map ? data.cart_products.map((cartProducts:any, n=1) =>                                                                     
                                                                        <div className='p-2'>
                                                                            <h5 className='fw-600'>
                                                                                {cartProducts.products?.map ? cartProducts.products.map((cp_p:any) => <> 
                                                                                    {cp_p.product_name}                                                                               
                                                                                </> ): <></>}
                                                                            </h5>
                                                                            {cartProducts.products_versions?.map ? cartProducts.products_versions.map((pv:any) => <>
                                                                                <table className="table table-sm mb-0">
                                                                                        <tbody className="fw-bold text-gray-600">
                                                                                            <tr style={{cursor: 'pointer'}} onClick={() => onClickVersion(pv.id, pv.version_number, pv.cart_product_id, pv.assets_name, pv.status)}>
                                                                                                <td>
                                                                                                    <div className='d-flex'>
                                                                                                        <div>
                                                                                                            <img width="120px" alt='version image' src='/assets2/images2/version.png' className='rounded' />
                                                                                                        </div>
                                                                                                        <div className='m-auto'>
                                                                                                            <p className='mb-1 fw-500'>File name Video 02,</p>
                                                                                                            <p className='mb-1 fw-500'>{moment(pv.createdAt).format('DD/MM/YYYY')}</p>
                                                                                                            <p className='mb-1 fw-500'>{pv.assets_type}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className='my-auto'>
                                                                                                        <p className='mb-1 fw-500'>Version - {pv.version_number}</p>
                                                                                                        <p className='mb-1 fw-500'>Status - &nbsp;
                                                                                                            {pv.status == 'Completed' && <span className='badge badge-info p-1'>{pv.status}</span>}
                                                                                                            {pv.status == 'Rejected' && <span className='badge badge-danger p-1'>{pv.status}</span>}
                                                                                                            {pv.status == 'Accepted' && <span className='badge badge-success p-1'>{pv.status}</span>}                                                                                                            
                                                                                                        </p>
                                                                                                        <p className='mb-1 fw-500'>{moment(pv.updatedAt).format('DD/MM/YYYY')}</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                </table>
                                                                            </>) : ''}
                                                                        </div>
                                                                    ): ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> ) : ''}
                                    </div>
                                    : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section End */}
            <ToastContainer autoClose={1000}/>
        </>
    )
}