
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getCookie } from 'typescript-cookie';
import {useEffect, useState } from 'react';
import http, { baseUrl } from "../../../http-common";
// import "./admin.css";
import MyComponent from './datatable';

export default function Content() {
    const email = getCookie('user_email');
    return(

        <>
        {/* Container-fluid starts*/}
        <div className="container-fluid my-2">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title" style={{fontWeight:'700'}}>Orders</h5>
                </div>                
                <div className="card-body order-datatable p-0">
                  <MyComponent/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Container-fluid Ends*/}
        </>

    );

}