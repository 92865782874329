import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getCookie, setCookie } from "typescript-cookie";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import http from "../../../http-common";
import { useState } from "react";

export default function MainNav(){
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [sucess, setSuccess] = useState(false);
    let email = getCookie('user_email');
    console.log(email);
    function logOut(){
        setCookie('user_email', '', { expires: 90});
        toast.success('Logout Successfully !',{position:toast.POSITION.TOP_RIGHT});
        window.location.reload();
        email = '';
    }
    return(
           <>
                {/*  navigation bar */}
                <div className="nav-overlay">
                    <div className="tooltip color-bg">Close Menu</div>
                </div>
                <div className="nav-holder">
                    <div className="nav-title"><span>Menu</span></div>
                    <div className="nav-inner-wrap">
                    <nav className="nav-inner sound-nav" id="menu">
                        <ul>
                        <li><Link to="/" className="act-link">Home</Link></li>
                        <li><Link to="/#about">About</Link></li>
                        <li><Link to="/#services">Services</Link></li>
                        <li><Link to="/#company">Company</Link></li>
                        <li><Link to="/#joinus">Joinus</Link></li>
                        <li><Link to="/#team">Team</Link></li>
                        <li><Link to="/#faq">Faq</Link></li>
                        <li><Link to="/contact_us">Contact</Link></li>
                        </ul>
                    </nav>
                    </div>
                </div>
                <div className="nav-holder-2">
                    <div className="nav-title"><span>Profile</span></div>
                    <div className="nav-inner-wrap">
                    <nav className="nav-inner sound-nav" id="menu-2">
                        <ul>
                            <li><a href="/orders_list" className="act-link">Orders</a></li>
                            <li><a href="/profile">My Profile</a></li>
                            {/* <li><Link to="/gallery_list">Password Change</Link></li> */}
                            {email == '' || email == undefined || email == null ? 
                                <>
                                    <li><a type="button" data-toggle="modal" data-target="#login123" href="#">Login</a ></li>
                                    {/* The Modal One */}
                                    {/* Modal */}
                                    {/* The Modal One ends */}
                                </> 
                            : 
                                <>
                                    <li><a href="#" onClick={() => logOut()}>Logout</a></li>
                                </>
                            }
                        </ul>
                    </nav>
                    </div>
                </div>
                {/*  navigation bar end */}
                <ToastContainer autoClose={1000}/>
           </>
    )
}