import axios from "axios";
export default axios.create({
  baseURL: "https://arkwrxclientapi.cafemobility.com/",
  // baseURL: "http://localhost:3002/",
  headers: {
    "Content-type": "application/json"
  }
});
export const baseUrl = "https://arkwrxclientapi.cafemobility.com/";
// export const baseUrl = "http://localhost:3002";
export const baseUrl2 = "https://arkwrxapi.cafemobility.com/";
// export const baseUrl2 = "http://localhost:3001";
