import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "typescript-cookie";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import http, {baseUrl} from "../../../http-common";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { error } from "jquery";
import { useState } from "react";
import './style.css';


export default function Login(){
    let unique_id = getCookie('unique_id');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false); 
    const navigate = useNavigate();
    if(unique_id == undefined){
        unique_id= ''
    }
    const initialValues = {
        user_name:"",
        user_email:"",
        phone:"",
        user_pass:"",
        user_image:"",
        agent_type:"",
        address:"",
        city:'',
        state:'',
        country:'',
        pincode:'',
        is_verified:'Yes',
        token:"",
        user_type:"User",
        status:"Yes",
        created_by:0,
        updated_by:0,
        unique_id:unique_id
    };
    const user_phnumber = /^[0-9]\d{9}$/;
    const user_pincode = /^[0-9]\d{5}$/;
    const validationSchema = Yup.object().shape({
        user_name: Yup.string().required("Name is required"),
        user_pass: Yup.string().required("Password Required"),
        phone: Yup.string()
                .matches(user_phnumber, 'Enter Valid Phone Number')
                .required('Phone Number Required')
                .test('Unique Phone number', 'Phone number already existing',
                function (phone) {  
                    return new Promise((resolve, reject) => {
                            http.get(`/users/phone/${phone}`)
                            .then((response) => {
                                // console.log(response.status)
                                if(response.data.notExist == true){
                                resolve(true)
                                }else{
                                resolve(false)
                                }
                            })
                        })
                    }
                ),
        user_email: Yup.string()
                    .email('Must be a valid email')
                    .required('Email is required')
                    .test('Unique Email', 'Email already existing',  
                        function (email) {  
                        return new Promise((resolve, reject) => {
                                http.get(`/users/user_email/${email}`)
                                .then((response) => {
                                    // console.log(response.status)
                                    if(response.data.notExist == true){
                                    resolve(true)
                                    }else{
                                    resolve(false)
                                    }
                                })
                            })
                        }
                    ),
        pincode: Yup.string()
                    .matches(user_pincode, 'Enter Valid Pincode')
                    .required('Pincode Required')
    })
    const onSubmit = (data:any) =>{
        setLoading(true);
        setError(false);
        console.log(data);
        http.post("/users/checkout", data).then(response => {
        //   console.log(response.data.user_email)
          setCookie('user_email', response.data.user_email, {expires:365});
          toast.success('Account Created Successfully !',{position:toast.POSITION.TOP_RIGHT});        
          setTimeout(() => {
            // window.location.reload();
            navigate('/live_shop');
          }, 2000); 
          setLoading(false);
        }).catch(err =>{
        //   console.log(err)
          setLoading(false);
          setError(true);
        })
      }
    return(
        <>
            {/*section start*/}
            <section style={{backgroundColor:'#fff'}} className="login-page section-b-space">
                <div className="container bg-img2">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <a href="/main"><h2 className="text-center mb-3" style={{fontSize: '42px'}}>ARKWRX</h2></a>
                            <div className="theme-card" style={{border:'none',borderRadius:'10px',boxShadow: "0 .1rem 1rem .25rem rgba(0,0,0,.05)"}}>
                            <Formik initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={validationSchema}
                                setFieldValue>
                                <Form className="custom-form" style={{float: 'none'}}>
                                    <div className='text-center mb-10'>
                                        <h4 className='text-dark mb-3 fw-600'>Create an Account</h4>
                                        <h5 className='text-gray-400 fw-600 fs-4'>
                                            Already have an account?
                                            <Link to='/Login' className='link-primary fw-bolder'>
                                               Login
                                            </Link>
                                        </h5>
                                    </div> 
                                    <div className="form-group mt-4">
                                        <label className="fw-600" htmlFor="user_name"><span className="text-danger">*</span> Name</label>
                                        <Field  type="text" className="form-control" name="user_name" id="user_name" placeholder="Full Name *" />
                                        <ErrorMessage name='user_name' component="span" className='text-danger'/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="email"><span className="text-danger">*</span> Phone Number</label>
                                        <Field  type="text" className="form-control" name="phone" id="phone" placeholder="Phone Number" />
                                        <ErrorMessage name='phone' component="span" className='text-danger'/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="user_email"><span className="text-danger">*</span> Email</label>                                        
                                        <Field  type="email" className="form-control" name="user_email" id="user_email" placeholder="Email *" />
                                        <ErrorMessage name='user_email' component="span" className='text-danger'/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="user_email"><span className="text-danger">*</span> Password</label>                                        
                                        <Field  type="password" className="form-control" name="user_pass" id="user_pass" placeholder="Password *" />
                                        <ErrorMessage name="user_pass" component="div" className="text-danger" />
                                    </div>                                    
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="address">Address</label>
                                        <Field  type="text" className="form-control" name="address" id="address" placeholder="Address " />
                                        <ErrorMessage name='address' component='span' className='text-danger'/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="city">City</label>
                                        <Field  type="text" className="form-control" name="city" id="city" placeholder="City "/>
                                        <ErrorMessage name="city" component="span" className="text-danger"/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="state">State</label>
                                        <Field  type="text" className="form-control" name="state" id="state" placeholder="State "/>
                                        <ErrorMessage name="state" component="span" className="text-danger"/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="country">Country</label>
                                        <Field  type="text" className="form-control" name="country" id="country" placeholder="Country " />
                                        <ErrorMessage name='country' component="span" className="text-danger"/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="pincode"><span className="text-danger">*</span> Pincode</label>
                                        <Field  type="text" className="form-control" name="pincode" id="pincode" placeholder="Pincode *" />
                                        <ErrorMessage name='pincode' component="span" className="text-danger"/>
                                    </div>
                                    <div className="clearfix">                                    
                                    {error && <p className='text-center text-danger'>Something Went Wrong</p> }
                                    </div>
                                    {!loading && 
                                        <button style={{width:'100%'}} type="submit" className="btn btn-mine mx-0 mt-3">Submit</button>
                                    }
                                    {loading && (
                                        <button style={{width:'100%'}} disabled type="submit" className="btn btn-mine mx-0 mt-3">Submit...</button>
                                    )}
                                    </Form>
                            </Formik>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>
            {/*Section ends*/}

        </>
    )
}