import { Link } from "react-router-dom";
import Header from "../headers/header";
import HeadStart from "../headers/head_start";
import ProductsFooter from "../footer/footer";
import Content from "./content";
import LoadStart from "../headers/load_start";

export default function Login(){
    return(
        <div id="shoping-Pages">
            <Header />
            {/* <LoadStart/> */}
            {/* <HeadStart /> */}
            <Content />
            <ProductsFooter />
        </div> 
    )
}