import { Link } from "react-router-dom";

export default function LeftNav() {
    return(
        <>
            
            {/* header*/}
            <header className="main-header">
                <Link className="logo-holder" to="/">
                <img src="/assets/images/logo.jpg" alt="" />
                </Link>
                {/* nav-button-wrap*/}
                <div className="nav-button but-hol">
                <span className="nos" />
                <span className="ncs" />
                <span className="nbs" />
                <div className="menu-button-text">Menu</div>
                </div>
                {/* nav-button-wrap end*/}
                <div className="header-social">
                <ul>
                    <li><a href="#" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#" target="_blank"><i className="fab fa-instagram" /></a></li>
                    <li><a href="#" target="_blank"><i className="fab fa-google" /></a></li>
                </ul>
                </div>
                {/*  showshare */}
                <div className="show-share nav-button-2 but-hol-2">
                <i className="fal fa-user" />
                {/*            <i class="far fa-share-square"></i>*/}
                <span>Profile</span>
                </div>
                {/*  showshare end */}
            </header>
            {/*  header end */}            

        </>
    )
}