import Header from "../headers/header";
import HeadStart from "../headers/head_start";
import LoadStart from "../headers/load_start";
import BreadCrumbs from "../headers/breadcrumb";
import ProductsFooter from "../footer/footer";
//import SideNav from "../navs/side_bar";
import Content from './content';

export default function View_Products(){
    return(
        <>
        <div id="shoping-Pages">
            <Header/>
            {/*<LoadStart/>*/}
            <HeadStart/>
            <BreadCrumbs/>
            <Content/>
            <ProductsFooter/>
            </div>
        </>
    )
}