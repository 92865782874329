export default function loader() {
    return(
        <>
        {/*loader*/}
        <div className="loader-wrap">
            <div className="pin" />
        </div>
        {/*loader end*/}
        {/* Main  */}
        </>
    )
}