import Header from "../headers/header";
import HeadStart from "../headers/head_start";
import LoadStart from "../headers/load_start";
import BreadCrumbs from "../headers/breadcrumb";
import ProductsFooter from "../footer/footer";
import SideNav from "../navs/side_bar";
import Content from './content';
import { useEffect, useRef, useState } from "react";
import http from "../../../http-common";

export default function View_Products(){

    const [getPageNuber, setPageNumber] = useState(12);
    Content(getPageNuber);
    
    const listInnerRef:any = useRef();
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                console.log("reached bottom");
                setPageNumber((prev) => prev + 12);
            }
        }
    };

    return(
        <>
            
            <div id="shoping-Pages"
                 >
                <Header/>
                {/* <LoadStart/> */}
                <HeadStart/>
                <BreadCrumbs/>
                <section className="section-b-space ratio_asos">
                    <div className="collection-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <Content/>
                            </div>
                        </div>
                    </div>
                </section>
                <ProductsFooter/>
            </div>
        </>
    )
}