import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'typescript-cookie'
import http, {baseUrl, baseUrl2} from "../../../http-common";
import {Formik, Form, Field, ErrorMessage} from "formik";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import "./style.css";
import "./content.css";

//Payment Gateway
function isDate(val: any) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
}

function isObj(val: any) {
    return typeof val === 'object'
}

function stringifyValue(val: any) {
    if (isObj(val) && !isDate(val)) {
        return JSON.stringify(val)
    } else {
        return val
    }
}

function buildForm(details:any) {
    console.log(details);
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action',details.action)

    Object.keys(details.params).forEach(key => {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', stringifyValue(details.params[key]))
        form.appendChild(input)
    })

    return form
}

function post(details: { action: any; params: any, body_rec:any }) {
    const form = buildForm(details)
    console.log(form);
    document.body.appendChild(form);
    form.submit()
    form.remove()
}

export default function Content(){

    //Constant declaration
    const [getCart, setCart] = useState([])
    const [getUserId, SetUserId] = useState(0)
    const [getUser, setUser] = useState([] as any)
    const [getSubUser, setSubUser] = useState([] as any)
    let MainValue = 0
    let cart_total = 0
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [sucess, setSuccess] = useState(false)
    const [getDeliveryCharges, setDeliveryCharges] = useState(0)
    const [getUserRegistered, setUserRegistered] = useState(false)
    const [getParent_user_id, set_parent_user_id] = useState(0)
    const [getUserrType, setUserType] = useState('')

    const email = getCookie('user_email')
    let unique_id = getCookie('unique_id')

    const [validCreditValue, setValidcreditValue] = useState(true);
    const [creditAmount, setCreditAmount] = useState(0);
    const [getCreditData, setCreditData] = useState(0);

    //Credit bill data
    const [getCredittableData, setCreditTableData] = useState([] as any);
    const [getCreditLimitStatus, setCreditLimitStatus] = useState(false);

    //Order Details
    const [getResellerTotal, setResellerTotal] = useState(0);
    const [getUserTotal, setUserTotal] = useState(0);
    const [getMrpTotal, setMrpTotal] = useState(0);
    const [getOldActualAmount, setOldActualAmount] = useState(0);

    let date_now = Date.now();

    const [getreseller_credit_id, setreseller_credit_id] = useState(0);

    //checking for user email in coockie
    useEffect(() => {
        if(!getCookie('user_email') || getCookie('user_email') == '' || getCookie('user_email') == "undefined"){
            http.get('/cart/byuniqueid/'+unique_id).then(Response => {
                setCart(Response.data);
                let UserTotal = 0;
                let ResellerTotal = 0;
                let MrpTotal = 0;
                if(Response.data.length != 0){
                    Response.data.map((cart_data:any) => {
                        if(cart_data.cart_products.length != 0){
                            cart_data.cart_products.map((cartProducts:any) => {
                                UserTotal = Number(UserTotal) + Number(cartProducts.price);
                                ResellerTotal = Number(ResellerTotal) + Number(cartProducts.rs_price);
                                MrpTotal = Number(MrpTotal) + Number(cartProducts.mrp);
                            })
                        }
                    })
                }
                // console.log(UserTotal, ResellerTotal, MrpTotal);
                setResellerTotal(ResellerTotal);
                setUserTotal(UserTotal);
                setMrpTotal(MrpTotal);
            }).catch(error => console.log(error))
        }else{          
            http.get('/users/byUserEmail/'+email).then(Response => {
                SetUserId(Response.data.id);
                set_parent_user_id(Response.data.id);
                setCreditAmount(Response.data.user_credit);
                setUser(Response.data);
                setUserRegistered(true);
                setUserType(Response.data.user_type);
                // console.log(Response);
                http.get('/cart/byuserid/'+Response.data.id).then(Response => {
                    setCart(Response.data);                    
                    let UserTotal = 0;
                    let ResellerTotal = 0;
                    let MrpTotal = 0;
                    if(Response.data.length != 0){
                        Response.data.map((cart_data:any) => {
                            if(cart_data.cart_products.length != 0){
                                cart_data.cart_products.map((cartProducts:any) => {
                                   UserTotal = Number(UserTotal) + Number(cartProducts.price);
                                   ResellerTotal = Number(ResellerTotal) + Number(cartProducts.rs_price);
                                   MrpTotal = Number(MrpTotal) + Number(cartProducts.mrp);
                                })
                            }
                        })
                    }
                    // console.log(UserTotal, ResellerTotal, MrpTotal);
                    setResellerTotal(ResellerTotal);
                    setUserTotal(UserTotal);
                    setMrpTotal(MrpTotal);
                }).catch(error => console.log(error))
                http.get("/users/usermappingdata/"+Response.data.id).then(Response => {
                    setSubUser(Response.data);
                    // console.log(Response.data);
                }).catch(error => console.log(error))
                if(Response.data.user_type == 'ResellerType' && Response.data.id != 0){
                    http.get('/cart/get_creditdetails/'+Response.data.id).then(Response => {
                        setCreditTableData(Response.data);
                        setreseller_credit_id(Response.data.credit_data.id);
                        if(Response.data.due_data.credit_amount != null){
                           setCreditData(Response.data.due_data.credit_amount);
                        }
                        if(Response.data.due_data.actual_amount != null){
                           setOldActualAmount(Response.data.due_data.actual_amount);
                        }
                        if(date_now < Response.data.credit_data.due_date){
                            setCreditLimitStatus(true);
                        }
                    })
                    .catch(error => console.log('user credit error : '+error))
                }
            }).catch(error => console.log(error))
        }       
    }, [])

   const initialValues = {
    user_name:"",
    user_email:"",
    phone:"",
    user_pass:"",
    user_image:"",
    agent_type:"",
    address:"",
    city:'',
    state:'',
    country:'',
    pincode:'',
    is_verified:'Yes',
    token:"",
    user_type:"User",
    status:"Yes",
    created_by:0,
    updated_by:0,
    unique_id:unique_id
  };

  //Initial values for adding purches for user
  const initialValues1 ={
    user_name:"",
    user_email:"",
    phone:"",
    user_pass:"",
    user_image:"",
    agent_type:"",
    address:"",
    city:'',
    state:'',
    country:'',
    pincode:'',
    is_verified:'Yes',
    token:"",
    user_type:"User",
    status:"Yes",
    created_by:getUserId,
    updated_by:getUserId,
    parent_user_id:getUserId
  } 

  const user_phnumber = /^[0-9]\d{9}$/;
  const user_pincode = /^[0-9]\d{5}$/;
  //Yup validation
  const validationSchema2 = Yup.object().shape({
        user_name: Yup.string().required("Name is required"),
        user_pass: Yup.string().required("Password Required"),
        phone: Yup.string()
                .matches(user_phnumber, 'Enter Valid Phone Number')
                .required('Phone Number Required')
                .test('Unique Phone number', 'Phone number already existing',
                function (phone) {  
                    return new Promise((resolve, reject) => {
                            http.get(`/users/phone/${phone}`)
                            .then((response) => {
                                // console.log(response.status)
                                if(response.data.notExist == true){
                                resolve(true)
                                }else{
                                resolve(false)
                                }
                            })
                        })
                    }
                ),
        user_email: Yup.string()
                    .email('Must be a valid email')
                    .required('Email is required')
                    .test('Unique Email', 'Email already existing',  
                        function (email) {  
                        return new Promise((resolve, reject) => {
                                http.get(`/users/user_email/${email}`)
                                .then((response) => {
                                    // console.log(response.status)
                                    if(response.data.notExist == true){
                                    resolve(true)
                                    }else{
                                    resolve(false)
                                    }
                                })
                            })
                        }
                    ),
        pincode: Yup.string()
                    .matches(user_pincode, 'Enter Valid Pincode')
                    .required('Pincode Required')
    })

    const validationSchema = Yup.object().shape({
        user_name: Yup.string().required("Name is required"),
        user_pass: Yup.string().required("Password Required"),
        phone: Yup.string()
                  .matches(user_phnumber, 'Enter Valid Phone Number')
                  .required('Phone Number Required')
                  .test('Unique Phone number', 'Phone number already existing',
                        function (phone) {  
                            return new Promise((resolve, reject) => {
                                http.get(`/users/phone/${phone}`)
                                .then((response) => {
                                    // console.log(response.status)
                                    if(response.data.notExist == true){
                                    resolve(true)
                                    }else{
                                    resolve(false)
                                    }
                                })
                            })
                        }
                   ),
        user_email: Yup.string()
                        .email('Must be a valid email')
                        .required('Email is required')
                        .test('Unique Email', 'Email already existing',  
                            function (email) {  
                                return new Promise((resolve, reject) => {
                                    http.get(`/users/user_email/${email}`)
                                    .then((response) => {
                                        // console.log(response.status)
                                        if(response.data.notExist == true){
                                        resolve(true)
                                        }else{
                                        resolve(false)
                                        }
                                    })
                                })
                            }
                        ),                    
        pincode: Yup.string()
                    .matches(user_pincode, 'Enter Valid Pincode')
                    .required('Pincode Required')
    })

    //User account registration 
    const onSubmit = (data:any) =>{
        setLoading(true);
        setError(false);
        // console.log(data);
        http.post("/users/checkout", data).then(response => {
            //   console.log(response.data.user_email)
            setCookie('user_email', response.data.user_email, {expires:365});
            toast.success('Account Created Successfully !',{position:toast.POSITION.TOP_RIGHT});        
            setTimeout(() => {
                window.location.reload();
                navigate('/check-out');
            }, 2000); 
            setLoading(false);
        }).catch(err =>{
            //   console.log(err)
            setLoading(false);
            setError(true);
        })
    }

    //User Profile account creation
    const onSubmit2 = (data:any) =>{
        setLoading(true);
        setError(false);
        // console.log(data);
        http.post("/users/user_mapping", data).then(response => {
        //   console.log(response.data.user_email)
        //setCookie('user_email', response.data.user_email, {expires:365});
        toast.success('User Added Successfully !',{position:toast.POSITION.TOP_RIGHT});        
        setTimeout(() => {
            window.location.reload();
            navigate('/check-out');
        }, 2000); 
        setLoading(false);
        }).catch(err =>{
        //   console.log(err)
        setLoading(false);
        setError(true);
        })
    }

    const getData=(data: { amount: number; email: any, phone: any, data:any })=>
    {   
        // console.log(data);
        // return http.post('/paytm_payments/payment').then(response=>response).catch(err=>console.log(err))
        return fetch(``+baseUrl+`/paytm_payments/payment`,{
            method:"POST",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json"
            },
            body:JSON.stringify(data)
        }).then(response=>response.json()).catch(err=>console.log(err))
    }
    //Check out
    const addProductToCheckout = (cart_id:any, order_user_id:any, user_id:any, cart_total:any,charge_total:any, order_total:any, c_amount:any) => {
        // setValidcreditValue(true);        
        let data ={
            cart_id:cart_id,
            user_id:order_user_id,
            parent_user_id:user_id,
            cart_total:cart_total,
            charge_total:charge_total, 
            order_total:order_total,
            delivery_time:'',
            deliver_later:'',
            order_comments:'',
            status:'Ordered',
            payment_status:'Completed',
            order_received:'',
            preparing_your_order:'',
            out_for_delivery:'',
            delivered_on_time:'', 
            address_id:0, 
            payment_type:'Online',
            invoice_no:'',
            admin_approval:'',
            created_by:user_id,
            updated_by:user_id,
            credit_amount:0,
            paid_amount:0,
            actual_amount:Number(getUserTotal)+Number(charge_total),
            user_email:email,
            user_phone:getUser.phone,
            reseller_credit_id:getreseller_credit_id
        }
        if(getUserrType == 'ResellerType' && Number(creditAmount) - Number(getUserTotal) - Number(getOldActualAmount) >= 0 ){
            data.payment_status = 'Completed';
            data.cart_total = getResellerTotal;
            data.order_total = Number(getResellerTotal) + Number(charge_total);
            data.payment_type = 'Credit Zone';
            data.credit_amount = Number(getResellerTotal) + Number(charge_total);
            console.log(data);
            http.post('/order_details', data).then(Response => 
            {
                toast.success('Order Placed Successfully!',{position:toast.POSITION.TOP_RIGHT});
                setTimeout(() => {                
                    navigate('/orders_list');            
                }, 2000);
                console.log(Response);
            }).catch(err => {
                console.log(err);
            })            
        }else{
            //Creating CheckSum Value;
            data.payment_status = 'Pending';
            data.cart_total = getUserTotal;
            data.order_total = Number(getUserTotal) + Number(charge_total);
            data.paid_amount = Number(getUserTotal) + Number(charge_total);
            console.log(data);
            getData({amount:Number(getUserTotal) + Number(charge_total),email:email, phone:getUser.phone, data:data}).then(response=>{            
                console.log(response);
                if(response.order_status == true){
                    var information={
                        action:"https://securegw-stage.paytm.in/theia/processTransaction",
                        params:response,
                        body_rec:data
                    }
                    post(information);
                }else{
                    toast.error('Something Went Wrong Try after some time!',{position:toast.POSITION.TOP_RIGHT});     
                }
            })
        } 
    }

    // let s_total = 0;
    // function credit_function(sub_total:any, e:any){
    //     console.log(sub_total+ ',' + e.target.value);
    //     if (e.target.value >= 0 && e.target.value <= getUser.user_credit && e.target.value <= sub_total){
    //         // console.log(sub_total+ ',' + e.target.value);
    //         setCreditAmount(e.target.value);
    //         setValidcreditValue(true);
    //     }else{
    //         setValidcreditValue(false);
    //         setCreditAmount(0);
    //     }    
    // }
    return(
        <>           
            {/* section start */}
            <section className="section-b-space p-3">
                <div className="container">
                    <div className="checkout-page">
                        <div className="checkout-form">
                            {getCart?.map ? getCart.map((cart:any, i, MrpValue:any) => (
                                <div className="row border_radius-10">
                                    <div className="col-xl-8 p-2 m-0 bg-white border_radius-10 p-2">
                                        <h6 className="font-weight-bold text-gray mb-3">Order Details</h6>
                                        <div className="table-responsive">
                                            <table className="table cart-table table-hover table-borderless">
                                                <thead style={{borderBottom:'1px dashed #777777'}}>
                                                    <tr>
                                                        <th>Product Details</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* Displaying Products */}
                                                    {cart.cart_products?.map ? cart.cart_products.map((cartProducts:any, k:0) => (
                                                        <>
                                                            {cartProducts.products?.map ? cartProducts.products.map((products:any, value:any, mrp:any, value2:any) => (
                                                            <tr style={{borderBottom:'1px dashed #777777'}}>
                                                                <td>
                                                                    <p style={{display:'none'}}>{value=0}{mrp=0} {value2=0}</p>
                                                                    <div className="d-flex">
                                                                        <div className="pr-2 my-auto">
                                                                            <Link to={'#'}>
                                                                                    <> 
                                                                                        {products.image != '' ?
                                                                                            <>
                                                                                                <img width={"50px"} src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt=""/>
                                                                                                
                                                                                            </>
                                                                                        :
                                                                                            <>
                                                                                                <img height={"50px"} src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt=""/>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                
                                                                            </Link>
                                                                        </div>
                                                                        <div className="border-dashed-2px-lt pl-2">                                                                                
                                                                            <h6 className="mb-0"><b>TITLE</b> - {products.product_name}</h6>
                                                                            <p className="mb-1"><b>{products.short_description}</b></p>                                                                                
                                                                            <div className="row">
                                                                                <p style={{display:'none'}}>{value = Number(value) + Number(products.product_price)}</p>
                                                                                {cartProducts.cart_product_details?.map ? cartProducts.cart_product_details.map((cartProductDetails:any) => 
                                                                                    <>
                                                                                        {cartProductDetails.product_option_values?.map ? cartProductDetails.product_option_values.map((ProductOptionValues:any) => 
                                                                                            <> 
                                                                                                <p className="col-12 mb-0"> 
                                                                                                    {ProductOptionValues.product_option.option_name} - {ProductOptionValues.value_name}
                                                                                                </p>
                                                                                                <p style={{display:'none'}}>{value = Number(value) + Number(ProductOptionValues.price)}</p>
                                                                                            </>
                                                                                        ) : ''}
                                                                                    </>
                                                                                ) : ''}
                                                                                {cartProducts.cart_product_languages?.map ? cartProducts.cart_product_languages.map((cartProductLanguages:any, j:Number) => 
                                                                                    <>  
                                                                                        <p style={{display:'none'}}>{j = Number(j) + 1}</p>
                                                                                        <p className="col-12" style={{ marginBottom: "0",color:"#474747"}}> 
                                                                                        Language - {cartProductLanguages.language.language_name}
                                                                                        </p>
                                                                                        {
                                                                                            j == 1 ? 
                                                                                            <>                                                                                    
                                                                                                <p style={{display:'none'}}>{value2 = Number(value2) + Number(value)}</p>
                                                                                            </> :
                                                                                            <>                                                                                      
                                                                                                <p style={{display:'none'}}>{value2 = Number(value2) + Number((Number(cartProductLanguages.product_language_option.price_percentage) * Number(value))/100)}</p>
                                                                                            </>
                                                                                        }                                                                                                          
                                                                                    </>
                                                                                ) : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </td>
                                                                <td>
                                                                    <div className="my-auto">
                                                                        <h6 className="col-12">
                                                                            {getUserrType == 'ResellerType' && Number(creditAmount) - Number(getUserTotal) - Number(getOldActualAmount) >= 0 ?
                                                                                <>₹ {cartProducts.rs_price}.00 /- &nbsp;&nbsp;</>
                                                                            :
                                                                                <>₹ {cartProducts.price}.00 /- &nbsp;&nbsp; </>                                                                                                        
                                                                            } 
                                                                            {cartProducts.mrp != cartProducts.price && 
                                                                                <>
                                                                                    <b className="m-auto"> you save </b>&nbsp;&nbsp;
                                                                                    <del className="text-color-pink font-size-15px m-auto">₹ {mrp = cartProducts.mrp}</del> &nbsp;&nbsp;
                                                                                    {getUserrType == 'ResellerType' && Number(creditAmount) - Number(getUserTotal) - Number(getOldActualAmount) >= 0 ?
                                                                                        <span className="text-color-green font-size-11px m-auto"> {Math.round(((Number(mrp) - Number(cartProducts.rs_price))/Number(mrp))*100)}% off</span>
                                                                                    :
                                                                                        <span className="text-color-green font-size-11px m-auto"> {Math.round(((Number(mrp) - Number(cartProducts.price))/Number(mrp))*100)}% off</span>                                                                                                                
                                                                                    }
                                                                                </>
                                                                            }
                                                                            &nbsp;&nbsp; 
                                                                        </h6>
                                                                        {getUserrType == 'ResellerType' ? 
                                                                            <p style={{display:'none'}}>{MainValue = Number(MainValue) + Number(cartProducts.rs_price)}</p>
                                                                        :
                                                                            <p style={{display:'none'}}>{MainValue = Number(MainValue) + Number(cartProducts.price)}</p>
                                                                        }
                                                                        <p style={{display:'none'}}>{MrpValue = Number(MrpValue) + Number(mrp)}</p>
                                                                    </div>                                                                
                                                                </td>
                                                            </tr>
                                                            )) : ''}
                                                        </>
                                                    )) : ''}
                                                </tbody>
                                            </table>
                                        </div>
                                        {getUserRegistered &&
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="sel1" className="font-weight-bold ml-2">Ordered for:</label>
                                                        <select onChange={(e:any) => set_parent_user_id(e.target.value)} className="form-control text-white bg-secondary rounded" id="sel1">
                                                            <option value={getUser.id}>Self</option>                                                                
                                                            {getSubUser.length == 0 ? '' : 
                                                                <>  
                                                                    {getSubUser?.map ? getSubUser.map((m_user:any) => 
                                                                        <>
                                                                            <option value={m_user.user_id}>{m_user.user.user_name} ({m_user.user.user_email})</option>
                                                                        </>
                                                                    ) :''}                                                                        </>
                                                            }
                                                        </select>
                                                    </div>  
                                                </div>
                                                <div className="col-6" style={{margin: "auto",float: 'right',textAlign: 'end'}}>
                                                    <button data-toggle="modal" data-target="#AddUser" className="btn btn-solid border_radius-10"> Add User</button>
                                                    <div id="AddUser" className="modal fade">
                                                        <div className="modal-dialog">
                                                        {/* Modal content */}
                                                            <div className="modal-content">
                                                            {/* <!-- Modal Header --> */}
                                                                <div className="modal-header bg-info">
                                                                    <h4 className="modal-title">Add User</h4>
                                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <Formik initialValues={initialValues1}
                                                                        onSubmit={onSubmit2}
                                                                        validationSchema={validationSchema2}
                                                                        setFieldValue>
                                                                        <Form className="custom-form text-center" style={{float: 'none'}}>
                                                                            <Field  type="text" className="form-control" name="user_name" id="user_name" placeholder="Full Name *" />
                                                                            <ErrorMessage name='user_name' component="span" className='text-danger'/>
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="phone" id="phone" placeholder="Phone Number *" />
                                                                            <ErrorMessage name='phone' component="span" className='text-danger'/>
                                                                            <br/>
                                                                            <Field  type="email" className="form-control" name="user_email" id="user_email" placeholder="Email *" />
                                                                            <ErrorMessage name='user_email' component="span" className='text-danger'/>
                                                                            <br/>
                                                                            <Field  type="password" className="form-control" name="user_pass" id="user_pass" placeholder="Password *" />
                                                                            <ErrorMessage name="user_pass" component="span" className="text-danger" />
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="address" id="address" placeholder="Address " />
                                                                            <ErrorMessage name='address' component='span' className='text-danger'/>
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="city" id="city" placeholder="City "/>
                                                                            <ErrorMessage name="city" component="span" className="text-danger"/>                                                                            
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="state" id="state" placeholder="State "/>
                                                                            <ErrorMessage name="state" component="span" className="text-danger"/>                                                                            
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="country" id="country" placeholder="Country " />
                                                                            <ErrorMessage name='country' component="span" className="text-danger"/>                                                                            
                                                                            <br/>
                                                                            <Field  type="text" className="form-control" name="pincode" id="pincode" placeholder="Pincode *" />
                                                                            <ErrorMessage name='pincode' component="span" className="text-danger"/>
                                                                            <br/>                    
                                                                            <div className="clearfix">
                                                                            
                                                                            {error && <p className='text-center text-danger'>Something Went Wrong</p> }
                                                                            {sucess && <p className='text-center text-success'>Category Added Successfully</p> }
                                                                            </div>
                                                                            {!loading && 
                                                                                <button type='submit' className="btn btn-info" style={{float: 'none'}}>
                                                                                    <span className="indicator-label">Submit</span>
                                                                                </button>
                                                                            }
                                                                            {loading && (
                                                                                <button type='submit' disabled className="btn btn-info" style={{float: 'none'}}>
                                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                                                    Please wait...
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                                </button>
                                                                            )}
                                                                            </Form>
                                                                    </Formik>
                                                                </div>      
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }                               
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="bg-white rounded p-2">
                                            <div className="order-box mb-0">
                                                <div className="title-box pb-1 mb-3">
                                                    <h6><b>Price Details</b></h6>
                                                </div>
                                                <ul className="sub-total mb-0 mt-2" style={{borderBottom:"#0000"}}>
                                                    <p style={{display:'none'}}>{cart_total = Number(MainValue)}</p>
                                                    
                                                    {getUserrType == 'ResellerType' && creditAmount > 0 ? 
                                                        <>   
                                                           {Number(creditAmount) - Number(getUserTotal) - Number(getOldActualAmount) >= 0 ? 
                                                              <>
                                                                {creditAmount > 0 &&
                                                                  <>
                                                                    <li>Credit Limit  <span className="count font-weight-bold"> ₹ {creditAmount}.00 /-</span></li>
                                                                    <li>Available Credit Limit <span className="count font-weight-bold"> ₹ {Number(creditAmount) - Number(getCreditData)}.00 /-</span></li>
                                                                 </>
                                                                }
                                                                <li>Subtotal <span className="count font-weight-bold">₹ {Number(getResellerTotal)+Number(getDeliveryCharges)}.00 /-</span></li>
                                                                <li>Remaining Credit Limit <span className="count font-weight-bold">₹ {Number(creditAmount) - Number(getCreditData) - Number(getResellerTotal)}.00 /-</span></li>
                                                                <li><span className="text-danger">*</span> If credit bill due date is expired need to pay ₹ {Number(getUserTotal)+Number(getDeliveryCharges)}.00 /-</li>
                                                              </>
                                                             :
                                                              <>
                                                                <li>Subtotal <span className="count font-weight-bold">₹ {Number(getUserTotal)+Number(getDeliveryCharges)}.00 /-</span></li>
                                                                <li>Amount To Pay <span className="count font-weight-bold">₹ {Number(getUserTotal)+Number(getDeliveryCharges)}.00 /-</span></li>
                                                              </>
                                                            }
                                                        </>
                                                        : 
                                                        <>
                                                            <li>Subtotal <span className="count font-weight-bold">₹ {MainValue = Number(getUserTotal)+Number(getDeliveryCharges)}.00 /-</span></li>
                                                            <li>Amount To Pay <span className="count font-weight-bold">₹ {MainValue = Number(getUserTotal)+Number(getDeliveryCharges)}.00 /-</span></li>                                                
                                                        </>
                                                    }  
                                                </ul>
                                                <ul className="sub-total mb-0">
                                                    <li>
                                                        <div className="radio-option">
                                                            <input style={{verticalAlign:'super'}} defaultChecked={true} onChange={(e) => setDeliveryCharges(0)} required type="radio" name={"delivery"} id="payment-1"/>
                                                            &nbsp; &nbsp;<label htmlFor="payment-1">Standard Delivery: <br/> <span className="small-text" style={{ display: "inline-block", fontSize: "10px", lineHeight: "1", color: "#8d8d8d" }}> Deliver with in 72 hours (Charges ₹0.00)
                                                            </span></label>
                                                        </div>
                                                        <div className="radio-option">
                                                            <input style={{verticalAlign:'super'}} onChange={(e) => setDeliveryCharges(300)} required type="radio" name={"delivery"} id="payment-2"/>
                                                            &nbsp; &nbsp;<label htmlFor="payment-2">Express Delivery: <br/> <span className="small-text" style={{ display: "inline-block", fontSize: "10px", lineHeight: "1", color: "#8d8d8d" }}> Deliver with in 24 hours (Charges ₹300.00)
                                                            </span></label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="total mb-0">
                                                    <li className="pt-2 text-white">Payment</li>
                                                </ul>
                                            </div>
                                            <div className="payment-box">
                                                <div className="upper-box" >
                                                    <div className="payment-options m-0" >
                                                        <ul>
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12 sol-xs-12">
                                                                    <li>
                                                                        <div className="radio-option">
                                                                            <input checked required type="radio" name="payment_type" id="payment-1" value="Online" />
                                                                            <label htmlFor="payment-1">Online Transfer<span className="small-text" style={{ display: "inline-block", fontSize: "14px", lineHeight: "1", color: "#8d8d8d" }}>Credit Card, Debit Cards,UPI Payment,
                                                                            Phone Pay,Google Pay, Paytm,Net Banking.
                                                                            </span></label>
                                                                        </div>
                                                                    </li>  
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>                                        
                                                </div>
                                                <div className="text-center">
                                                    {getUserId == 0 ? 
                                                        <>
                                                            {/* <button type="button" 
                                                                className="btn btn-solid" data-toggle="modal" data-target="#myModalone" style={{ width: "50%", borderRadius: "10px" }}>
                                                                    Place Order
                                                            </button> */}
                                                            <Link to={'/login'}
                                                                className="btn btn-solid" style={{ width: "50%", borderRadius: "10px" }}>
                                                                    Place Order
                                                            </Link>
                                                            {/* The Modal One */}
                                                            <div id="myModalone" className="modal fade">
                                                                <div className="modal-dialog">
                                                                {/* Modal content */}
                                                                    <div className="modal-content">
                                                                    {/* <!-- Modal Header --> */}
                                                                        <div className="modal-header bg-info">
                                                                            <h4 className="modal-title">Please Register</h4>
                                                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <Formik initialValues={initialValues}
                                                                                onSubmit={onSubmit}
                                                                                validationSchema={validationSchema}
                                                                                setFieldValue>
                                                                                <Form className="custom-form" style={{float: 'none'}}>
                                                                                    <Field  type="text" className="form-control" name="user_name" id="user_name" placeholder="Full Name *" />
                                                                                    <ErrorMessage name='user_name' component="span" className='text-danger'/>
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="phone" id="phone" placeholder="Phone Number" />
                                                                                    <ErrorMessage name='phone' component="span" className='text-danger'/>
                                                                                    <br/>
                                                                                    <Field  type="email" className="form-control" name="user_email" id="user_email" placeholder="Email *" />
                                                                                    <ErrorMessage name='user_email' component="span" className='text-danger'/>
                                                                                    <br/>
                                                                                    <Field  type="password" className="form-control" name="user_pass" id="user_pass" placeholder="Password *" />
                                                                                    <ErrorMessage name="user_pass" component="span" className="text-danger" />
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="address" id="address" placeholder="Address *" />
                                                                                    <ErrorMessage name='address' component='span' className='text-danger'/>
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="city" id="city" placeholder="City *"/>
                                                                                    <ErrorMessage name="city" component="span" className="text-danger"/>                                                                            
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="state" id="state" placeholder="State *"/>
                                                                                    <ErrorMessage name="state" component="span" className="text-danger"/>                                                                            
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="country" id="country" placeholder="Country *" />
                                                                                    <ErrorMessage name='country' component="span" className="text-danger"/>                                                                            
                                                                                    <br/>
                                                                                    <Field  type="text" className="form-control" name="pincode" id="pincode" placeholder="Pincode *" />
                                                                                    <ErrorMessage name='pincode' component="span" className="text-danger"/>
                                                                                    <br/>
                                                                                    <Field type="hidden" value="BussinessType" name="user_type" />                      
                                                                                    <div className="clearfix">
                                                                                    
                                                                                    {error && <p className='text-center text-danger'>Something Went Wrong</p> }
                                                                                    {sucess && <p className='text-center text-success'>Category Added Successfully</p> }
                                                                                    </div>
                                                                                    {!loading && 
                                                                                        <button type='submit' className="btn btn-info" style={{float: 'none'}}>
                                                                                            <span className="indicator-label">Submit</span>
                                                                                        </button>
                                                                                    }
                                                                                    {loading && (
                                                                                        <button type='submit' disabled className="btn btn-info" style={{float: 'none'}}>
                                                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                                                            Please wait...
                                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                        </span>
                                                                                        </button>
                                                                                    )}
                                                                                    </Form>
                                                                            </Formik>
                                                                        </div>      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* The Modal One ends */}
                                                        </>
                                                        :
                                                        <>
                                                            
                                                            {getCreditLimitStatus ? 
                                                                <>  
                                                                    <button type="button"
                                                                        data-toggle="modal" data-target="#myModaltwo"
                                                                        className="btn btn-solid" style={{ width: "50%", borderRadius: "10px" }}>Place Order</button>
                                                                    <div id="myModaltwo" className="modal fade">
                                                                        <div className="modal-dialog modal-lg">
                                                                        {/* Modal content */}
                                                                            <div className="modal-content">
                                                                            {/* <!-- Modal Header --> */}
                                                                                <div className="modal-header">
                                                                                    <h4 className="modal-title">Credit bill due date expired</h4>
                                                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                                </div>  
                                                                                <div className="modal-body">
                                                                                    <h6>Your credit bill limit due date is expired. Please pay your credit bill <b>₹ {getCredittableData.due_data.credit_amount}</b></h6>
                                                                                    <Link to={"/credit_bill"}>Click here to pay</Link>
                                                                                </div>    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :   
                                                                <>
                                                                    <button type="submit"
                                                                        onClick={() => addProductToCheckout(cart.id, getParent_user_id,getUserId, getMrpTotal, getDeliveryCharges, getUserTotal, creditAmount)} 
                                                                        className="btn btn-solid" style={{ width: "50%", borderRadius: "10px" }}>Place Order</button>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : '<p>No orders to show</p>'}
                        </div>
                    </div>
                </div>
            </section>
            {/* section end */}
            <ToastContainer autoClose={1000}/>
        </>
    )
}