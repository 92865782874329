import { Link } from "react-router-dom"
export default function Order(){
    return(
        <>
         {/* Content*/}
            <div className="content">
            <div className="single-page-decor" />
            <div className="single-page-fixed-row">
                <div className="scroll-down-wrap">
                <div className="mousey">
                    <div className="scroller" />
                </div>
                <span>Scroll Down</span>
                </div>
                <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left" /> <span>Back to home</span></Link>
            </div>
            {/* section*/}
          <div className="content">
                    {/* <!-- section end-->   */}
                    <section data-scrollax-parent="true" id="sec1" style={{ backgroundColor: "#000" }}>
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span></div>
                        <div className="container-fluid">
                            {/* <!-- blog-container  --> */}
                            <div className="fl-wrap post-container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        {/* <!-- post --> */}
                                        <div className="post fl-wrap fw-post" style={{ marginBottom: "0", padding: "0" }}>
                                            <div className="parallax-header col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12" style={{ padding: "5px 0 10px 0" }}>
                                                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                                    <h3 style={{ color: "white", marginTop: "10px", fontSize: "1rem" }}>Video title</h3>
                                                    </div>
                                                    {/* <div className="category-filter blog-btn-filter">
                                                        <div className="blog-btn">Versions</div>
                                                        <ul style={{ backgroundColor: "#444444", width: "230px", border: "none", borderRadius: "10px", padding: "5px 20px" }}>
                                                            <li style={{ borderBottom: "1px solid black", padding: "0" }}>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <a href="#" style={{ padding: "5px" }}>
                                                                        <span style={{ margin: "10px", color: "#fff" }}>V1</span>
                                                                        <img src="/assets/images/logo.jpg" style={{ width: "65px", height: "40px" , borderRadius: "12px",  }} alt="logo" />
                                                                        <h3 style={{ display: "inline-block", marginLeft: "10px", color: "#fff" }}>Video Title</h3>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li style={{ padding: "0" }}>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <a href="#" style={{ padding: "5px" }}>
                                                                        <span style={{ margin: "10px", color: "#fff" }}>V1</span>
                                                                        <img src="/assets/images/logo.jpg" style={{ width: "65px", height: "40px" , borderRadius: "12px",  }} alt="logo" />
                                                                        <h3 style={{ display: "inline-block", marginLeft: "10px", color: "#fff" }}>Video Title</h3>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div> */}
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                                        <form action="" style={{ marginTop: "5px" }}>
                                                            <select className="form-select" id="sel1" name="sellist1" style={{ width: "130px", color: "white", border: "1px solid gray", borderRadius: "5px", backgroundColor: "#565454b8", padding: "9px", marginTop: "5px" }}>
                                                              <option selected>Version</option>
                                                              <option>V1</option>
                                                              <option>V2</option>
                                                            </select>
                                                        </form>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                                        <form action="" style={{ marginTop: "5px" }}>
                                                            <select className="form-select" id="sel1" name="sellist1" style={{ width: "130px", color: "white", border: "1px solid gray", borderRadius: "5px", backgroundColor: "#565454b8", padding: "9px", marginTop: "5px" }}>
                                                              <option selected>Language</option>
                                                              <option>English</option>
                                                              <option>Telugu</option>
                                                              <option>Hindi</option>
                                                            </select>
                                                        </form>
                                                    </div>
                                                </div>
                                            <div  className="col-lg-5 col-md-5 col-sm-5 col-xs-12" style={{  padding: "13px 0" }}>
                                                <div className="col-lg-6 col-xs-6" style={{ paddingRight: "0" }}>
                                                    <form action="">
                                                        <select className="form-select" id="sel1" name="sellist1" style={{ width: "130px", color: "white", border: "1px solid gray", borderRadius: "5px", backgroundColor: "#565454b8", padding: "9px" }}>
                                                          <option>Needs Review</option>
                                                          <option>In Progress</option>
                                                          <option>Approved</option>
                                                          <option selected>No Status</option>
                                                        </select>
                                                    </form>
                                                </div>
                                                <div className="col-lg-6 col-xs-6" style={{ display: "flex", paddingLeft: "0" }}>
                                                    <button className="" style={{ border: "1px solid gray", borderRadius: "5px", backgroundColor: "#565454b8", color: "white", padding: "9px", marginLeft: "5px" }}>Download</button>
                                                    <button className="" style={{ width: "60px", border: "1px solid gray", borderRadius: "5px", backgroundColor: "blue", color: "white", padding: "9px", marginLeft: "5px" }}>Share</button>
                                                </div>
                                            </div> 
                                        </div>
                                            {/* <!-- blog media --> */}
                                            <div className="blog-media fl-wrap nomar-bottom" style={{ height: "50vh" }}>
                                                <div className="img-wrapper" style={{ height: "100%" }}>
                                                    
                                                    <iframe style={{ width: "100%", height: "100%" }} src="https://www.youtube.com/embed/rMtMuBh-tP0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                </div>
                                            </div>
                                            {/* <!-- blog media end --> */}
                                        </div>
                                    </div>
                                    {/* <!-- blog-sidebar  --> */}
                                    <div className="col-lg-4">
                                        <div className="blog-sidebar fl-wrap" >
                                            {/* <!-- widget-wrap --> */}
                                            <div className="widget-wrap fl-wrap" style={{ height: "640px", borderRadius: "10px", borderColor: "#3c3c3c" , backgroundColor: "#000", overflow:"scroll" }}>
                                                <h4 className="widget-title" style={{ backgroundColor: "#3c3c3c", borderColor: "#3c3c3c" }}>Comments</h4>
                                                <div className="widget-container fl-wrap">
                                                    <div className="widget-posts fl-wrap">
                                                        <ul>
                                                            <li className="clearfix" style={{ marginBottom: "2px", backgroundColor: "#3c3c3c", borderRight: "0" }}>
                                                                {/* <a href="#"  className="widget-posts-img"><img src="images/folio/1.jpg" className="respimg" alt="" /></a> */}
                                                                <div className="widget-posts-descr" style={{ width: "95%" }}>
                                                                    <a href="#" title="" style={{ marginBottom: "5px", color: "white", fontSize: "13px"  }}><i className="fa fa-user" style={{ margin: "0 10px 0 0" }}></i>Vivamus dapibus rutrum</a>
                                                                    <p style={{ }}>comment1</p>
                                                                    <span className="widget-posts-date" style={{ marginTop: "0" }}> 21 Mar 09.05 </span> 
                                                                </div>
                                                            </li>
                                                            <li className="clearfix" style={{ marginBottom: "2px", backgroundColor: "#3c3c3c", borderRight: "0" }}>
                                                                {/* <a href="#"  className="widget-posts-img"><img src="images/folio/1.jpg" className="respimg" alt="" /></a> */}
                                                                <div className="widget-posts-descr" style={{ width: "95%" }}>
                                                                    <a href="#" title="" style={{ marginBottom: "5px", color: "white", fontSize: "13px" }}><i className="fa fa-user" style={{ margin: "0 10px 0 0" }}></i>In hac habitasse platea</a>
                                                                    <p>comment2</p>
                                                                    <span className="widget-posts-date" style={{ marginTop: "0" }}> 7 Mar 18.21 </span> 
                                                                </div>
                                                            </li>
                                                            <li className="clearfix" style={{ marginBottom: "2px", backgroundColor: "#3c3c3c", borderRight: "0" }}>
                                                                {/* <a href="#"  className="widget-posts-img"><img src="images/folio/1.jpg" className="respimg" alt="" /></a> */}
                                                                <div className="widget-posts-descr" style={{ width: "280px" }}>
                                                                    <a href="#" title="" style={{ marginBottom: "5px", color: "white", fontSize: "13px" }}><i className="fa fa-user" style={{ margin: "0 10px 0 0" }}></i>Tortor tempor in porta</a>
                                                                    <p>comment3</p>
                                                                    <span className="widget-posts-date" style={{ marginTop: "0" }}> 7 Mar 16.42 </span>
                                                                </div>
                                                            </li>
                                                            <li className="clearfix" style={{ marginBottom: "2px", backgroundColor: "#3c3c3c", borderRight: "0" }}>
                                                                {/* <a href="#"  className="widget-posts-img"><img src="images/folio/1.jpg" className="respimg" alt="" /></a> */}
                                                                <div className="widget-posts-descr" style={{ width: "280px" }}>
                                                                    <a href="#" title="" style={{ marginBottom: "5px", color: "white", fontSize: "13px" }}><i className="fa fa-user" style={{ margin: "0 10px 0 0" }}></i>Praesent aliquet tortor</a>
                                                                    <p>comment4</p>
                                                                    <span className="widget-posts-date" style={{ marginTop: "0" }}> 2 Mar 14.42 </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="comment-reply-form clearfix">
                                                        <form id="add-comment" className="add-comment custom-form" style={{ marginTop:"0px", paddingLeft: "0" }}>
                                                            <fieldset>
                                                                <div className="container-fluid" style={{ border: "1px solid black", borderRadius: "10px", backgroundColor: "#3c3c3c", marginTop: "16px" }}>
                                                                <div className="row">
                                                                    <div className="col-md-12 input-group">
                                                                        <label><i className="fal fa-user"></i></label>
                                                                        <input type="text" className="" placeholder="Leave Your Comments Here *" style={{ color: "white", marginBottom: "0", border: "none", borderRadius: "16px", backgroundColor: "#3c3c3c" }}/>
                                                                    </div>
                                                                        <div className="col-lg-12 col-md-12 col-sm-12 " style={{ height: "45px", display: "flex", justifyContent: "flex-end"  }}>
                                                                             <button type="button" className="" style={{ margin: "0 0 6px 0", borderRadius: "10px", backgroundColor: "gray", padding: "5px 25px 5px 25px" }} >Send</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- blog-sidebar end --> */}
                                    <div className="limit-box fl-wrap"></div>
                                </div>
                                {/* <!-- content-nav end-->      */}
                            </div>
                            {/* <!-- blog-container end    --> */}
                        </div>
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines" style={{ zIndex: "-1" }}></div>
                    </section>
                    {/* <!-- section end-->               */}
                    {/* <!-- section--> */}
                    {/* <section className="dark-bg2 small-padding order-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h3>Ready To order Your Project ?</h3>
                                </div>
                                <div className="col-md-4"><a href="contscts.html" className="btn flat-btn color-btn">Get In Touch</a> </div>
                            </div>
                        </div>
                    </section> */}
                    {/* <!-- section end--> */}
                </div>
            {/* section end*/}
            </div>
            {/* Content end */}


        </>
    )
}