import DataTable, { TableColumn } from 'react-data-table-component';
import Card from "@material-ui/core/Card";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useState, useEffect } from 'react';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl } from "../../../http-common";
import "./admin.css";

const sortIcon = <ArrowDownward />;

type DataRow = {
    user_name:string;
    phone:string;
    user_email:string;
    id:string;
    user:[];

};
const columns: TableColumn<DataRow>[] = [
    {
        name: 'S.NO',
        cell:(row, index) => <>{index+1}</>,
        // sortable:true
        width: "75px"
    },
    {
        name: 'User',
        cell: (row:any) => row.user.user_name,
        // sortable:true
    },
    {
        name: 'Relation Ship',
        selector: row => '-',
        // sortable:true
    },
    {
        name: 'Date of Birth',
        selector: row => 'xx-xx-xxxx',
        // sortable:true
    },
    {
        name: 'Contact Number',
        cell: (row:any) => row.user.phone,
        // sortable:true
    },
    {
        name: 'Email',
        cell: (row:any) => row.user.user_email,
        // sortable:true
    },
    // {
    //     name: "Actions",
    //     cell: (row) => <Link to={"/order_view/"+row.id} className="btn btn-primary">View</Link>,        
    // }
];
function handleAction(e:any, id:any){
    // console.log(e);
    // console.log(id);
}

const MyusersListTable: React.FC = () => {
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const [getOrdersData, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
            // console.log(Response);
            http.get("/users/usermappingdata/"+Response.data.id)
            .then((res: any) => {
                // console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            })            
        })
        
    }, []);

    return(
        <>
        <DataTable
        //   title="Orders"
          columns={columns}
          data={getOrdersData}
          sortIcon={sortIcon}
          pagination
          fixedHeader={true}
          responsive={true}
          striped={true}
          highlightOnHover={true}
        //   theme={'dark'}
          //   selectableRows
        />
        </>
    );
};

export default MyusersListTable;