import DataTable, { TableColumn } from 'react-data-table-component';
import Card from "@material-ui/core/Card";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useState, useEffect } from 'react';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl,baseUrl2 } from "../../../http-common";
import "./admin.css";
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Countdown from "react-countdown";
import { width } from '@mui/system';

const sortIcon = <ArrowDownward />;
// Random component
const Completionist = () => <span>You are good to go!</span>;
// Renderer callback with condition

function pad(num:any, size:any) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

type DataRow = {
    credit_amount : any,
    paid_amount : any,
    transaction_id : any,
    credit_date : any,
    due_date : any,
    payment_status : any,
    count_value:any
};
const columns: TableColumn<DataRow>[] = [
    {
        name: '#',
        cell: (row, index) => <>{index + 1}</>,
        sortable:true,
        width: '75px',
      },
    {
        name: <>Credit Amount</>,
        selector: row => "₹ "+row.credit_amount+"/-",
        // sortable:true
        // width: '150px'
    },
    {
        name: <>Paid Amount</>,
        selector: row => "₹ "+row.paid_amount+"/-",
        sortable:true,
        // width:'300px'
    },    
    {
        name: <>Bill Date</>,
        selector: (row) => moment(row.credit_date).format('MMMM Do, YYYY'),
        sortable:true,
        // width:'300px'
    },
    {
        name: <>Due Date</>,
        selector: (row) => moment(row.due_date).format('MMMM Do, YYYY'),
        sortable:true,
        // width:'300px'
    },
    {
        name: <>Status</>,
        cell: (row) => <div>
                            {row.payment_status == 'Completed' ? 
                                <div className='badge badge-success p-1'>Completed</div>
                            :
                                <div className='badge badge-warning p-1'>Pending</div>
                            }
                        </div>,
        // width:'300px'
    },
    {
        name: <>Transaction ID <br/> Bill Date</>,
        cell: row => <>{row.transaction_id} <br/> {moment(row.due_date).format('MMMM Do, YYYY')}</>,
        // sortable:true,
        // width:'300px'
    },
    
];
function handleAction(e:any, id:any){
    console.log(e);
    console.log(id);
}

const CreditDataTable: React.FC = () => {
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const [getOrdersData, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
            http.get("/users/user_credit_byid/"+Response.data.id)
            .then((res: any) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            })
        })
        
    }, []);

    return(
        <>
        <DataTable
        //   title="Orders"
          columns={columns}
          data={getOrdersData}
          sortIcon={sortIcon}
          pagination
          fixedHeader={true}
          responsive={true}
          striped={true}
          highlightOnHover={true}
        //   theme={'dark'}
          //   selectableRows
        />
        </>
    );
};

export default CreditDataTable;