import '../../css/landing/reset.scss';
import '../../css/landing/plugins.scss';
import '../../css/landing/style.scss';
import '../../css/landing/color.scss';

export default function Header(){
    
    return(
        <>
        
        </>
    )
}