import { Link } from 'react-router-dom';
import './home.scss';
import { FC, useEffect, useState } from 'react';
import axios from "axios";
// import http from "../../../http-common";
//import { data } from 'jquery';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import http,{baseUrl, baseUrl2} from "../../../http-common";

//for toast messages 
import { toast , ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import  { useNavigate } from 'react-router-dom'
import { setCookie } from 'typescript-cookie';
// import './style.css';
import Slider from 'react-slick';
import {Product4, Product5, Product3} from '../../services/slider';

const UseScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};


  

export default function MainContent(){

  const [getCategories, setCategories] = useState([]);
  const [getRecentProducts, setRecentProducts] = useState([]);
  const [getProductsByCategory, setProductsBycategory] = useState([]);
 
    UseScript('/assets/js/customjs/home.js');
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(false);
      const [sucess, setSuccess] = useState(false);
      const [getCategory, setCategory] = useState([]);

      
      const [error1, setError1] = useState(false);

      const[count, setCount] = useState(1);
      const navigate = useNavigate();

      useEffect(() => {
       http.get("/categories")
        .then(responce => {
          setCategory(responce.data);
        })
        .catch(err => console.log(err));
      }, [])

      useEffect(() => {
        http.get('/categories').then((Response) => {
          setCategories(Response.data);
          
        })
        .catch((error) => console.log(error));
      }, []);

      // console.log(getCategory);
      

    const initialValues = {
      user_name:"",
      user_email:"",
      phone:"",
      user_pass:"",
      user_image:"",
      agent_type:"",
      address:"",
      city:'',
      state:'',
      country:'',
      pincode:0,
      is_verified:'Yes',
      token:"",
      user_type:"User",
      status:"Yes",
      created_by:0,
      updated_by:0
    };
    const validationSchema = Yup.object().shape({
        user_name: Yup.string().required("User name is required"),
        user_email: Yup.string().required(),
        phone: Yup.string().required(),
        user_pass: Yup.string().required()
    })

    const initialValues2 = {
      user_name:"",
      user_email:"",
      phone:"",
      user_pass:"",
      user_image:"",
      agent_type:"",
      address:"",
      city:'',
      state:'',
      country:'',
      pincode:0,
      is_verified:'Yes',
      token:"",
      user_type:"ResellerType",
      status:"Yes",
      created_by:0,
      updated_by:0
    };
    const validationSchema2 = Yup.object().shape({
        user_name: Yup.string().required("User name is required"),
        user_email: Yup.string().required(),
        phone: Yup.string().required(),
        user_pass: Yup.string().required()
    })

    const initialValues3 = {
      user_name:"",
      user_email:"",
      phone:"",
      user_pass:"",
      user_image:"",
      agent_type:"",
      software_type:"",
      address:"",
      city:'',
      state:'',
      country:'',
      pincode:0,
      is_verified:'Yes',
      token:"",
      user_type:"TeamType",
      status:"Yes",
      created_by:0,
      updated_by:0
    };
    const validationSchema3 = Yup.object().shape({
        user_name: Yup.string().required("User name is required"),
        user_email: Yup.string().required(),
        phone: Yup.string().required(),
        user_pass: Yup.string().required()
    })
  
    const onSubmit = (data:any) =>{
      setLoading(true);
      setError(false);
      // console.log(data);
      http.post("/users", data).then(response => {
        // console.log(response)
        localStorage.setItem("user_email", response.data.user_email);
        setCookie('user_email', response.data.user_email, { expires: 90})
        toast.success('Account Created Successfully !',{position:toast.POSITION.TOP_RIGHT});
        setTimeout(() => {
          navigate('/live_shop');
        }, 2000); 
        setLoading(false);
      }).catch(err =>{
        console.log(err)
        setLoading(false);
        setError(true);
      })
    }

    const initialValuesLogin = {
      user_email:'',
      user_pass:'',
      unique_id:''
    };
    const validationSchemaLogin = Yup.object().shape({
        user_email: Yup.string().required("User name is required"),
        user_pass: Yup.string().required()
    })

    const userLogin = (data:any) => {
      // console.log(data);
      http.post("/users/login", data).then(response => {
        setLoading(true);
        if(response.data.error == 'error'){
          setError1(true);
          setLoading(false);
        }else if(response.data.id){
          setCookie('user_email', response.data.user_email, { expires: 90})
          toast.success('Login Successfully !',{position:toast.POSITION.TOP_RIGHT});
          setTimeout(() => {
            // navigate('/live_shop');
            window.location.reload();
          }, 2000); 
          setLoading(false);
        }else{
          setError1(true);
          setLoading(false);
        }
      }).catch(error => {
        setError1(true);
        console.log(error);
        setLoading(false);
      })
    }
    return(
        <>
          {/* wrapper*/}
            {/* scroll-nav-wrap*/}
            <div className="scroll-nav-wrap" style={{top:'none'}}>
              <div className="scroll-down-wrap">
                <div className="mousey">
                  <div className="scroller" />
                </div>
                <span>Scroll Down</span>
              </div>
              <nav className="scroll-nav scroll-init">
                <ul>
                  <li><Link className="scroll-link act-link" to="./">HOME</Link></li>
                  <li><a className="scroll-link" href="#about">ABOUT</a></li>
                  <li><a className="scroll-link" href="#services">SERVICES</a></li>
                  <li><a className="scroll-link" href="#team">TEAM</a></li>
                  <li><a className="scroll-link" href="#joinus">JOINUS</a></li>
                  <li><a className="scroll-link" href="#faq">FAQ</a></li>
                  <a href="#" className="btn float-btn flat-btn color-btn" id="myBtnone" style={{padding: '5px!important'}}>Register</a>
                </ul>
              </nav>
            </div>
            {/* scroll-nav-wrap end*/}
            {/* hero-wrap*/}
            <div className="hero-wrap" id="#" data-scrollax-parent="true">
              {/* hero-inner*/}
              {/* fullscreen-slider-wrap*/}
              <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
                <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick="{&quot;autoplay&quot;: true, &quot;autoplaySpeed&quot;: 4000 , &quot;pauseOnHover&quot;: false}">
                  {/* fullscreen-slider-item*/}
                  <div className="fullscreen-slider-item full-height fl-wrap">
                    <div className="bg par-elem" data-bg="/assets/images/01_VFX.jpg" />
                    <div className="overlay" />
                    <div className="half-hero-wrap">
                      <h1>Providing a world to you!<br />Where adventure is never scripted
                        <br /> <span> Film Playground </span>                        
                      </h1>
                      <h4>We make it happen</h4>
                      <div className="clearfix" />
                      <a href="/live_shop" className="btn float-btn flat-btn color-btn mar-top">Let's Start</a>
                    </div>
                  </div>
                  {/* fullscreen-slider-item end*/}
                  {/* fullscreen-slider-item*/}
                  <div className="fullscreen-slider-item full-height fl-wrap">
                    <div className="bg par-elem" data-bg="/assets/images/02_VFX.jpg" />
                    <div className="overlay" />
                    <div className="half-hero-wrap">
                      <h1>Giving life to your
                        <br /> <span> Occasion </span>
                      </h1>
                      <h4>We make it happen</h4>
                      <div className="clearfix" />
                      <a href="/live_shop" className="btn float-btn flat-btn color-btn mar-top">Let's Start</a>
                    </div>
                  </div>
                  {/* fullscreen-slider-item end*/}
                  {/* fullscreen-slider-item*/}
                  <div className="fullscreen-slider-item full-height fl-wrap">
                    <div className="bg par-elem" data-bg="/assets/images/03_VFX.jpg" />
                    <div className="overlay" />
                    <div className="half-hero-wrap">
                      <h1>Weddings, Birthdays, <br />Corporate Events...
                        <br /> <span> Many More </span>
                      </h1>
                      <h4>We make it happen</h4>
                      <div className="clearfix" />
                      <a href="/live_shop" className="btn float-btn flat-btn color-btn mar-top">Let's Start</a>
                    </div>
                  </div>
                  {/* fullscreen-slider-item end*/}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left" /></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right" /></div>
                <div className="fullscreenslider-counter" />
              </div>
              {/* fullscreen-slider-wrap end*/}
              {/*hero dec*/}
              {/*            <div class="hero-decor-numb"><span>40.7143528  </span><span>-74.0059731 </span> <a href="https://www.google.com.ua/maps/" target="_blank" class="hero-decor-numb-tooltip">Based In NewYork</a></div>*/}

            </div>
            {/* hero-wrap end*/}
            {/* Content*/}
            <div className="content">

              {/* About section*/}
              <section data-scrollax-parent="true" id="about">
                <div className="container">
                  {/* blog-container  */}
                  <div className="fl-wrap post-container">
                    <div className="row">
                      <div className="col-md-1">
                        {/* post */}
                        <div className="post fl-wrap fw-post">
                          <h2><a href="#"><span>About</span></a></h2>
                          {/* blog media */}
                          {/* <div class="blog-media fl-wrap nomar-bottom">
                                            <div class="single-slider-wrap slider-carousel-wrap ">
                                                <div class="single-slider cur_carousel-slider-container fl-wrap">
                                                    <div class="slick-slide-item"><img
                                                            src="<?php echo base_url('assets/images/about.jpg');?>" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                          {/* blog media end */}
                        </div>
                        {/* post end*/}
                        {/*comments end */}
                      </div>
                      {/* blog-sidebar  */}
                      <div className="col-md-11">
                        <div className="blog-text fl-wrap">
                          <div className="clearfix" />
                          {/* <p style="text-align:left; font-size:15px;">What we deliver what we promise</p> */}
                          <h3 style={{fontSize: '25px'}}>Innovative and Professional
                          </h3>
                          <h3 style={{fontSize: '25px', color: 'black'}}>Design Creations Classy &amp; Stylish <span style={{color: '#0424b3'}}>Brand</span>
                            Perception
                          </h3>
                          <blockquote>
                            <p style={{textAlign: 'left'}}> The innocense of every single moment captured in
                              the
                              lens will have
                              a life
                              that
                              is treasured for ever and ever. Digital designs to your best memory. </p>
                          </blockquote>
                          <div className="col-md-6">
                          <ul>
                              <li className="clearfix" style={{marginTop: '10px'}}>
                              <div className='row'>
                                  <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                    <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/motg.jpg" className=" respimg" alt="" /></a>
                                  </div>
                                  <div className='col-md-8' style={{padding:"0px"}}>
                                  <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                    <a href="#" style={{fontSize: '13px'}}>MOTION GRAPHICS</a>
                                    <span className=" widget-posts-date">We offer title fx, screen fx work
                                      that
                                      require motion graphics work.Our in house talented team will
                                      deliver all different motion graphics elements as required for
                                      your
                                      service requirements Please reach out to us for further
                                      details and work requirements.</span>
                                  </div>
                                  </div>
                                </div>
                              </li>
                              <li className="clearfix" style={{marginTop: '20px'}}>
                              <div className='row'>
                                  <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                    <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/vfxx.jpg" className=" respimg" alt="" /></a>
                                  </div>
                                  <div className='col-md-8' style={{padding:"0px"}}>
                                  <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                    <a href="#" style={{fontSize: '13px'}}>FX/3D VISUALISATION</a>
                                    <span className=" widget-posts-date">We offer title fx, screen fx work
                                      that
                                      require motion graphics work.Our in house talented team will
                                      deliver all different motion graphics elements as required for
                                      your
                                      service requirements Please reach out to us for further
                                      details and work requirements.</span>
                                  </div>
                                  </div>
                                </div>
                              </li>
                              <li className="clearfix" style={{marginTop: '20px'}}>
                                <div className='row'>
                                    <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                      <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/ba.jpg" className=" respimg" alt="" /></a>
                                    </div>
                                    <div className='col-md-8' style={{padding:"0px"}}>
                                    <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                      <a href="#" style={{fontSize: '13px'}}>BROAD CAST ANIMATIONS</a>
                                      <span className=" widget-posts-date">We offer title fx, screen fx work
                                        that
                                        require motion graphics work.Our in house talented team will
                                        deliver all different motion graphics elements as required for
                                        your
                                        service requirements Please reach out to us for further
                                        details and work requirements.</span>
                                    </div>
                                    </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                          <ul>
                              <li className="clearfix" style={{marginTop: '10px'}}>
                                <div className='row'>
                                    <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                      <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/FM.jpg" className=" respimg" alt="" /></a>
                                    </div>
                                    <div className='col-md-8' style={{padding:"0px"}}>
                                    <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                      <a href="#" style={{fontSize: '13px'}}>FUTURE FILMS - VFX</a>
                                      <span className=" widget-posts-date">We offer title fx, screen fx work
                                        that
                                        require motion graphics work.Our in house talented team will
                                        deliver all different motion graphics elements as required for
                                        your
                                        service requirements Please reach out to us for further
                                        details and work requirements.</span>
                                    </div>
                                    </div>
                                  </div>
                              </li>
                              <li className="clearfix" style={{marginTop: '20px'}}>
                                <div className='row'>
                                    <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                      <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/dii.jpg" className=" respimg" alt="" /></a>
                                    </div>
                                    <div className='col-md-8' style={{padding:"0px"}}>
                                    <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                      <a href="#" style={{fontSize: '13px'}}>DIGITAL INTERMEDITE - DI</a>
                                      <span className=" widget-posts-date">We offer title fx, screen fx work
                                        that
                                        require motion graphics work.Our in house talented team will
                                        deliver all different motion graphics elements as required for
                                        your
                                        service requirements Please reach out to us for further
                                        details and work requirements.</span>
                                    </div>
                                    </div>
                                  </div>
                              </li>
                              <li className="clearfix" style={{marginTop: '20px'}}>
                                <div className='row'>
                                    <div className='col-md-4' style={{margin:"20px 0px",padding:"0px"}}>
                                      <a style={{width:'100%'}} href="#" className="widget-posts-img"><img src="/assets/images/db.jpg" className=" respimg" alt="" /></a>
                                    </div>
                                    <div className='col-md-8' style={{padding:"0px"}}>
                                    <div className="widget-posts-descr" style={{width:'100%',margin:"20px 0px"}}>
                                      <a href="#" style={{fontSize: '13px'}}>DIGITAL BRANDING</a>
                                      <span className=" widget-posts-date">We offer title fx, screen fx work
                                        that
                                        require motion graphics work.Our in house talented team will
                                        deliver all different motion graphics elements as required for
                                        your
                                        service requirements Please reach out to us for further
                                        details and work requirements.</span>
                                    </div>
                                    </div>
                                  </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* blog-sidebar end */}
                      <div className="limit-box fl-wrap" />
                    </div>
                  </div>
                  {/* blog-container end    */}
                </div>
                <div className="bg-parallax-module" data-position-top={50} data-position-left={20} data-scrollax="properties: { translateY: '-250px' }" />
                <div className="bg-parallax-module" data-position-top={40} data-position-left={70} data-scrollax="properties: { translateY: '150px' }" />
                <div className="bg-parallax-module" data-position-top={80} data-position-left={80} data-scrollax="properties: { translateY: '350px' }" />
                <div className="bg-parallax-module" data-position-top={95} data-position-left={40} data-scrollax="properties: { translateY: '-550px' }" />
                <div className="sec-lines" />
              </section>
              {/* About section end*/}
              
              {/*services section*/}
              <section style={{display:'none'}} className="dark-bg no-padding" id="services">
                <div className="section-subtitle right-pos" data-scrollax="properties: { translateY: '-250px' }" style={{fontSize: '145px'}}>
                  <span>//</span>Services
                </div>
                <div className=" section-title fl-wrap">
                  <h2 style={{color: '#fff', visibility: 'hidden'}}>Scale Of Services</h2>
                </div>
                {/* <div className="hidden-info-wrap-bg" style={{top: '0px!important'}}>
                  <div className="bg-ser">
                  </div>
                  <div className="overlay" />
                </div> */}
                {/*   hidden-info-wrap */}
                <div className="hidden-info-wrap" style={{marginTop: '-10%!important'}}>
                  <div className="hidden-info fl-wrap">
                    <div className="hidden-info-title"><span style={{fontSize: '40px!important'}}>Scale Of Services</span>
                    </div>
                    <div className="hidden-works-list fl-wrap">
                      {/*   hidden-works-item */}
                      
                      {getCategory?.map ? getCategory.map((category:any, i) => (
                            <>
                              {/* widget-wrap */}
                              {/* widget-wrap end  */}
                              <div className="hidden-works-item fl-wrap" data-bgscr="/assets/images/services/wi.jpg"   key={i}>
                                <div className="hidden-works-item-media">
                                  <div className="hidden-works-item-media-img fl-wrap">
                                    <img src="/assets/images/services/wi.jpg" alt="" />
                                  </div>
                                  <span>
                                   
                                      {i+1}
                                  
                                  </span>
                                </div>
                                <div className="hidden-works-item-text">
                                  {/* <h3><Link to="live_shop/">{category.category_name}</Link></h3> */}
                                  <h3> <Link to="live_shop/">{category.category_name}</Link></h3>
                                  <ul>
                                    {
                                      category.subCategories && category.subCategories.map((subCategory: {id:any, category_image:any, category_name:any, category_description:any, status:any}) => (
                                      <li><Link to="live_shop/">{subCategory.category_name}</Link></li>
                                                                             
                                    ))
                                    }
                                    {/* <li><a href="#">Engagement Invitation</a>
                                    </li><li><a href="#">Wedding Invitation</a>
                                    </li><li><a href="#">Anniversary Invitation</a>
                                    </li><li><a href="#">Party Invitation</a>
                                    </li> */}
                                  </ul>
                                </div>
                            </div>
                            </>
                      )): "Loading.."}
                  
                     
                      {/*   hidden-works-item end */}
                    </div>
                  </div>
                </div>
                {/* hidden-info-wrap end */}
              </section>
              {/* services section end*/}

              <section className="dark-bg" id="team">
                <div className="section-subtitle right-pos" data-scrollax="properties: { translateY: '-250px' }" style={{fontSize: '145px'}}>
                  <span>//</span>Services
                </div>
                <div className="container">
                  <div className="section-title fl-wrap">
                    <h2>Services</h2>
                    <h3 style={{fontSize: '22px'}}>Scale Of Services</h3>
                  </div>
                  <div className='row'>
                    <Slider {...Product4} className="product-4 no-arrow">
                      {getCategories?.map ? getCategories.map((categories:any, i) => 
                        <div key={i} className="col-md-3" style={{marginBottom:"20px"}}>
                          {/* <div className="card">
                            <div className="card-body">
                              <div style={{}}>
                                <Link to={'/live_shop'}>
                                  {categories.category_image == '' && <img src="/assets2/images2/topcollection8.jpg" className="img-fluid blur-up lazyload bg-img" alt="" />}
                                  {categories.category_image != '' && <img src={baseUrl2+'/'+categories.category_image} className="img-fluid blur-up lazyload bg-img" alt="" />}                          
                                </Link>
                              </div>
                            </div><br />
                            <div className="card-footer">
                              <Link to={'/live_shop'}>
                                <h6><b>{categories.category_name}</b></h6>
                              </Link>
                            </div>
                          </div> */}
                          <div className="widget-wrap fl-wrap" style={{padding:'0px', margin:'0px'}}>                               
                            <div className="widget-container fl-wrap">
                              <div className="about-widget fl-wrap">
                                {categories.category_image != '' && <img src={baseUrl2+'/'+categories.category_image}  className="respimg" alt="" />}
                                {categories.category_image == '' && <img src="/assets/images/01_VFX.jpg"  className="respimg" alt="" />}
                                {/* <img src="assets/images/avatar/7.jpg" alt="" /> */}
                                <h5 style={{paddingLeft:'40px', textAlign:"center"}}><a href="/live_shop">{categories.category_name}</a></h5>
                                <div className="clearfix" />
                                <p style={{color:'#4d0f85', paddingLeft:'25px', textAlign:"center"}}>{categories.category_description}</p>                              
                              </div>
                            </div>
                          </div>
                          {/* <div className="fet_pr-carousel-box-media fl-wrap">                       
                              <img src={baseUrl2+'/'+categories.category_image}  className="respimg" alt="" />
                              <a href={baseUrl2+'/'+categories.category_image} className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                            </div>
                            <div style={{backgroundColor:"#fff"}}>
                              <Link to={'/live_shop'}>
                                <h6 style={{padding:'20px'}}><b style={{padding:'40px'}}>{categories.category_name}</b></h6>
                              </Link>
                            </div>*/}
                        </div> 
                      ) : ''}
                    </Slider>
                  </div>
                </div>
              </section>

              {/* Team section */}
              <section className="dark-bg" id="team" style={{backgroundColor: 'white'}}>
                <div className="section-subtitle right-pos" data-scrollax="properties: { translateY: '-250px' }" style={{fontSize: '145px'}}>
                  <span>//</span>CREATIVE TEAM
                </div>
                <div className="container-fluid">
                  <div className="section-title fl-wrap">
                    <h2 style={{color: '#000'}}>FREELANCER TEAM</h2>
                    <h3 style={{fontSize: '22px'}}>Odio posuere Uetus quisque faucibus lectus arcu
                      doUec. Eget dictum eu viverra faucibus. Viverra
                      scelerisque coUsequat.</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="center">
                        <a href="#" className="btn float-btn flat-btn color-btn" id="myBtnthree">
                          <div className=" time-line-icon">
                            <i className="fal fa-user" style={{color: 'white'}} />
                          </div><br />REGISTER NOW
                        </a>
                      </div><br />
                      <div className="inline-facts-wrap" style={{marginRight: '0px', textAlign:'center', float:'none'}}>
                        <div className=" inline-facts">
                          <div className="milestone-counter">
                            <div className="stats animaper">
                              <div className="num" data-content={0} data-num={240} style={{color: '#6E179F', fontSize: '75px'}}>
                                0</div>
                            </div>
                          </div>
                          <h3 style={{color: 'black', fontSize: '28px'}}><b>Meet our team</b>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      {/*features-box */}
                      <div className="features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-thumbs-up" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>OUR STORY</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>We are a established studio iU Hyderabad, IUdia
                          with
                          a peUchaUt to deliver by pushiUg the bouUdaries of
                          clieUt s expectatioUs by briUgiUg to life immersive </p>
                      </div>
                      {/* features-box end  */}
                      {/*features-box */}
                      <div className="features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-eye" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>OUR MISSION</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>We always promote growth, learUiUg aUd
                          coUstaUt improvisatioU aUd abstaiU from
                          separatiUg a Uewcomer from aU experieUced
                          persoU. By doiUg so, we all are a part.</p>
                      </div>
                      {/* features-box end  */}
                      {/*features-box */}
                      <div className=" features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-user" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>OUR VALUES</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>The work enviroUmeUt that we have created
                          emphasizes oU
                          learUiUg aUd
                          creative growth. We stroUgly believe that maUtaiUiUg a stroUg creative culture
                          aUd maUtaiUiUg a out of the box iU a famly workiUg eUviroUmeUt will help our
                          people create magic that always is extraordiUary.</p>
                      </div>
                      {/* features-box end  */}
                      {/*features-box */}
                      <div className="features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-thumbs-up" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>RESPECT</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>Odio posuere Uetus quisque faucibus lectus arcu
                          doUec. Eget dictum eu viverra faucibus. Viverra
                          scelerisque coUsequat.</p>
                      </div>
                      {/* features-box end  */}
                      {/*features-box */}
                      <div className="features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-clipboard-list-check" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>COMMITMENT</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>Odio posuere Uetus quisque faucibus lectus arcu
                          doUec. Eget dictum eu viverra faucibus. Viverra
                          scelerisque coUsequat.</p>
                      </div>
                      {/* features-box end  */}
                      {/*features-box */}
                      <div className="features-box col-md-4">
                        <div className="time-line-icon">
                          <i className="fal fa-eye" style={{color: 'blue'}} />
                        </div>
                        <h3 style={{color: '#031685', fontWeight: 800}}>TRANSPARENCY</h3>
                        <p style={{color: '#595858', fontSize: '13px'}}>Odio posuere Uetus quisque faucibus lectus arcu
                          doUec. Eget dictum eu viverra faucibus. Viverra
                          scelerisque coUsequat.</p>
                      </div>
                      {/* features-box end  */}
                    </div>
                  </div><br /><br /><br />
                  {/*slider-carousel-wrap */}
                  <div className="slider-carousel-wrap fl-wrap">
                    <div className="fet_pr-carousel-title">
                      <div className="fet_pr-carousel-title-item">
                        <h3 style={{color: 'black', marginBottom: 'none!important'}}>Creative Team</h3>
                        {/* <a href="#" className="btn float-btn flat-btn color-btn " id="myBtnthree">Team
                          Register</a> */}
                      </div>
                    </div>
                    {/*fet_pr-carousel */}
                    <div className="fet_pr-carousel cur_carousel-slider-container fl-wrap">
                      {/*slick-item */}
                      <div className="slick-item" style={{width: "250px"}}>
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: "#000"}}>Silvia Perry</h3>
                            <div className="fet_pr-carousel-cat"><a href="#" style={{color: "#000"}}>Designer</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                      {/*slick-item */}
                      <div className="slick-item">
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: "#000"}}>Smith Joe</h3>
                            <div  className="fet_pr-carousel-cat"> <a href="#" style={{color: "#000"}}>
                                Multimedia Designer</a></div>
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                      {/*slick-item */}
                      <div className="slick-item">
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: "#000"}}>Neo Jackson</h3>
                            <div  className="fet_pr-carousel-cat"><a href="#" style={{color: "#000"}}>Art
                                Director</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                      {/*slick-item */}
                      <div className="slick-item">
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: "#000"}}>Steve Parkar</h3>
                            <div  className="fet_pr-carousel-cat"><a href="#" style={{color: "#000"}}>Advertising
                                Designer</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                      {/*slick-item */}
                      <div className="slick-item">
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: '#000'}}>Jone</h3>
                            <div  className="fet_pr-carousel-cat"><a href="#" style={{color: '#000'}}>Logo
                                Designer</a></div>
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                      {/*slick-item */}
                      <div className="slick-item">
                        <div className="fet_pr-carousel-box">
                          <div className="fet_pr-carousel-box-media fl-wrap">
                            <img src="/assets2/images2/blank.png" className="respimg" alt="" />
                            <a href="/assets2/images2/blank.png" className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search" /></a>
                          </div>
                          <div className="fet_pr-carousel-box-text fl-wrap">
                            <h3 style={{color: "#000"}}>David Schwimmer</h3>
                            <div className="fet_pr-carousel-cat"><a href="#"  style={{color: "#000"}}>Graphic
                                Designer</a></div>                                
                          </div>
                        </div>
                      </div>
                      {/*slick-item end */}
                    </div>
                    {/*fet_pr-carousel end */}
                    <div className="sp-cont sp-arr sp-cont-prev"><i className="fal fa-long-arrow-left" /></div>
                    <div className="sp-cont sp-arr sp-cont-next"><i className="fal fa-long-arrow-right" /></div>
                  </div>
                  {/* {/slider-carousel-wrap end/} */}
                  <a href="#" className="btn float-btn flat-btn color-btn" id="myBtntwo">
                          <div className=" time-line-icon">
                            <i className="fal fa-user" style={{color: 'white'}} />
                          </div><br />Join Us
                        </a>
                  <div className="fet_pr-carousel-counter" />
                </div>
              </section>
              {/* Team section end */}

              {/* Joinus section*/}
              <section id="joinus" style={{backgroundColor: 'black'}}>
                <div className="section-subtitle right-pos" data-scrollax="properties: { translateY: '-250px' }" style={{fontSize: '145px'}}>
                  <span>//</span>RESELLER BUSINESS
                </div>
                <div className="container">
                  <div className="section-title fl-wrap">
                    {/* <h2 style="color:#fff;">RESELLER ACCOUNT</h2> */}
                  </div>
                  <div className=" row">
                    <div className="col-md-5">
                      <a href="#" className="btn float-btn flat-btn color-btn" id="myBtntwo">
                        <div className="time-line-icon">
                          <i className="fal fa-user" style={{color: 'white'}} />
                        </div><br />Join Us
                      </a>
                      <div className="inline-facts-wrap" style={{marginRight: '0px', float:'none', textAlign:'center'}}>
                        <div className=" inline-facts">
                          <div className="milestone-counter">
                            <div className="stats animaper">
                              <div className="num" data-content={0} data-num={240} style={{color: '#6E179F'}}>
                                0</div>
                            </div>
                          </div>
                          <h3 style={{color: 'white', fontSize: '15px'}}><b>Resellers Marketing
                              Bussiness</b>
                          </h3>
                        </div>
                      </div>
                      <br /> <br /> <br />
                      <h3 style={{color: 'white', textAlign: 'left', fontSize: '16px'}}>Empower Your Business With Your</h3>
                      <h2 style={{color: '#6E179F', textAlign: 'left', fontSize: '30px'}}><b>Reseller Account</b></h2>
                      <p style={{textAlign: 'left'}}>As a reseller, you can start your business road right away
                        without
                        waiting for
                        any
                        products to arrive. As soon as you add reselling products and content on your
                        site,
                        you can begin marketing your business. Moreover, it doesn’t ask for much time
                        commitment since most of the processes are automated and you won’t need to
                        package
                        or ship products to the customers. Stampwallet offers a full package of platform
                        and
                        services needed for setting your own business. You won’t have to go through
                        complicated messy software since our app focuses on your local business. As a
                        reseller of Stampwallet, you will get support for whole activities including
                        research, development, and marketing and have access to the multi-language
                        platform.
                      </p>
                    </div>
                    <div className=" col-md-7">
                      <div className="main-about fl-wrap" style={{width: '125%'}}>
                        <ul>
                          <li className="clearfix" style={{marginTop: '60px'}}>
                            <a href="#" className="widget-posts-img"><img src="/assets/images/ra.jpg" className=" respimg" alt="" /></a>
                            <div className="widget-posts-descr">
                              <a href="#" style={{fontSize: '16px', color: '#6E179F'}}>RESELLER ACCOUNT</a>
                              <span className="widget-posts-date">We offer title fx, screen fx work
                                that
                                require motion graphics work.Our in house talented team will
                                deliver all different motion graphics elements as required for
                                your
                                service requirements Please reach out to us for further
                                details and work requirements.</span>
                            </div>
                          </li>
                          <li className="clearfix" style={{marginTop: '20px'}}>
                            <a href="#" className="widget-posts-img"><img src="/assets/images/senn.jpg" className=" respimg" alt="" /></a>
                            <div className="widget-posts-descr">
                              <a href="#" style={{fontSize: '16px', color: '#6E179F'}}>SHARE &amp; EARN
                              </a>
                              <span className="widget-posts-date">We offer title fx, screen fx
                                work
                                that
                                require motion graphics work.Our in house talented team will
                                deliver
                                all different motion graphics elements as required for your
                                service
                                requirements.Please reach out to us for further details and
                                work
                                requirements.
                              </span>
                            </div>
                          </li>
                          <li className="clearfix" style={{marginTop: '20px'}}>
                            <a href="#" className="widget-posts-img"><img src="/assets/images/rw.png" className="respimg" alt="" />
                            </a>
                            <div className="widget-posts-descr">
                              <a href="#" style={{fontSize: '16px', color: '#6E179F'}}>REWARDS</a>
                              <span className="widget-posts-date">We offer title fx, screen fx work
                                that
                                require motion graphics work.Our in house talented team will
                                deliver
                                all different motion graphics elements as required for your
                                service
                                requirements.Please reach out to us for further details and work
                                requirements. </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-parallax-module" data-position-top={90} data-position-left={25} data-scrollax="properties: { translateY: '-250px' }" />
                <div className="bg-parallax-module" data-position-top={70} data-position-left={70} data-scrollax="properties: { translateY: '150px' }" />
                <div className="sec-lines" />
              </section>
              {/* Joinus section end*/}

              {/* FAQ Section */}
              <section data-scrollax-parent="true" id="faq">
                <div className="section-subtitle right-pos" data-scrollax="properties: { translateY: '-250px' }" style={{fontSize: '145px'}}>
                  <span>//</span>FAQ
                </div>
                <div className="container-fluid">
                  <div className="section-title fl-wrap">
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fixed-column l-wrap">
                        <div className="pr-title fl-wrap">
                          <h3 style={{color: 'white'}}>Frequently Asked Questions</h3>
                          <span>Lorem Ipsum generators on the Internet king this the first true
                            generator</span>
                        </div>
                        <div className=" ci-num"><span>01.</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4" style={{zIndex: 99999}}>
                      <h2 style={{fontWeight: 800, fontSize: '30px', color: 'white'}}>Payment Details</h2>
                      {/* accordion*/}
                      <div className="accordion mar-top">
                        <a className="toggle act-accordion" href="#"> Pixel pushing close the loop
                          <span /></a>
                        <div className="accordion-inner ">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                            Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className=" toggle" href="#"> Parallel path optimize the fireball
                          <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className="toggle" href="#"> Details option 3 <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                      </div>
                      {/* accordion end */}
                    </div>
                    <div className="col-md-4" style={{zIndex: 99999}}>
                      <h2 style={{fontWeight: 800, fontSize: '30px', color: 'white'}}>Refunds</h2>
                      {/* accordion*/}
                      <div className="accordion mar-top">
                        <a className="toggle act-accordion" href="#"> Pixel pushing close the loop
                          <span /></a>
                        <div className="accordion-inner ">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                            Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className=" toggle" href="#"> Parallel path optimize the fireball
                          <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className="toggle" href="#"> Details option 3 <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                      </div>
                      {/* accordion end */}
                    </div>
                    <div className="col-md-4" style={{zIndex: 99999}}>
                      <h2 style={{fontWeight: 800, fontSize: '30px', color: 'white'}}>Returns</h2>
                      {/* accordion*/}
                      <div className="accordion mar-top">
                        <a className="toggle act-accordion" href="#"> Pixel pushing close the loop
                          <span /></a>
                        <div className="accordion-inner ">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                            Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className=" toggle" href="#"> Parallel path optimize the fireball
                          <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                        <a className="toggle" href="#"> Details option 3 <span /></a>
                        <div className="accordion-inner">
                          <p style={{color: '#545454'}}>Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in
                            pulvinar
                            neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                            Aliquam
                            erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed
                            tempor
                            iaculis massa faucibus feugiat. In fermentum facilisis massa, a
                            consequat
                            purus viverra.</p>
                        </div>
                      </div>
                      {/* accordion end */}
                    </div>
                  </div>
                  <div className="limit-box fl-wrap" />
                </div>
                {/* det box end*/}
                <div className="bg-parallax-module" data-position-top={50} data-position-left={20} data-scrollax="properties: { translateY: '-250px' }" />
                <div className="bg-parallax-module" data-position-top={40} data-position-left={70} data-scrollax="properties: { translateY: '150px' }" />
                <div className="bg-parallax-module" data-position-top={80} data-position-left={80} data-scrollax="properties: { translateY: '350px' }" />
                <div className="bg-parallax-module" data-position-top={95} data-position-left={40} data-scrollax="properties: { translateY: '-550px' }" />
                <div className="sec-lines" />
              </section>

              {/* The Modal One */}
              <div id="myModalone" className="modal">
                {/* Modal content */}
                <div className="modal-content">
                  <span className="close">×</span>
                  <h2 style={{fontSize: '25px'}}><b>Register</b></h2>
                  <p>Welcome to Arkwrx</p>
                  <Formik initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validationSchema}
                          setFieldValue>
                    <Form className="custom-form" style={{float: 'none'}}>
                      <label><i className="fal fa-user" /></label>
                      <Field  type="text" name="user_name" id="user_name" placeholder="Full Name *" />
                      <ErrorMessage name='user_name' component="div" className='text-danger'/>
                      <label><i className="fal fa-mobile-android" /> </label>
                      <Field  type="text" name="phone" id="phone" placeholder="Phone Number" />
                      <ErrorMessage name='phone' component="div" className='text-danger'/>
                      <label><i className="fal fa-envelope" /> </label>
                      <Field  type="email" name="user_email" id="user_email" placeholder="Email *" />
                      <ErrorMessage name='user_email' component="div" className='text-danger'/>
                      <label><i className="fas fa-lock"></i></label>
                      <Field  type="password" name="user_pass" id="user_pass" placeholder="Password *" />
                      <ErrorMessage name="user_pass" component="div" className="text-danger" />
                      <label><i className="fal fa-user" /> </label>
                      {/* <Field as="select" style={{marginBottom:"20px"}} name="agent_type" id="agent_type" data-placeholder="service">
                        <option value="Others">Type Of Agent</option>
                        <option>Hospital Services</option>
                        <option>Resturant Services </option>
                        <option>Retail Shopping Services</option>
                        <option>Corporate Services</option>
                        <option>Others</option>
                      </Field>
                      <ErrorMessage name='agent_type' component="div" className='text-danger'/> */}
                      <label><i className="fal fa-address-card" /> </label>
                      <Field  type="text" name="address" id="address" placeholder="Address *" />
                      <ErrorMessage name='address' component='div' className='text-danger'/>
                      <label><i className="fal fa-address-card" /> </label>
                      <Field  type="text" name="city" id="city" placeholder="City *"/>
                      <ErrorMessage name="city" component="div" className="text-danger"/>
                      <label><i className="fal fa-address-card" /> </label>
                      <Field  type="text" name="state" id="state" placeholder="State *"/>
                      <ErrorMessage name="state" component="div" className="text-danger"/>
                      <label><i className="fal fa-address-card" /> </label>
                      <Field  type="text" name="country" id="country" placeholder="country *" />
                      <ErrorMessage name='country' component="div" className="text-danger"/>
                      <label><i className="fal fa-address-card" /> </label>
                      <Field  type="text" name="pincode" id="pincode" placeholder="Pincode *" />
                      <ErrorMessage name='pincode' component="div" className="text-danger"/>
                      <Field type="hidden" value="User" name="user_type" />
                      <div className="clearfix">                        
                        {error && <p className='text-center text-danger'>Something Went Wrong</p> }
                        {sucess && <p className='text-center text-success'>Category Added Successfully</p> }
                      </div>
                      {!loading && 
                          <button type='submit' className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                                <span className="indicator-label">Submit</span>
                          </button>
                      }
                      {loading && (
                          <button type='submit' disabled className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                      )}
                    </Form>
                  </Formik>
                </div>
              </div>
              {/* The Modal One ends */}
              {/* The Modal Two */}
              <div id="myModaltwo" className="modal">
                {/* Modal content */}
                <div className="modal-content">
                  <span className="close1">×</span>
                  <h2 style={{fontSize: '25px'}}><b>JOIN US</b></h2>
                  <h2 style={{fontSize: '20px'}}><b>FREELANCER TEAM Register</b></h2>
                  <p>Welcome to ARKWRX</p>
                <Formik initialValues={initialValues2}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema2}>
                  <Form className="custom-form" action="#" name="contactform" style={{float: 'none'}}>
                    <label><i className="fal fa-user" /></label>
                    <Field  type="text" name="user_name" id="user_name" placeholder="Full Name *" />
                    <ErrorMessage name="user_name" component="div" className="text-danger"/>
                    <label><i className="fal fa-mobile-android" /> </label>
                    <Field  type="text" name="phone" id="phone" placeholder="Phone No *" />
                    <ErrorMessage name="phone" component="div" className="text-danger"/>
                    <label><i className="fal fa-envelope" /> </label>
                    <Field  type="email" name="user_email" id="user_email" placeholder="Email *" />
                    <ErrorMessage name="user_email" component="div" className="text-danger"/>
                    <label><i className="fas fa-lock"></i></label>
                    <Field  type="password" name="user_pass" id="user_pass" placeholder="Password *" />
                    <ErrorMessage name="user_pass" component="div" className="text-danger" />
                    <label><i className="fal fa-user" /></label>
                    {/* <Field as="select" style={{marginBottom:"20px"}} name="agent_type" id="agent_type" data-placeholder="agent" >
                      <option value="">Type Of Agent</option>
                      <option>Marketing Agents</option>
                      <option>Wedding Cards Agent </option>
                      <option>Graphic Designer Agent</option>
                      <option>Marriages Agent</option>
                      <option>Others</option>
                    </Field>
                    <ErrorMessage name="agent_type" component="div" className="text-danger"/> */}
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="address" id="address" placeholder="Address *" />
                    <ErrorMessage name="address" component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="city" id="city" placeholder="City *"/>
                    <ErrorMessage name="city" component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="state" id="state" placeholder="State *"/>
                    <ErrorMessage name='state' component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field type="text" name="country" id="country" placeholder="country *" />
                    <ErrorMessage name='country' component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="pincode" id="pincode" placeholder="Pincode *" />
                    <ErrorMessage name='pincode' component="div" className="text-danger"/>
                    <div className="clearfix">
                        {error && <p className='text-center text-danger'>Something Went Wrong</p> }
                        {sucess && <p className='text-center text-success'>Category Added Successfully</p> }
                    </div>
                    {!loading && 
                          <button type='submit' className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                                <span className="indicator-label">Submit</span>
                          </button>
                      }
                      {loading && (
                          <button type='submit' disabled className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                      )}
                  </Form>
                  </Formik>
                </div>
              </div>
              {/* The Modal Two ends */}
              {/* The Modal Three */}
              <div id="myModalthree" className="modal">
                {/* Modal content */}
                <div className="modal-content">
                  <span className="close2">×</span>
                  <h2 style={{fontSize: '25px'}}><b>TEAM US</b></h2>
                  <h2 style={{fontSize: '20px'}}><b>Creative Team Register</b></h2>
                  <p>Welcome to Team Registration takes less than a
                    minute but gives you full control over your orders.</p>
                    <Formik initialValues={initialValues3}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema3}>
                  <Form className="custom-form" action="#" name="contactform" style={{float: 'none'}}>
                    <label><i className="fal fa-user" /></label>
                    <Field  type="text" name="user_name" id="user_name" placeholder="Full Name *" />
                    <ErrorMessage name="user_name" component="div" className="text-danger" />
                    <label><i className="fal fa-mobile-android" /> </label>
                    <Field  type="text" name="phone" id="phone" placeholder="Phone No *" />
                    <ErrorMessage name="phone" component="div" className="text-danger" />
                    <label><i className="fal fa-envelope" /> </label>
                    <Field  type="email" name="user_email" id="user_email" placeholder="Email *" />
                    <ErrorMessage name="user_email" component="div" className="text-danger" />
                    <label><i className="fas fa-lock"></i></label>
                    <Field  type="password" name="user_pass" id="user_pass" placeholder="Password *" />
                    <ErrorMessage name="user_pass" component="div" className="text-danger" />
                    <label><i className="fal fa-user" /></label>
                    <Field as="select" style={{marginBottom:"20px"}} name="agent_type" id="agent_type" data-placeholder="agent">
                      <option value="">Type Of Agent</option>
                      <option>Creative Studio</option>
                      <option>Graphics Designer </option>
                      <option>Editor</option>
                      <option>Cameraman</option>
                    </Field>
                    <ErrorMessage name="agent_type" component="div" className="text-danger" />
                    <label><i className="fal fa-user" /></label>
                    <Field as="select" style={{marginBottom:"20px"}} name="software_type" id="software_type" data-placeholder="software">
                      <option value="">Select Knowledges Of Software</option>
                      <option>Adobe Photoshop</option>
                      <option>Adobe illustrator</option>
                      <option>Adobe after Effects</option>
                      <option>Apple FCPX</option>
                      <option>Blackmagic Fusion</option>
                      <option>Nuke</option>
                    </Field>
                    <ErrorMessage name="software_type" component="div" className="text-danger" />
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="address" id="address" placeholder="Address *" />
                    <ErrorMessage name="address" component="div" className="text-danger" />
                    <label><i className="fal fa-address-card" /> </label>
                    <Field type="text" name="city" id="city" placeholder="City *"/>
                    <ErrorMessage name="city" component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="state" id="state" placeholder="State *"/>
                    <ErrorMessage name="state" component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field  type="text" name="country" id="country" placeholder="country *" />
                    <ErrorMessage name='country' component="div" className="text-danger"/>
                    <label><i className="fal fa-address-card" /> </label>
                    <Field type="text" name="pincode" id="pincode" placeholder="Pincode *" />
                    <ErrorMessage name='pincode' component="div" className="text-danger"/>
                    <div className="clearfix">

                      {error && <p className="text-center text-danger">Something Went Wrong</p>}
                      {sucess && <p className="text-center text-danger">Category Added Successfully</p>}
                    </div>

                    {!loading &&

                          <button className="btn float-btn flat-btn color-btn" id="submit" style={{float: 'none'}}>
                                <span className="indicator-label">Submit</span>
                          </button>
                    }
                    {loading && 
                          <button type="submit" disabled className="btn float-btn flat-btn color-btn" style={{ float: "none" }} >
                              <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                          </button>
                    }
                  </Form>
                  </Formik>
                </div>
              </div>
              {/* The Modal Three ends */}
              {/* The Modal Four */}
              <div id="myModalfour" className="modal">
                {/* Modal content */}
                <div className="modal-content">
                  <span className="close3">×</span>
                  <h2 style={{fontSize: '25px'}}><b>CONTACT US</b></h2>
                  <h2 style={{fontSize: '20px'}}><b>Ready to get started?</b></h2>
                  <p>Welcome to Team Registration takes less than a
                    minute but gives you full control over your orders.</p>
                    <Formik initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}>
                  <Form className="custom-form" action="#" name="contactform" style={{float: 'none'}}>
                    <label><i className="fal fa-user" /></label>
                    <ErrorMessage name="cname" component="span" className="text-danger" />
                    <Field  type="text" name="cname" id="name" placeholder="First Name *" />
                    <label><i className="fal fa-user" /></label>
                    <ErrorMessage name="lname" component="span" className="text-danger" />
                    <Field  type="text" name="lname" id="name" placeholder="Last Name *" />
                    <label><i className="fal fa-envelope" /> </label>
                    <ErrorMessage name="cemail" component="span" className="text-danger" />
                    <Field  type="email" name="cemail" id="cemail" placeholder="Email Address *" />
                    <label><i className="fal fa-mobile-android" /> </label>
                    <ErrorMessage name="cphone" component="span" className="text-danger" />
                    <Field  type="text" name="cphone" id="cphone" placeholder="Phone *" />
                    <label><i className="fal fa-building" /> </label>
                    <ErrorMessage name="ccompany" component="span" className="text-danger" />
                    <Field  type="text" name="ccompany" id="ccompany" placeholder="Company *" />
                    <label><i className="fal fa-address-card" /> </label>
                    <ErrorMessage name="cposition" component="span" className="text-danger" />
                    <Field  type="text" name="cposition" id="ccompany" placeholder="Position *" />
                    <Field  component="textarea" name="comments" id="comments" cols={40} rows={3} placeholder="Additional details:" defaultValue={""} />
                    <div className="clearfix">
                    </div>
                    <button className="btn float-btn flat-btn color-btn" id="submit" style={{float: 'none'}}>Submit</button>
                  </Form>
                  </Formik>
                </div>
              </div>
              {/* The Modal Four ends */}
            </div>
            {/* Content end*/} 

          {/* wrapper end */}
          <div id="login123" className="modal fade">
              <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                        <button type='button' className="close" data-dismiss="modal">×</button>
                        <h4 className="modal-title">Please Login</h4>
                    </div>
                    <div className="modal-body">
                      <Formik initialValues={initialValuesLogin}
                              onSubmit={userLogin}
                              validationSchema={validationSchemaLogin}
                              setFieldValue>
                          <Form className="custom-form" style={{float: 'none'}}>
                            <label><i className="fal fa-envelope" /> </label>
                            <Field  type="email" name="user_email" id="user_email" placeholder="Email *" />
                            <ErrorMessage name='user_email' component="div" className='text-danger'/>
                            <label><i className="fas fa-lock"></i></label>
                            <Field  type="password" name="user_pass" id="user_pass" placeholder="Password *" />
                            <ErrorMessage name="user_pass" component="div" className="text-danger" />
                            <div className="clearfix">                                
                                {error1 && <p className='text-center text-danger'>Your username or password is incorrect</p> }
                            </div>
                            {!loading && 
                                <button type='submit' className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                                        <span className="indicator-label">Login</span>
                                </button>
                            }
                            {loading && (
                                <button type='submit' disabled className="btn float-btn flat-btn color-btn" style={{float: 'none'}}>
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </button>
                            )}
                          </Form>
                      </Formik>
                    </div>
                  </div>
              </div>
          </div>
          
        <ToastContainer autoClose={1000}/>
        </>
    )
}