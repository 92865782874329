import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import http,{baseUrl, baseUrl2} from "../../../http-common";
import {Formik, Form, Field, ErrorMessage} from "formik";
import "./style.css";
import { isEmptyObject } from "jquery";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { getCookie, setCookie } from 'typescript-cookie';
import ReactPlayer from 'react-player';

export default function Content(page:any){
    const [getProducts, setProducts] = useState([] as any);
    const [getCategory, setCategory] = useState([]);
    const [getSubCategories, setSubCategories] = useState([]);
    const [getSubCategoriesf, setSubCategoriesf] = useState([]);
    const [getProductOptions, setProductoptions] =useState([]);
    const [getProductOptions1, setProductoptions1] =useState([]);
    const [getCategoryId, setCategoryId] = useState(0);
    const [getSubCategoryId, setSubCategoryId] = useState([] as any);
    const [pageNum, setPageNum] = useState(1);
    const [getproduct_option_value_id, setproduct_option_value_id] = useState([] as any);
    const [getPageNuber, setPageNumber] = useState(12);
    const [getLoader, setLoader] = useState(false);
    const [getUserdata, setUserData] = useState([]);
    const [getUserId, setUserId] = useState(0);
    let email = getCookie('user_email');
    const [getUserType, setUserTpe] = useState('');
    // console.log(page);
    //for scrolling

    const listInnerRef:any = useRef();
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                
                setPageNumber((prev) => prev + 12);
                const page = getPageNuber + 12;
                let ctg_id = getCategoryId;
                let sub_ct_id = getSubCategoryId;
                let pop_id = getproduct_option_value_id;
                const prev = getProducts;
                if(sub_ct_id.length == 0){
                    sub_ct_id = 0;
                }
                if(pop_id.length == 0){
                    pop_id = 0;
                }
                http.get('/products/categories/'+ctg_id+'/'+sub_ct_id+'/'+pop_id+'/'+page).then(Response => {
                    const res = Response.data;
                    setProducts((prev:any) => [...prev, ...res]);
                    console.log(getProducts);
                    return (getProducts);
                }).catch(err => console.log(err));
            }
        }
    };

    useEffect(() => {
        http.get('/products/'+0).then(Response => {
            setProducts(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));
        http.get("/categories")
        .then(Response => {
            setCategory(Response.data);
        }).catch(err => console.log(err));
        http.get("/categories/subcategory/"+0).then(Response => {
            setSubCategories(Response.data);
            setSubCategoriesf(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));
        http.get("/product_option_values/by_category/"+0).then(Response => {
            setProductoptions(Response.data);
            setProductoptions1(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));
        //get user data using email
        http.get('/users/byUserEmail/' + email).then(Response => {
            setUserData(Response.data);
            setUserId(Response.data.id);
            setUserTpe(Response.data.user_type)
        }).catch(err => console.log(err))
    }, [])
    // console.log(getUserdata);
    function categories(e:any){
        const ctg_id = e.target.value;
        // alert('/products/categories/'+ctg_id+'/'+0+'/'+0+'/'+0);
        setCategoryId(ctg_id);
        //Sub Category
        const new_data = getSubCategoriesf.filter((ctg:any) => ctg.parent_id == ctg_id);
        setSubCategories(new_data);
        // console.log(new_data);      
        //Produvt Options
        const new_pop = getProductOptions1.filter((pop:any) => pop.product_option.category_id == ctg_id);
        setProductoptions(new_pop);
        // console.log(new_pop);  
        //Getting Products
        setSubCategoryId([]);
        setproduct_option_value_id([]);
        http.get('/products/categories/'+ctg_id+'/'+0+'/'+0+'/'+0).then(Response => {
            setProducts(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));
    }

    function subCategories(e:any){
        const ctg_id = getCategoryId;
        const old_ids = getSubCategoryId;
        const new_id = e.target.value;
        let main_data:any = getSubCategoryId;
        let pop_id:any = getproduct_option_value_id;
        if(e.target.checked == true){
            setSubCategoryId([...old_ids, new_id]);            
            main_data = [...old_ids, new_id];
        }else{
            main_data = old_ids.filter((datag:any) => datag != new_id);
            setSubCategoryId(main_data);
        }
        // console.log(main_data);
        if(pop_id.length == 0){
            pop_id = 0;
        }
        http.get('/products/categories/'+ctg_id+'/'+main_data+'/'+pop_id+'/'+0).then(Response => {
            setProducts(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));
    }
     
    function productOptions(e:any){
        const ctg_id = getCategoryId;
        let subct_id:any = getSubCategoryId;
        let pop_id:any = getproduct_option_value_id;
        const old_ids = getproduct_option_value_id;
        const new_id = e.target.value;
        if(e.target.checked == true){
            setproduct_option_value_id([...old_ids, new_id]);            
            pop_id = [...old_ids, new_id];
        }else{
            pop_id = old_ids.filter((data:any) => data != new_id);
            setproduct_option_value_id(pop_id);
        }
        // console.log(pop_id);
        if(subct_id.length == 0){
            subct_id = 0;
        }
        console.log(subct_id);
        http.get('/products/categories/'+ctg_id+'/'+subct_id+'/'+pop_id+'/'+0).then(Response => {
            setProducts(Response.data);
            // console.log(Response.data);
        }).catch(err => console.log(err));        
    }

    function addTOWhishList(p_id:any){
        let data:any = {};
        data.product_id = p_id;
        if(getUserId == 0){
            toast.info('Please register/login to add favourites!', { position: toast.POSITION.TOP_RIGHT });
        }else{
            data.user_id = getUserId;
            data.created_by = getUserId;
            data.updated_by = getUserId;
            console.log(data);
            http.post('/favourite_videos', data).then((Response) => {
                toast.success('Video successfully added to favourites!', { position: toast.POSITION.TOP_RIGHT });                
            }).catch(err => console.log(err));
        }        
    }
    
    //console.log(getProducts);
    //console.log(getCategory);
    //console.log(getSubCategories);
    return(
        <>  
            <body id="scroll" 
                 onScroll={onScroll}
                 ref={listInnerRef}>
                <div className="col-12 row" >
                    <div className="col-sm-3 collection-filter">
                    {/* side-bar colleps block stat */}
                    <div className="collection-filter-block  pt-3">
                        {/* brand filter start */}
                        <div className="collection-mobile-back pb-2 mb-2"><span className="filter-back"><i className="fa fa-angle-left" aria-hidden="true" /> back</span></div>
                            <div className="collection-collapse-block open" style={{textAlign:"left"}}>
                                <h6 className="collapse-block-title mt-3">Categories</h6>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter scroll1">
                                        {getCategory?.map ? getCategory.map((category: { id:any, category_image:any, category_name:any, category_description:any, status:any }) => (                                        
                                            <div className="custom-control custom-checkbox collection-filter-checkbox" key={category.id}>
                                                <input onChange={(e:any) => categories(e)}  type="radio" className="custom-control-input" value={''+category.id+''} name="categoryId" id={'"'+category.id+'"'} />
                                                <label className="custom-control-label" htmlFor={'"'+category.id+'"'}>{category.category_name}</label>
                                            </div>
                                        )): "Loading.."}
                                    </div>
                                </div>
                            </div>
                        {/* color filter start here */}
                        {/* size filter start here */}
                        <div className="collection-collapse-block border-0 open pb-2 mb-2" style={{textAlign:"left"}}>
                            <h3 className="collapse-block-title mt-2">Sub Categories</h3>
                            <div className="collection-collapse-block-content">
                                <div className="collection-brand-filter scroll1">
                                    {getSubCategories?.map ? getSubCategories?.map((subcatgories:any, i) => 
                                        <>
                                            <div className="custom-control custom-checkbox collection-filter-checkbox">
                                                <input onChange={(e:any) => subCategories(e)} type="checkbox" className="custom-control-input" value={''+subcatgories.id+''} name="subCategory[]" id={'"subcategory'+subcatgories.id+'"'} />
                                                <label className="custom-control-label" htmlFor={'"subcategory'+subcatgories.id+'"'}>{subcatgories.category_name}</label>
                                            </div>
                                        </>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        <div className="collection-collapse-block border-0 open pb-2 mb-2" style={{textAlign:"left"}}>
                            <h3 className="collapse-block-title mt-2">Product Options</h3>
                            <div className="collection-collapse-block-content">
                                <div className="collection-brand-filter scroll1">
                                    {getProductOptions?.map ? getProductOptions?.map((productoptions:any, i) => 
                                            <>
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={i}>
                                                    <input onChange={(e:any) => productOptions(e)} type="checkbox" className="custom-control-input" value={''+productoptions.id+''} name="ProductOptions" id={'"productoptions'+productoptions.id+'"'} />
                                                    <label className="custom-control-label" htmlFor={'"productoptions'+productoptions.id+'"'}>{productoptions.value_name}</label>
                                                </div>
                                            </>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        {/* {getProductOptions != null ?
                            <>
                            {getProductOptions?.map ? getProductOptions?.map((productoptions:any, i) => 
                                <div key={i} className="collection-collapse-block border-0 open pb-2 mb-2" style={{textAlign:"left"}}>
                                    <h3 className="collapse-block-title mt-2">{productoptions.option_name}</h3>
                                    <div className="collection-collapse-block-content">
                                        <div className="collection-brand-filter scroll">
                                            {productoptions.Product_option_values?.map ? productoptions.Product_option_values.map((productoptionsvalues:any) => 
                                                    <>
                                                        <div className="custom-control custom-checkbox collection-filter-checkbox">
                                                            <input type="checkbox" className="custom-control-input" value={productoptionsvalues.id} name="ProductOptions" id={'"productoptions'+productoptionsvalues.id+'"'} />
                                                            <label className="custom-control-label" htmlFor={'"productoptions'+productoptionsvalues.id+'"'}>{productoptionsvalues.option_value}</label>
                                                        </div>
                                                    </>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            </>
                        : ''} */}
                        {/* price filter start here */}
                    </div>
                    {/* side-bar banner end here */}
                    </div>
                    <div className="collection-content col">
                        <div className="page-main-content">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="top-banner-wrapper">
                                </div>
                                <div className="collection-product-wrapper" >
                                <div className="product-top-filter">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="filter-main-btn"><span className="filter-btn btn btn-theme p-3"><i className="fa fa-filter" aria-hidden="true" /> Filter</span></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="product-filter-content">
                                                <div className="collection-view">
                                                    <ul>
                                                    <li><i className="fa fa-th grid-layout-view text-dark" /></li>
                                                    <li><i className="fa fa-list-ul list-layout-view text-dark" /></li>
                                                    </ul>
                                                </div>
                                                <div className="collection-grid-view">
                                                    <ul>
                                                    <li><img src="/assets2/images/icon/2.png" alt="" className="product-2-layout-view" /></li>
                                                    <li><img src="/assets2/images/icon/3.png" alt="" className="product-3-layout-view" /></li>
                                                    <li><img src="/assets2/images/icon/4.png" alt="" className="product-4-layout-view" /></li>
                                                    <li><img src="/assets2/images/icon/6.png" alt="" className="product-6-layout-view" /></li>
                                                    </ul>
                                                </div>
                                                {/* <div className="product-page-per-view">
                                                    <select>
                                                    <option value="High to low">24 Products Par Page
                                                    </option>
                                                    <option value="Low to High">50 Products Par Page
                                                    </option>
                                                    <option value="Low to High">100 Products Par Page
                                                    </option>
                                                    </select>
                                                </div> */}
                                                <div className="product-page-filter">
                                                    <select>
                                                    <option value="">Sorting items</option>
                                                    <option value="High to low">High to low</option>
                                                    <option value="Low to High">Low to High</option>
                                                    <option value="Low to High">New Arrivals</option>
                                                    <option value="Low to High">Most Popular</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-wrapper-grid">
                                    <div className="row margin-res">
                                    {getProducts?.map ? getProducts.map((products: any, i:any) => (
                                        // getProducts.length 
                                        <>  
                                            <div className="col-md-4 col-sm-6 col-grid-box">
                                                <div className="product-box p-1">                                
                                                    <div className="img-wrapper" style={{borderRadius:'10px'}}>
                                                        <div className="lable-block">
                                                            {/* <span className="lable2">new</span> */}
                                                            <span className="lable4 px-2 py-1 bg-white text-dark rounded">{products.short_description}</span>
                                                        </div>
                                                        {products.video != '' && products.video != null ?
                                                            <>
                                                                <ReactPlayer width="100%" height="150px" controls={true} playing={true} url={''+baseUrl2+'/'+products.video+''} light={''+baseUrl2+'/'+products.image+''}/>
                                                                {/* <iframe style={{ width: "100%",height: "100%", minHeight: "250px", maxHeight: "450px" }} src={''+baseUrl2+'/'+products.video+''} allowFullScreen /> */}
                                                            </>
                                                        :
                                                        products.image != '' && products.image != null ?
                                                            <>
                                                                <div className="front">
                                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("'+baseUrl2+'/'+products.image+'")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                                        <img src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                                    </a>
                                                                </div>
                                                                {/* <div className="back">
                                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("'+baseUrl2+'/'+products.image+'")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                                        <img src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                                    </a>
                                                                </div> */}
                                                            </>
                                                        :
                                                            <>
                                                                <div className="front">
                                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                                        <img src={'/assets2/images/pro3/36.jpg'} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                                    </a>
                                                                </div>
                                                                {/* <div className="back">
                                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/36.jpg")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                                        <img src={'/assets2/images/pro3/36.jpg'} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                                    </a>
                                                                </div> */}
                                                            </>
                                                        }
                                                        <div className="cart-info cart-wrap"> 
                                                            <button onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-heart" aria-hidden="true" /></button>
                                                        </div>                                         
                                                    </div>
                                                    <div className="product-detail p-1 br-b-10">
                                                        <div className="row m-0">
                                                            <Link to={"/single-product/"+products.id} className="col-12">
                                                                {/* <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div> */}
                                                                <Link to={"/single-product/"+products.id}>
                                                                    <h6>{products.product_name}</h6>
                                                                </Link>
                                                            </Link>
                                                            <div className="col-6 m-auto">
                                                                <span className="badge badge-secondary p-1 fw-400">
                                                                    {products.main_cateogery != null && products.main_cateogery.category_name}
                                                                </span>
                                                            </div>
                                                            <div className="col-6 m-auto">
                                                                <span className="badge badge-secondary float-right p-1 fw-400">
                                                                    Universal
                                                                </span>
                                                            </div>
                                                            <div className="col-12 my-2">
                                                                {getUserType == 'ResellerType' ? 
                                                                    <>
                                                                        <h6>₹ {products.mrp} /-</h6>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <h6><del>₹ {products.mrp} /-</del><span style={{color:'#6e179f'}}> {Math.round(((products.mrp - products.product_price)/products.mrp)*100)}% off</span>&nbsp;₹ {products.product_price} /-</h6>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="col-7 m-auto">
                                                                <div className="rating mt-0">
                                                                    <i className="fa fa-star"></i> 
                                                                    <i className="fa fa-star"></i> 
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i> 
                                                                    <i className="fa fa-star"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 m-auto text-right">
                                                                <a href="#" style={{fontSize:'14px'}} onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-heart text-danger" aria-hidden="true" /></a>&nbsp; &nbsp;
                                                                <a href="#" style={{fontSize:'14px'}} onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-shopping-cart text-danger" aria-hidden="true" /></a>
                                                            </div>
                                                        </div>                                                    
                                                    </div>
                                                </div>
                                            </div>                                 
                                        </>
                                    )) : ''}
                                    {getLoader && 
                                        <div className="col-md-12 m-auto text-center">
                                            <div className="m_auto text-center" style={{padding:'10px'}}>Loading...</div>
                                        </div>
                                    }
                                    
                                    </div>
                                </div>
                                {/*<div className="product-pagination">
                                    <div className="theme-paggination-block">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6 col-sm-12">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination">
                                            <li className="page-item"><a className="page-link" href="#" aria-label="Previous"><span aria-hidden="true"><i className="fa fa-chevron-left" aria-hidden="true" /></span> <span className="sr-only">Previous</span></a></li>
                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true"><i className="fa fa-chevron-right" aria-hidden="true" /></span> <span className="sr-only">Next</span></a></li>
                                            </ul>
                                        </nav>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-sm-12">
                                        <div className="product-search-count-bottom">
                                            <h5>Showing Products 1-24 of 10 Result</h5>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Quick-view modal popup start}
                <div className="modal fade bd-example-modal-lg theme-modal" id="quick-view" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <div className="row">
                        <div className="col-lg-6 col-xs-12">
                            <div className="quick-view-img"><img src="/assets2/images/pro3/1.jpg" alt="" className="img-fluid blur-up lazyload" /></div>
                        </div>
                        <div className="col-lg-6 rtl-text">
                            <div className="product-right">
                            <h2>Women Pink Shirt</h2>
                            <h3>$32.96</h3>
                            <ul className="color-variant">
                                <li className="bg-light0" />
                                <li className="bg-light1" />
                                <li className="bg-light2" />
                            </ul>
                            <div className="border-product">
                                <h6 className="product-title">product details</h6>
                                <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                            </div>
                            <div className="product-description border-product">
                                <div className="size-box">
                                <ul>
                                    <li className="active"><a href="#">s</a></li>
                                    <li><a href="#">m</a></li>
                                    <li><a href="#">l</a></li>
                                    <li><a href="#">xl</a></li>
                                </ul>
                                </div>
                                <h6 className="product-title">quantity</h6>
                                <div className="qty-box">
                                <div className="input-group"><span className="input-group-prepend"><button type="button" className="btn quantity-left-minus" data-type="minus" data-field><i className="ti-angle-left" /></button> </span>
                                    <input type="text" name="quantity" className="form-control input-number" defaultValue={1} /> <span className="input-group-prepend"><button type="button" className="btn quantity-right-plus" data-type="plus" data-field><i className="ti-angle-right" /></button></span></div>
                                </div>
                            </div>
                            <div className="product-buttons"><a href="#" className="btn btn-solid">add to cart</a> <a href="#" className="btn btn-solid">view detail</a></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Quick-view modal popup end*/}
            </body>
            <ToastContainer autoClose={2500}/>
        </>
    )
}