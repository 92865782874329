
import { useEffect as Useeffect } from "react";

const UseScript = (url: string) => {
    Useeffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
};

export default function ProductsFooter(){
    UseScript('/assets2/js/jquery-3.3.1.min.js');
    UseScript('/assets2/js/menu.js');
    UseScript('/assets2/js/lazysizes.min.js');
    UseScript('/assets2/js/popper.min.js');
    UseScript('/assets2/js/price-range.js');
    UseScript('/assets2/js/slick.js');
    UseScript('/assets2/js/bootstrap.js');
    UseScript('/assets2/js/bootstrap-notify.min.js');
    UseScript('/assets2/js/datatables/jquery.dataTables.min.js');
    //UseScript('/assets2/js/datatables/custom-basic.js');
    UseScript('/assets2/js/jquery.elevatezoom.js');
    UseScript('/assets2/js/script.js');
    return(
    <>
      {/* footer start */}
        <footer className="footer-light">
          <section className="section-b-space light-layout">
            <div className="container">
              <div className="row footer-theme partition-f">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-title footer-mobile-title">
                    <h4>about</h4>
                  </div>
                  <div className="footer-contant">
                    <div className="footer-logo" style={{textAlign:"left"}}><img src="/assets/images/logo.jpg" alt="" style={{width:"32%",border:"1px solid #6e179f"}}/></div>
                    <div className="footer-social">
                      <ul>
                        <li><a href="#"><i className="fa fa-facebook" aria-hidden="true" style = {{color:"#cacaca"}} /></a></li>
                        <li><a href="#"><i className="fa fa-twitter" aria-hidden="true" style = {{color:"#cacaca"}}/></a></li>
                        <li><a href="#"><i className="fa fa-instagram" aria-hidden="true" style = {{color:"#cacaca"}}/></a></li>
                        <li><a href="#"><i className="fa fa-youtube" aria-hidden="true" style = {{color:"#cacaca"}}/></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col offset-xl-1">
                  {/* <div className="sub-title">
                    <div className="footer-title">
                      <h4>my account</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        <li><a href="#">mens</a></li>
                        <li><a href="#">womens</a></li>
                        <li><a href="#">clothing</a></li>
                        <li><a href="#">accessories</a></li>
                        <li><a href="#">featured</a></li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                <div className="col">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>shop</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        <li><a href="#">new event arivals</a></li>
                        <li><a href="#">events trending now</a></li>
                        <li><a href="#">festival events</a></li>
                        <li><a href="#">brand events</a></li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
                <div className="col">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>store information</h4>
                    </div>
                    <div className="footer-contant">
                      <ul className="contact-list">
                        <li><i className="fa fa-map-marker" />Hitech City, Main Westin Hotel.
                        </li>
                        <li><i className="fa fa-phone" />Call Us: 123-456-7898</li>
                        <li><i className="fa fa-envelope-o" />Email Us: Hello@arkwrx.com</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      {/* footer end */}

    </>
    )
}