import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookie, setCookie } from "typescript-cookie";
import Datatable from "../order_list/datatable"
import http, { baseUrl } from "../../../http-common";
import MyusersListTable from './my_users';
import MyVideos from "./my_videos";
import MyFavouries from "./my_favourites";


export default function Cintent(){
    let email = getCookie('user_email');
    const [getUserData, setUserData] = useState([] as any);
    const navigate = useNavigate();
    function logOut(){
        setCookie('user_email', '', { expires: 90});
        //toast.success('Logout Successfully !',{position:toast.POSITION.TOP_RIGHT});
        // window.location.reload();
        navigate('/main');
        email = '';
    }
    if(!getCookie('user_email') || email == ''){
        navigate('/login');
    }
    // console.log(email);
    useEffect(() => {
        if(!getCookie('user_email') || email == ''){
            navigate('/login');
        }else{
            http.get('/users/byUserEmail/' + email).then(Response => {
                setUserData(Response.data);
            }).catch(err => console.log(err))   
        }
    }, [])
    
    // console.log(getUserData);
    return(

        <section className="dashboard-section section-b-space">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
                    <div className="dashboard-sidebar">
                        <div className="profile-top">
                            <div className="profile-image">
                                <img src="/assets2/images2/blank.png" alt="" className="img-fluid" />
                            </div>
                            <div className="profile-detail">
                                <h5>{getUserData.user_name}</h5>
                                <h6>{getUserData.phone}</h6>
                                <h6>{getUserData.user_email}</h6>
                                <p className="nav-item"><a onClick={() => logOut()} href="#" className="nav-link" data-toggle="modal" data-target="#logout">Logout</a>
                                </p>
                            </div>
                        </div>
                        <div className="faq-tab">
                            <ul className="nav nav-tabs" id="top-tab" role="tablist">
                            <li className="nav-item">
                                <a data-toggle="tab" className="nav-link active" href="#orders"><i style={{fontSize:'120%'}} className="fas fa-shopping-bag"></i> &nbsp; Orders</a>
                            </li>
                            <li className="nav-item"><a data-toggle="tab" className="nav-link" href="#myusers"><i style={{fontSize:'120%'}} className="fas fa-users"></i> &nbsp; My Users</a>
                            </li>
                            <li className="nav-item"><a data-toggle="tab" className="nav-link" href="#myvideos"><i style={{fontSize:'120%'}} className="fas fa-film"></i> &nbsp; My Videos</a>
                            </li>
                            <li className="nav-item"><a data-toggle="tab" className="nav-link" href="#myfavourites"><i style={{fontSize:'120%'}} className="fas fa-heart"></i> &nbsp; My Favourites</a>
                            </li>
                            <li className="nav-item"><a data-toggle="tab" className="nav-link" href="#profile"><i style={{fontSize:'120%'}} className="fas fa-cog"></i> &nbsp; Profile</a>
                            </li>
                            {getUserData.user_type && getUserData.user_type == "ResellerType" &&
                                <li className="nav-item"><Link className="nav-link" to="/credit_page"><i style={{fontSize:'120%'}} className="fas fa-credit-card"></i> &nbsp; Credit Details</Link>
                                </li>
                            }
                            {/* <li className="nav-item"><a className="nav-link" data-toggle="modal" data-target="#logout">logout</a>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="faq-content tab-content" id="top-tabContent">
                        <div className="tab-pane fade show active" id="orders">
                            <div className="row">
                            <div className="col-12">
                                <div className="card dashboard-table mt-0">
                                <div className="card-body">
                                    <div className="top-sec">
                                    <h3>Orders</h3>
                                    {/* <a href="#" className="btn btn-sm btn-solid">add product</a> */}
                                    </div>
                                    <Datatable/>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="myusers">
                            <div className="row">
                            <div className="col-12">
                                <div className="card dashboard-table mt-0">
                                <div className="card-body">
                                    <div className="top-sec">
                                    <h3>My Users List</h3>
                                    {/* <a href="#" className="btn btn-sm btn-solid">add product</a> */}
                                    </div>
                                    <MyusersListTable/>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="myvideos">
                            <div className="row">
                            <div className="col-12">
                                <div className="card dashboard-table mt-0">
                                <div className="card-body">
                                    <div className="top-sec">
                                    <h3>My Videos</h3>
                                    {/* <a href="#" className="btn btn-sm btn-solid">add product</a> */}
                                    </div>
                                    <MyVideos/>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="myfavourites">
                            <div className="row">
                            <div className="col-12">
                                <div className="card dashboard-table mt-0">
                                <div className="card-body">
                                    <div className="top-sec">
                                    <h3>My Favourites</h3>
                                    {/* <a href="#" className="btn btn-sm btn-solid">add product</a> */}
                                    </div>
                                    <MyFavouries/>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile">
                            <div className="row">
                            <div className="col-12">
                                <div className="card mt-0">
                                <div className="card-body">
                                    <div className="dashboard-box">
                                    <div className="dashboard-title">
                                        <h4>Profile</h4>
                                        {/* <span data-toggle="modal" data-target="#edit-profile">edit</span> */}
                                    </div>
                                    <div className="dashboard-detail">
                                        {getUserData.length != 0 && 
                                        <ul>
                                            <li>
                                                <div className="details">
                                                <div className="left">
                                                    <h6>Name</h6>
                                                </div>
                                                <div className="right">
                                                    <h6>{getUserData.user_name}</h6>
                                                </div>
                                                </div>
                                            </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>Email Address</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.user_email}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>Phone Number</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.phone}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>Address</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.address}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>City</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.city}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>State</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.state}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="details">
                                            <div className="left">
                                                <h6>pin / zip</h6>
                                            </div>
                                            <div className="right">
                                                <h6>{getUserData.pincode}</h6>
                                            </div>
                                            </div>
                                        </li>
                                        </ul>
                                        }
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

    )
}