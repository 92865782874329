import { Link, useParams, useNavigate } from "react-router-dom";
import './content.css';
import { getCookie, setCookie } from 'typescript-cookie'
import { useEffect, useState } from "react";
import http, { baseUrl , baseUrl2} from "../../../http-common";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { v4 as uuid } from 'uuid';
import ReactPlayer from 'react-player';
import './style.css';

export default function Content(props: any) {

    const [getProduct, setProducts] = useState([] as any);
    const [getUserdata, setUserData] = useState([]);
    const [getUserId, setUserId] = useState(0);
    const [getSubmit, setSubmit] = useState(false);
    const [getproduct_name, setproduct_name] =useState();
    const [getAddon, setAddon] =useState(false);
    
    const params = useParams();
    const getProductID = params.productId;

    const [getAdditionalDiscount, setAdditionalDiscount] = useState(false);

    //Product Data
    const [getMrp, setMrp] = useState(0);
    const [getMrp2, setMrp2] = useState(0);
    const [getproduct_price, setproduct_price] = useState(0);
    const [getreseller_product_price, setreseller_product_price] = useState(0);
    const [getProductOptions, setProductOptions] = useState([]);
    const [getPercentageCat, setPercentageCat] = useState(0);
    const [getResellerPercentageCat, setResellerPercentageCat] = useState(0);

    const [getSubcategory, setSubcategory] = useState([] as any);

    //Get Categories by category id
    const [getProductsBicid, setProductsBicid] = useState([]);

    let email = getCookie('user_email');
    const [getUserType, setUsertype] = useState();
    // const [getUserType, setUserTpe] = useState('');
    
    //setting uniqid
    let unique_id:any = uuid();;
    //unique_id = uuid();
    if(!getCookie('unique_id')){
        unique_id = uuid();
    }
    if(getCookie('unique_id') == '' || getCookie('unique_id') == undefined ){
        unique_id = uuid();
    }else{
        unique_id = getCookie('unique_id');
    }

    //Setting uniqid as cookie
    if(!getCookie('user_email')){
        setCookie('unique_id', unique_id, {expires:365});
    }
    if(getCookie('user_email') == ''){
        setCookie('unique_id', unique_id, {expires:365});
    }
    
    useEffect(() => {
        //Get Products using id
        http.get('/products/product/' + getProductID).then(Response => {
            // console.log(Response.data);
            setProducts(Response.data);
            setMrp(Response.data.mrp);
            setMrp2(Response.data.mrp);
            setproduct_price(Response.data.product_price);
            setproduct_name(Response.data.product_name);
            setreseller_product_price(Response.data.reseller_descount_percentage);
            http.get('/productoptions/getByProductID/' + getProductID).then(ResponsePO => {
                setProductOptions(ResponsePO.data);
                console.log(ResponsePO);
            }).catch(err => console.log(err))
            http.get('/products/productsGetbyCategoryID/' + Response.data.category_id + '/6').then(Response => {
                setProductsBicid(Response.data);
                // console.log(Response.data)
            })
            http.get('/categories/categorybyid/'+Response.data.category_id)
            .then((catres) => {
                if(catres.data.vendor_discount_percent == '' || catres.data.vendor_discount_percent == null){
                    setPercentageCat(0);
                }else{
                    setPercentageCat(catres.data.vendor_discount_percent);
                }
                if(catres.data.reseller_descount_percentage == '' || catres.data.reseller_descount_percentage == null){
                    setResellerPercentageCat(0);
                }else{
                    setResellerPercentageCat(catres.data.reseller_descount_percentage);
                }
            }).catch(err => console.log(err))            
            http.get('/categories/categorybyid/'+Response.data.sub_category)
            .then((sub_category) => {
                setSubcategory(sub_category.data);
            }).catch(err => console.log(err))

        }).catch(err => console.log(err))
        //get user data using email
        // console.log(getSubcategory);
        http.get('/users/byUserEmail/' + email).then(Response => {
            setUserData(Response.data);
            setUserId(Response.data.id);
            setUsertype(Response.data.user_type);
        }).catch(err => console.log(err))
        //get product options using category
    }, []);

    // console.log(getProductOptions);
    const navigate = useNavigate();
    const initialvalues = {
        user_id: getUserId,
        created_by: getUserId,
        status: 'Pending',
        product_id: Number(getProductID),
        quantity: 1,
        Cart_product_details: [],
        product_language_id: [],
        unique_id:unique_id
    }
    const addProductToCart = (data: any) => {        
        let formdata = new FormData();
        data.user_id = getUserId;
        data.created_by = getUserId;
        data.ppmrp = [getMrp, getMrp2];
        data.ppprice = [getproduct_price, ''+Math.round( getMrp2 - (((Number(getPercentageCat))/100) * getMrp2) )+'' ];
        data.rpprice = [''+Math.round( getMrp - (((Number(getreseller_product_price))/100) * getMrp) )+'', ''+Math.round( getMrp2 - (((Number(getResellerPercentageCat))/100) * getMrp2) )+'' ];
                
        if(getAdditionalDiscount == false){
            data.Cart_product_details = data.Cart_product_details.slice(0, 1);
        }
        http.post('/cart', data)
        .then(Response => {
            toast.success('Product Added Successfully!', { position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
                navigate('/cart');
            }, 2000);
            setSubmit(false);
        })
        .catch(err => {
            console.log(err);
        })
    }

    function activateOptions(eid: any, thisClassName: any, price:any) {
        let aid = document.getElementById(eid);
        const myElement = Array.from(document.getElementsByClassName(thisClassName));
        myElement.forEach(ele => {
            ele.classList.remove("active_classname");
        });
        aid?.classList.add("active_classname");
        let pricing = Array.from(document.getElementsByClassName(price));
        let mrp = 0
        pricing.forEach((ele:any) => {
            if(ele.checked == true) {
                var [before, after] = ele.id.split("_");
                mrp = Number(mrp) + Number(before);
            }
        })
    }

    function activateOptions1(eid: any, thisClassName: any, price:any) {
        let aid = document.getElementById(eid);
        const myElement = Array.from(document.getElementsByClassName(thisClassName));
        myElement.forEach(ele => {
            ele.classList.remove("active_classname");
        });
        aid?.classList.add("active_classname");
        let pricing = Array.from(document.getElementsByClassName(price));
        let mrp = 0
        pricing.forEach((ele:any) => {
            if(ele.checked == true) {
                var [before, after] = ele.id.split("_");
                mrp = Number(mrp) + Number(before);
            }
        })
    }    
    function fAdditionalDiscount(){
        if(getAdditionalDiscount == true){
            setAdditionalDiscount(false);
            setAddon(false);
        }
        if(getAdditionalDiscount == false){
            setAdditionalDiscount(true);
            setAddon(true);
        }
    }

    function addTOWhishList(p_id:any){
        let data:any = {};
        data.product_id = p_id;
        if(getUserId == 0){
            toast.info('Please register/login to add favourites!', { position: toast.POSITION.TOP_RIGHT });
        }else{
            data.user_id = getUserId;
            data.created_by = getUserId;
            data.updated_by = getUserId;
            // console.log(data);
            http.post('/favourite_videos', data).then((Response) => {
                toast.success('Video successfully added to favourites!', { position: toast.POSITION.TOP_RIGHT });                
            }).catch(err => console.log(err));
        }
        
    }
    return (
        <>
            {/* section start */}
            <section>
                <div className="collection-wrapper">
                    <div className="container-fluid bg-white">
                        <div className="container">
                            <Formik initialValues={initialvalues}
                                onSubmit={addProductToCart}>
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-8 col-sm-12 col-xs-12 order-up">
                                            {getProduct.video != '' ?
                                                <>                                                
                                                    {/* <ReactPlayer className="bx-shadow" width="100%" height="420px" controls={true} playing={true} url={''+baseUrl2+'/'+getProduct.video+''} light={''+baseUrl2+'/'+getProduct.image+''}/> */}
                                                    <ReactPlayer className="bx-shadow" width="100%" height="auto" controls={true} playing={true} url={''+baseUrl2+'/'+getProduct.video+''}/>
                                                </>
                                            :
                                            getProduct.image != '' ?
                                                <>
                                                    <div className="front">
                                                        <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url('+baseUrl2+'/'+getProduct.image+')', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "450px", maxHeight: "650px"}}>
                                                            <img src={''+baseUrl2+'/'+getProduct.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                        </a>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className="front">
                                                            <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "450px", maxHeight: "650px"}}>
                                                                <img src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                            </a>
                                                    </div>
                                                </>
                                            }

                                            <div className="row mt-3">
                                                <div className="col-md-6 col-sm-6 m-auto">
                                                    <div className="d-flex m-auto">
                                                        <div className="rating my-auto">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>&nbsp;&nbsp;
                                                        <p className="text-primary pb-0 my-auto">85 ratings</p>
                                                        <a href="#" className="ml-3 p-2" style={{fontSize:'18px', border:'0.5px solid red' }} onClick={() => addTOWhishList(getProductID)} title="Add to Wishlist" tabIndex={0}><i className="ti-heart text-danger" aria-hidden="true" /></a>&nbsp; &nbsp;
                                                        <p className="ml-2 my-auto">Favorite</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h6>Share it</h6>
                                                            <p>or share with link</p>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex">
                                                                <a href="#" className="ml-3 p-2" style={{fontSize:'18px' }} onClick={() => addTOWhishList(getProductID)} title="Share Link" tabIndex={0}><i className="fas fa-paper-plane"></i></a>&nbsp; &nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="nav nav-tabs nav-material" id="top-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="top-home-tab" data-toggle="tab" href="#top-home" role="tab" aria-selected="true">Prodcut Details</a>
                                                    <div className="material-border" />
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="profile-top-tab" data-toggle="tab" href="#top-profile" role="tab" aria-selected="false">Referance Images</a>
                                                    <div className="material-border" />
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="description-top-tab" data-toggle="tab" href="#top-description" role="tab" aria-selected="false">Terms and Conditions</a>
                                                    <div className="material-border" />
                                                </li>
                                                {/* <li className="nav-item"><a className="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-selected="false">Video</a>
                                                    <div className="material-border" />
                                                </li> */}
                                                <li className="nav-item">
                                                    <a className="nav-link" id="review-top-tab" data-toggle="tab" href="#top-review" role="tab" aria-selected="false">Write Review</a>
                                                    <div className="material-border" />
                                                </li>
                                            </ul>
                                            <div className="tab-content nav-material" id="top-tabContent">
                                                <div className="tab-pane fade show active" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                                                    <h6 className="pt-2">Product Details :</h6>
                                                    <div className="single-product-tables mt-0 table-responsive-md ml-3">
                                                        <table className="table table-bordered table-sm table-striped mb-0">
                                                            <tbody>
                                                                {getProductOptions?.map ? getProductOptions.map((pop: any, i) =>
                                                                    <>
                                                                        {pop.view_to_customer == 'Yes' && 
                                                                            <tr>
                                                                                <td className="nowrap">{pop.option_name}</td>
                                                                                <td className="nowrap">
                                                                                    {pop.product_mapping_product_options?.map ? pop.product_mapping_product_options.map((popv: any, j: any) =>
                                                                                        
                                                                                        <>
                                                                                            {popv.product_option_value.value_name}, &nbsp;
                                                                                        </>
                                                                                    
                                                                                    ) : ''}
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                ) : ''}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <h6 className="pt-2 mb-0">
                                                        Media Information :
                                                    </h6>
                                                    <div className="ml-3">
                                                        <p className="py-1 mb-0">Digitla Media Type Download - Video File.</p>
                                                        <p className="py-1 mb-0">Video link sent over email link and mobile app download in mp4 fromat full HD.</p>
                                                        <p className="py-1 mb-0">HD Versions so that you can use in all social platforms.</p>
                                                    </div>
                                                    <h6 className="pt-2 mb-0">
                                                        Product Description :
                                                    </h6>
                                                    <p className="py-2 ml-3">
                                                        {getSubcategory.length != 0 && getSubcategory != null && getSubcategory.long_description}
                                                    </p>
                                                </div>
                                                <div className="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">
                                                    <h6 className="pt-2 mb-0">
                                                        Referance Images :
                                                    </h6>
                                                    <div className="row">
                                                        {getSubcategory.category_images?.map ? getSubcategory.category_images.map((img:any) => 
                                                            <>
                                                                {img.image_type == 'image' && 
                                                                    <div className="col-sm-3 col-md-2">
                                                                        <img className="image-fluid p-2" style={{width:'100%', height:'100%'}} alt="img" src={baseUrl2+'/'+img.images}/>
                                                                    </div>
                                                                }
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                    <h6 className="pt-2 mb-0">
                                                        How to used application infromation video demo :
                                                    </h6>
                                                    <div className="row">
                                                    {getSubcategory.category_images?.map ? getSubcategory.category_images.map((img:any) => 
                                                            <>
                                                                {img.image_type == 'video' && 
                                                                    <>
                                                                        {/* <div className="col-sm-4 col-md-4"></div> */}
                                                                        <div className="col-sm-4 col-md-4">
                                                                            {/* <img className="image-fluid p-2" style={{width:'100%', height:'100%'}} alt="img" src={baseUrl2+'/'+img.images}/> */}
                                                                            <iframe style={{ width: "100%",height: "100%", minHeight: "250px", maxHeight: "450px" }} src={baseUrl2+'/'+img.images} allowFullScreen />
                                                                        </div>
                                                                        {/* <div className="col-sm-4 col-md-4"></div> */}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="top-description" role="tabpanel" aria-labelledby="description-top-tab">
                                                    <h6 className="pt-2">
                                                        Terms and Conditions and Returned and Expiry Policy
                                                    </h6>
                                                    <div className="ml-3">
                                                        <p className="py-1 mb-0">1. Once orders placed will cancels with in 2 hours refund 95% amount</p>
                                                        <p className="py-1 mb-0">2. Orders Events Documenry Not Responces any Communations</p>
                                                        <p className="py-1 mb-0">3. Cancel after 24 hours 70% Refund Amount</p>
                                                        <p className="py-1 mb-0">4. If You Not Propers Sent Documents Deadline Not Reach.our Not Responsoblity</p>
                                                    </div>                                    
                                                </div>
                                                <div className="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                                                    <div className="mt-4 text-center">
                                                        <iframe width={560} height={315} src="https://www.youtube.com/embed/rMtMuBh-tP0" allow="autoplay; encrypted-media" allowFullScreen />
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">
                                                    <form className="theme-form">
                                                        <div className="form-row">
                                                            <div className="col-md-12">
                                                                <div className="media">
                                                                    <label>Rating</label>
                                                                    <div className="media-body ml-3">
                                                                        <div className="rating three-star"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="name">Name</label>
                                                                <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="email">Email</label>
                                                                <input type="text" className="form-control" id="email" placeholder="Email" required />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="review">Review Title</label>
                                                                <input type="text" className="form-control" id="review" placeholder="Enter your Review Subjects" required />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="review">Review Title</label>
                                                                <textarea className="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows={6} defaultValue={""} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <button className="btn btn-solid" type="submit">Submit YOur Review</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="product-right product-form-box px-2 bg-white text-left py-4">
                                                <h2 className="product-title text-dark float-left text-left w-p-100">{getproduct_name}</h2>
                                                <div className="pb-2 pt-2 text-left">
                                                    <p>
                                                        {getProductOptions?.map ? getProductOptions.map((pop: any, i) =>
                                                                pop.view_to_customer == 'Yes' &&
                                                                pop.product_mapping_product_options.length == 1 &&                                                             
                                                                    pop.product_mapping_product_options?.map ? pop.product_mapping_product_options.map((popv: any, j: any) =>
                                                                        <>{pop.option_name} - 
                                                                            {pop.containers_group == 'addingduration' ? 
                                                                               <>{popv.product_option_value.price} &nbsp; {popv.product_option_value.duration_type}</>
                                                                            :
                                                                                popv.product_option_value.value_name 
                                                                            }
                                                                         / &nbsp;</>
                                                                    ) : ''
                                                                
                                                        ) : ''}
                                                    </p>
                                                </div>
                                                <div className="pb-2 pt-2 text-left d-flex m-auto">
                                                    
                                                        {getUserType == 'ResellerType' ? 
                                                            <><h4>₹ {Math.round(getMrp-((getMrp*getreseller_product_price)/100))}.00 /- </h4> &nbsp; &nbsp;</>
                                                        :
                                                            <><h4>₹ {getproduct_price}.00 /- </h4> &nbsp; &nbsp;</>
                                                        }
                                                        
                                                    <p className="text-color-grey">
                                                        {getUserType == 'ResellerType' ?
                                                            <><b> you save </b> &nbsp;&nbsp;&nbsp;<del className="text-color-pink font-size-15px">₹{getMrp}</del>&nbsp;&nbsp;<span className="text-color-green font-size-11px">[{Math.round(getreseller_product_price)}% off]</span></>
                                                        :
                                                            <><b> you save </b> &nbsp;&nbsp;&nbsp;<del className="text-color-pink font-size-15px">₹{getMrp}</del>&nbsp;&nbsp;<span className="text-color-green font-size-11px">[{Math.round(((getMrp - getproduct_price) / getMrp) * 100)}% off]</span></>
                                                        }
                                                    </p>
                                                </div>

                                                <div className="product-description border-product border-dashed-2px col-md-12 p-0 m-0">
                                                    {getProductOptions?.map ? getProductOptions.map((pop: any, i) =>
                                                        <>  
                                                            {pop.view_to_customer == 'Yes' && pop.product_mapping_product_options.length > 1 &&
                                                            <>  
                                                                <div className="row p-0 m-0 border-dashed-2px-bt ">
                                                                    <div className="col-md-12 pb-2 pl-0">
                                                                        <h6 key={i} className="col-md-12 pl-0 product-title float-left text-muted">Select {pop.option_name}</h6>
                                                                        <ul className="text-center">
                                                                            {pop.product_mapping_product_options?.map ? pop.product_mapping_product_options.map((popv: any, j: any) =>
                                                                                <>
                                                                                    <li className="m-1 p-1">
                                                                                        <a type="button" className="text-white cart-text-items">
                                                                                            <label id={"a_"+popv.product_option_value.id} className={"btn btn-sm m-auto bc-dblue cart-text-items cart-select-items cart_product_options_" + pop.id} htmlFor={''+popv.product_option_value.price+'_pp0'+popv.product_option_value.id}>
                                                                                                <Field  className="product_0" onClick={()=> activateOptions("a_"+popv.product_option_value.id, "cart_product_options_"+pop.id, "product_0")} style={{display:'none'}} required id={''+popv.product_option_value.price+'_pp0'+popv.product_option_value.id} type="radio" value={''+popv.product_option_value.id+''} name={"Cart_product_details[0]["+i+"]"} />
                                                                                                {popv.product_option_value.value_name}
                                                                                            </label>
                                                                                        </a>
                                                                                    </li>&nbsp;
                                                                                </>
                                                                            ) : ''}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            }

                                                        </>
                                                    ) : ''}
                                                    <h6 className="product-title text-muted">Delivery</h6><br />
                                                    <ul style={{ marginTop: "-10px", marginBottom: "8px" }}>
                                                        <li style={{ marginBottom: "5px;" }}>
                                                            {/*<input name="delivery" checked={true} id="standard" type="radio"/>*/}&nbsp;<label htmlFor="standard" className="text-muted">Standard Delivery: with in 72 hours</label>
                                                        </li><br/>   
                                                        <li style={{ marginBottom: "5px;" }}>
                                                            {/*<input name="delivery" id="express" type="radio"/>*/}&nbsp;<label htmlFor="express" className="text-muted">Express Delivery: with in 24 hours delivery</label>
                                                        </li><br/>                                                    
                                                    </ul>
                                                    <h6 className="product-title"></h6>
                                                    <div className="accordion theme-accordion bg-dark-blue" id="accordionExample">  
                                                        {getPercentageCat != 0 &&
                                                            <div className="card" style={{border: 'unset'}}>
                                                                <a onClick={() => fAdditionalDiscount()} className="b-img card-header" id="headingTwo">
                                                                    <h6 className="product-title mb-0">
                                                                        <button  className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            ADD ON ADDITIONAL 
                                                                            {getUserType == 'ResellerType' ? <>{getResellerPercentageCat}</> : <>{getPercentageCat}</>}
                                                                            % OFF
                                                                        </button>
                                                                    </h6>
                                                                </a>
                                                                {getAdditionalDiscount && 
                                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                                        <div className="card-body px-0 pb-0">
                                                                            <p>Add on select second Video Title Language ₹{getMrp2}</p>
                                                                            <div className="d-flex m-auto pt-2">
                                                                                {getUserType == 'ResellerType' ?
                                                                                    <>
                                                                                        <h4 className="text-dark">₹ {Math.round( getMrp2 - (((Number(getResellerPercentageCat))/100) * getMrp2) )}.00 /-</h4> &nbsp; &nbsp;
                                                                                        <p className="text-color-grey">
                                                                                            <b> you save </b> &nbsp;&nbsp;&nbsp;<del className="text-color-pink font-size-15px">₹{getMrp2}</del>&nbsp;&nbsp;<span className="text-color-green font-size-11px">{getResellerPercentageCat}% off</span>                                                                                    
                                                                                        </p>
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                        <h4 className="text-dark">₹ {Math.round( getMrp2 - (((Number(getPercentageCat))/100) * getMrp2) )}.00 /-</h4> &nbsp; &nbsp;
                                                                                        <p className="text-color-grey">
                                                                                            <b> you save </b> &nbsp;&nbsp;&nbsp;<del className="text-color-pink font-size-15px">₹{getMrp2}</del>&nbsp;&nbsp;<span className="text-color-green font-size-11px">{getPercentageCat}% off</span>                                                                                    
                                                                                        </p>
                                                                                    </>
                                                                                }
                                                                                
                                                                            </div>
                                                                            {getProductOptions?.map ? getProductOptions.map((pop: any, i) =>
                                                                                <>  
                                                                                    {pop.product_mapping_product_options.length > 1 && pop.view_to_customer == 'Yes' &&
                                                                                        <>
                                                                                            <h6 className="product-title text-muted">Select {pop.option_name}</h6><br />
                                                                                            <ul style={{ marginTop: "-10px", marginBottom: "8px", textAlign:'center' }}>
                                                                                                {pop.product_mapping_product_options?.map ? pop.product_mapping_product_options.map((popv: any, j: any) =>
                                                                                                    <>
                                                                                                        <li>
                                                                                                            <a type="button" className="cart-text-items">
                                                                                                                <label id={"a_1"+popv.product_option_value.id} className={"bc-dblue btn cart-text-items cart-select-items cart_product_options_1" + pop.id} htmlFor={''+popv.product_option_value.price+'_pp01'+popv.product_option_value.id} style={{fontSize:'12px'}}>
                                                                                                                    {getAddon ?
                                                                                                                            <Field required={true} className="product_1" onClick={()=> activateOptions1("a_1"+popv.product_option_value.id, "cart_product_options_1"+pop.id, "product_1")} style={{display:'none'}} id={''+popv.product_option_value.price+'_pp01'+popv.product_option_value.id} type="radio" value={''+popv.product_option_value.id+''} name={"Cart_product_details[1]["+i+"]"} />
                                                                                                                        :
                                                                                                                            <Field  className="product_1" onClick={()=> activateOptions1("a_1"+popv.product_option_value.id, "cart_product_options_1"+pop.id, "product_1")} style={{display:'none'}} id={''+popv.product_option_value.price+'_pp01'+popv.product_option_value.id} type="radio" value={''+popv.product_option_value.id+''} name={"Cart_product_details[1]["+i+"]"} />                                                                                                                                                                                                                                            
                                                                                                                    }
                                                                                                                    {popv.product_option_value.value_name}
                                                                                                                </label>
                                                                                                            </a>
                                                                                                        </li>&nbsp;
                                                                                                    </>
                                                                                                ) : ''}
                                                                                            </ul>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            ) : ''}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <h6 className="product-title text-dark my-3">
                                                        Total Media Payment: &nbsp;
                                                        {getUserType == 'ResellerType' ?
                                                            <>
                                                                {!getAddon && '₹'+ Math.round((getMrp-((getMrp*getreseller_product_price)/100)))}
                                                                {getAddon && '₹'+Number(Number((getMrp-((getMrp*getreseller_product_price)/100)))+Number( Math.round( getMrp2 - (((Number(getResellerPercentageCat))/100) * getMrp2) ) ))}
                                                            </>
                                                        :
                                                            <>
                                                                {!getAddon && '₹'+getproduct_price}
                                                                {getAddon && '₹'+Number(Number(getproduct_price)+Number( Math.round( getMrp2 - (((Number(getPercentageCat))/100) * getMrp2) ) ))}
                                                            </>
                                                        }                                                    
                                                    </h6>
                                                </div>
                                                <div className="product-buttons row m-0">
                                                    {!getSubmit &&
                                                        <>  
                                                            <div className="col-6 pl-0">
                                                                <button type="submit" className="btn btn-solid w-100">add to cart</button>
                                                            </div>
                                                            <div className="col-6 pl-0">
                                                                <button type="submit" className="btn btn-solid w-100">buy now</button>
                                                            </div>
                                                        </>
                                                    }
                                                    {getSubmit &&
                                                        <>  
                                                            <div className="col-6 pl-0">
                                                                <button disabled type="submit" className="btn btn-solid w-100">adding ....</button>                                                                
                                                            </div>
                                                            <div className="col-6 pl-0">
                                                                <button disabled type="submit" className="btn btn-solid w-100">buy now</button>                                                                
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tab-product m-0 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-lg-8 bg-white">
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* Section ends */}
            {/* product-tab starts */}
            {/* product-tab ends */}
            {/* product section start */}
            <section className="section-b-space ratio_asos bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12 product-related">
                            <h5>Related Products</h5>
                        </div>
                    </div>
                    <div className="row search-product">
                        {getProductsBicid?.map ? getProductsBicid.map((products: any, i) => (
                            <>
                            <div className="col-md-3 col-sm-4 col-grid-box mb-3">
                                <div className="product-box p-1">                                
                                    <div className="img-wrapper" style={{borderRadius:'10px'}}>
                                        <div className="lable-block">
                                            {/* <span className="lable2">new</span> */}
                                            <span className="lable4 px-2 py-1 bg-white text-dark rounded">{products.short_description}</span>
                                        </div>
                                        {products.video != '' && products.video != null ?
                                            <>
                                                <ReactPlayer width="100%" height="150px" controls={true} playing={true} url={''+baseUrl2+'/'+products.video+''} light={''+baseUrl2+'/'+products.image+''}/>
                                                {/* <iframe style={{ width: "100%",height: "100%", minHeight: "250px", maxHeight: "450px" }} src={''+baseUrl2+'/'+products.video+''} allowFullScreen /> */}
                                            </>
                                        :
                                        products.image != '' && products.image != null ?
                                            <>
                                                <div className="front">
                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("'+baseUrl2+'/'+products.image+'")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                        <img src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                    </a>
                                                </div>
                                                {/* <div className="back">
                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("'+baseUrl2+'/'+products.image+'")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                        <img src={''+baseUrl2+'/'+products.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                    </a>
                                                </div> */}
                                            </>
                                        :
                                            <>
                                                <div className="front">
                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                        <img src={'/assets2/images/pro3/36.jpg'} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                    </a>
                                                </div>
                                                {/* <div className="back">
                                                    <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/36.jpg")',height: "150px", maxHeight:'150px;', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
                                                        <img src={'/assets2/images/pro3/36.jpg'} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                    </a>
                                                </div> */}
                                            </>
                                        }
                                        <div className="cart-info cart-wrap"> 
                                            <button onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-heart" aria-hidden="true" /></button>
                                        </div>                                         
                                    </div>
                                    <div className="product-detail p-1 br-b-10">
                                        <div className="row m-0">
                                            <Link to={"/single-product/"+products.id} className="col-12">
                                                {/* <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div> */}
                                                <Link to={"/single-product/"+products.id}>
                                                    <h6>{products.product_name}</h6>
                                                </Link>
                                            </Link>
                                            <div className="col-6 m-auto">
                                                <span className="badge badge-secondary p-1 fw-400">
                                                    {products.main_cateogery != null && products.main_cateogery.category_name}
                                                </span>
                                            </div>
                                            <div className="col-6 m-auto">
                                                <span className="badge badge-secondary float-right p-1 fw-400">
                                                    Universal
                                                </span>
                                            </div>
                                            <div className="col-12 my-2">
                                                {getUserType == 'ResellerType' ? 
                                                    <>
                                                        <h6>₹ {products.mrp} /-</h6>
                                                    </>
                                                :
                                                    <>
                                                        <h6><del>₹ {products.mrp} /-</del><span style={{color:'#6e179f'}}> {Math.round(((products.mrp - products.product_price)/products.mrp)*100)}% off</span>&nbsp;₹ {products.product_price} /-</h6>
                                                    </>
                                                }
                                            </div>
                                            <div className="col-7 m-auto">
                                                <div className="rating mt-0">
                                                    <i className="fa fa-star"></i> 
                                                    <i className="fa fa-star"></i> 
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i> 
                                                    <i className="fa fa-star"></i>
                                                </div>
                                            </div>
                                            <div className="col-5 m-auto text-right">
                                                <a href="#" style={{fontSize:'14px'}} onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-heart text-danger" aria-hidden="true" /></a>&nbsp; &nbsp;
                                                <a href="#" style={{fontSize:'14px'}} onClick={() => addTOWhishList(products.id)} title="Add to Wishlist" tabIndex={0}><i className="ti-shopping-cart text-danger" aria-hidden="true" /></a>
                                            </div>
                                        </div>                                                    
                                    </div>
                                </div>
                            </div>
                            </>
                        )) : ''}
                    </div>
                </div>
            </section>
            {/* product section end */}
            <ToastContainer autoClose={1000}/>
        </>
    )
}