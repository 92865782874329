import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import http,{baseUrl, baseUrl2} from "../../../http-common";
import Slider from 'react-slick';
import {Product4, Product5, Product3} from '../../services/slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './style.css';

export default function Content() {
    const [getCategories, setCategories] = useState([]);
    const [getRecentProducts, setRecentProducts] = useState([]);
    const [getProductsByCategory, setProductsBycategory] = useState([]);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    useEffect(() => {
      http.get('/categories').then((Response) => {
        setCategories(Response.data);
        
      })
      .catch((error) => console.log(error));
      http.get('/products/recent/'+0).then((Response) => {
        setRecentProducts(Response.data);        
      })
      .catch((error) => console.log(error));
    }, []);

    return(

        <>
        
         {/* Home Slider */}
        <section className="p-0 gym-slider">
          <div className="slide-1 home-slider">
            <div>
              <div className="home text-right p-right" style={{backgroundColor: '#330f0f'}}>
                <img src="/assets2/images2/homeslider3.jpg" alt="" className="bg-img blur-up lazyload" />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div>
                          <h1>Providing a world to you!
                            Where adventure is never scripted
                          </h1>
                          <h2 style={{color: '#631597'}}>Film Playground</h2>
                          <Link to="/live_shop" className="btn btn-solid btn-gradient">Lets Start</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home text-right p-right" style={{backgroundColor: '#330f0f', textAlign: 'left'}}>
                <img src="/assets2/images2/homeslider2.jpg" alt="" className="bg-img blur-up lazyload" />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div>
                          <h1>Giving Life to Your</h1>
                          <h2 style={{color: '#631597'}}>Occasion</h2>
                          <p>WE MAKE IT HAPPEN</p>
                          <a href="#" className="btn btn-solid btn-gradient">Lets Start</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home text-right p-right" style={{backgroundColor: '#330f0f', textAlign: 'left'}}>
                <img src="/assets2/images2/homeslider3.jpg" alt="" className="bg-img blur-up lazyload" />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div>
                          <h1>Wedding, Birthdays, <br /> Corporate Events...</h1>
                          <h2 style={{color: '#631597'}}>Many More</h2>
                          <p>WE MAKE IT HAPPEN</p>
                          <a href="#" className="btn btn-solid btn-gradient">Lets Start</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home slider end */}

        {/* Paragraph*/}
        <div className="title1 title-gradient  section-t-space">
          <h2 className="title-inner1">Categories</h2>
        </div>
        {/* Paragraph end */}


        {/* Product slider */}
        <section className="p-t-0 section-b-space gym-product ratio_square">
          <div className="container">
            <div className="row partition-cls">
              {getCategories?.map ? getCategories.map((categories:any, i) => 
                <div key={i} className="col-xl-3 col-6">
                  <div className="product-box ">
                    <div className="img-wrapper">
                      <div className="front" style={{}}>
                        <Link to={'/live_shop'}>
                          {categories.category_image == '' && <img src="/assets2/images2/topcollection8.jpg" className="img-fluid blur-up lazyload bg-img" alt="" />}
                          {categories.category_image != '' && <img src={baseUrl2+'/'+categories.category_image} className="img-fluid blur-up lazyload bg-img" alt="" />}                          
                        </Link>
                      </div>
                    </div><br />
                    <div className="product-detail">
                      <Link to={'/live_shop'}>
                        <h6><b>{categories.category_name}</b></h6>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </section>
        {/* Product slider end */}


        {/* Product slider */}
        <div className=" ratio_square bg-dark">
          <div className="section-t-space section-b-space gym-product">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="title1 title-gradient">
                    <h2 style={{color: 'white'}} className="title-inner1">Recent</h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 offset-lg-3">
                      <div className="product-para">
                        <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <section className="blog p-t-0 section-b-space gym-blog ratio3_2">
                    <div className="container">
                      <div className="row">
                      <div className="col-md-12">
                          <Slider {...Product3} className="product-3 no-arrow">
                            {getRecentProducts?.map ? getRecentProducts.map((grp:any,k) => 
                                <div key={k} className="col-md-12 p-1">
                                  <div className="product-box p-0">
                                    <div className="img-wrapper">
                                    {grp.image != '' ? 
                                      <div className="front" style={{backgroundImage:'url("'+baseUrl2+'/'+grp.image+'")', backgroundSize:'cover'}}>
                                        <Link to={'/single-product/'+grp.id}>
                                          <img style={{visibility:'hidden'}} height="180px" width="100%" src={baseUrl2+'/'+grp.image} className="blur-up lazyload bg-img" alt="" />                                          
                                        </Link>
                                      </div>
                                    : 
                                      <div className="front" style={{backgroundImage:'url("/assets2/images2/topcollection5.jpg")', backgroundSize:'cover'}}>
                                        <Link to={'/single-product/'+grp.id}>
                                            <img style={{visibility:'hidden'}} height="180px" width="100%" src="/assets2/images2/topcollection5.jpg" className="blur-up lazyload bg-img" alt="" />
                                        </Link>
                                      </div>
                                    }
                                    </div>
                                    <div className="product-detail p-3">
                                      <Link to={'/single-product/'+grp.id}>
                                        <h6>{grp.product_name}</h6>
                                      </Link>
                                      <h4>  ₹ {grp.product_price} <br/>
                                            <del>₹ {grp.mrp}</del>
                                      </h4>
                                    </div>
                                  </div>
                              </div>
                              )
                              : ''}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </section> 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product slider end */}

        {/* Product slider */}
        <div className=" ratio_square bg-light">
          <div className="section-t-space section-b-space gym-product">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <section className="blog p-t-0 section-b-space gym-blog ratio3_2">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <Slider {...Product3} className="product-3 no-arrow">
                            {getRecentProducts?.map ? getRecentProducts.map((grp:any,k) => 
                                <div key={k} className="col-md-12 p-1">
                                  <div className="product-box p-0">
                                    <div className="img-wrapper">
                                    {grp.image != '' ? 
                                      <div className="front" style={{backgroundImage:'url("'+baseUrl2+'/'+grp.image+'")', backgroundSize:'cover'}}>
                                        <Link to={'/single-product/'+grp.id}>
                                          <img style={{visibility:'hidden'}} height="180px" width="100%" src={baseUrl2+'/'+grp.image} className="blur-up lazyload bg-img" alt="" />                                          
                                        </Link>
                                      </div>
                                    : 
                                      <div className="front" style={{backgroundImage:'url("/assets2/images2/topcollection5.jpg")', backgroundSize:'cover'}}>
                                        <Link to={'/single-product/'+grp.id}>
                                            <img style={{visibility:'hidden'}} height="180px" width="100%" src="/assets2/images2/topcollection5.jpg" className="blur-up lazyload bg-img" alt="" />
                                        </Link>
                                      </div>
                                    }
                                    </div>
                                    <div className="product-detail p-3">
                                      <Link to={'/single-product/'+grp.id}>
                                        <h6>{grp.product_name}</h6>
                                      </Link>
                                      <h4>  ₹ {grp.product_price} <br/>
                                            <del>₹ {grp.mrp}</del>
                                      </h4>
                                    </div>
                                  </div>
                              </div>
                              )
                              : ''}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </section> 
                </div>
                <div className="col-md-4">
                  <div className="title1 title-gradient">
                    <h2 className="title-inner1">Upcoming</h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 offset-lg-3">
                      <div className="product-para">
                        <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product slider end */}

        {/* product slider */}
        <section className="section-b-space bg-dark">
          <div className="container">
          <div className="row multiple-slider">
              {getCategories?.map ? getCategories.map((cat:any, m) =>
                <div key={m} className="col-lg-3 col-sm-6">
                  <div className="theme-card">
                    <h5 className="title-border text-white">{cat.category_name} </h5>
                    <Slider className="offer-slider slide-1">
                      {cat.products?.map ? cat.products.map((grp:any, n:0) => 
                        <div key={n}>
                          <div className="media">
                            <Link to={'/single-product/'+grp.id}>
                            {grp.image != '' ? 
                              <img style={{width: '100%'}} className="img-fluid blur-up lazyload" src={baseUrl2+'/'+grp.image} alt="" />
                            :                               
                              <img style={{width: '100%'}} className="img-fluid blur-up lazyload" src="/assets2/images2/topcollection2.jpg" alt="" />
                            }
                            </Link>
                            <div className="media-body align-self-center">
                              {/* <div className="rating">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                              </div><br /> */}
                              <Link to={'/single-product/'+grp.id}>
                                <h6 className="m-auto text-center text-white">{grp.product_name}</h6>
                              </Link><br />
                              <h5 className="m-auto text-center text-white">₹{grp.product_price} <br/><del> ₹{grp.mrp}</del></h5>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </Slider>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </section>
        {/* product slider end */}

        {/* Tab product */}
        <div className="title1 section-t-space">
          <h2 className="title-inner1">Digital Inivations</h2>
        </div>
        <section className="section-b-space p-t-0 ratio_asos">
          <div className="container">
            <div className="row">
              <div className="col m-auto text-center">
                {/* <Tabs className="theme-tab m-auto text-center" value={value} onChange={handleChange} aria-label="icon label tabs example">
                  <Tab label="RECENTS" />
                  <Tab label="FAVORITES" />
                  <Tab label="NEARBY" />
                </Tabs> */}
                {/* <div className="theme-tab">
                  <ul className="tabs tab-title">
                    <li className="current"><a href="tab-4">New Products</a></li>
                    <li className=""><a href="tab-5">Featured Products</a></li>
                    <li className=""><a href="tab-6">Best Sellers</a></li>
                  </ul>
                  <div className="tab-content-cls">
                    <div id="tab-4" className="tab-content active default">
                      <div className="no-slider row">
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection4.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection3.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection6.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection7.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection10.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection11.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection2.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection9.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>New Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab-5" className="tab-content">
                      <div className="no-slider row">
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection7.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection10.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection11.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection9.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection2.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection4.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection3.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection6.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Featured Products</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab-6" className="tab-content">
                      <div className="no-slider row">
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection10.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection11.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection9.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection2.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection6.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection3.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection4.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                        <div className="product-box">
                          <div className="img-wrapper">
                            <div className="front">
                              <a href="product-page(no-sidebar).html"><img src="/assets2/images2/topcollection7.jpg" className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                            </div>
                          </div>
                          <div className="product-detail">
                            <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                            <a href="product-page(no-sidebar).html">
                              <h6>Best Sellers</h6>
                            </a>
                            <h4>₹500.00</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Tab product end */}

        {/* collection banner */}
        <div className="title1 section-t-space">
          <h2 className="title-inner1">Promos</h2>
        </div>
        <section className="banner-padding banner-furniture ratio2_1 bg-dark pb-4">
          <div className="container-fluid">
            <div className="row partition4">
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="collection-banner p-right text-right">
                    <div className="img-part">
                      <img src="/assets2/images2/topcollection4.jpg" className="img-fluid blur-up lazyload bg-img" />
                    </div>
                    <div className="contain-banner banner-4">
                      <div>
                        <h2>Wedding Invitation</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="collection-banner p-right text-right">
                    <div className="img-part">
                      <img src="/assets2/images2/topcollection10.jpg" className="img-fluid blur-up lazyload bg-img" />
                    </div>
                    <div className="contain-banner banner-4">
                      <div>
                        <h2>Birthday Invitation</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="collection-banner p-right">
                    <div className="img-part">
                      <img src="/assets2/images2/topcollection5.jpg" className="img-fluid blur-up lazyload bg-img" />
                    </div>
                    <div className="contain-banner banner-4">
                      <div>
                        <h2>Engagement Invitation</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="collection-banner p-left">
                    <div className="img-part">
                      <img src="/assets2/images2/topcollection9.jpg" className="img-fluid blur-up lazyload bg-img" />
                    </div>
                    <div className="contain-banner banner-4">
                      <div>
                        <h2>Party Celebrations </h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* collection banner end */}
        
        </>
    );
}