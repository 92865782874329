import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getCookie, setCookie } from "typescript-cookie";
import http from "../../../http-common";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import './style.css';

export default function Login(){

    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState(false);
    let unique_id = getCookie('unique_id');
    const navigate = useNavigate();
    if(unique_id == undefined){
        unique_id= ''
    }

    let email = getCookie('user_email');
    // console.log(email);
    
    // if(getCookie('user_email') == '' || getCookie('user_email') == undefined || getCookie('user_email') == null){
    //     // navigate('/live_shop');
    // }else{
    //     // toast.success('Login Successfully !',{position:toast.POSITION.TOP_RIGHT});
    //         setTimeout(() => {
    //             navigate('/live_shop');
    //         }, 2000); 
    //         setLoading(false);
    // }

    const initialValuesLogin = {
        user_email:'',
        user_pass:'',
        unique_id:unique_id
    };

    const validationSchemaLogin = Yup.object().shape({
        user_email: Yup.string().required("User name is required"),
        user_pass: Yup.string().required()
    })

    const userLogin = (data:any) => {
        console.log(data);
        http.post("/users/login", data).then(response => {
          setLoading(true);
          if(response.data.error == 'error'){
            setError1(true);
            setLoading(false);
          }else if(response.data.id){
            setCookie('user_email', response.data.user_email, { expires: 90})
            toast.success('Login Successfully !',{position:toast.POSITION.TOP_RIGHT});
            setTimeout(() => {
              navigate('/live_shop');
              window.location.reload();
            }, 2000); 
            setLoading(false);
          }else{
            setError1(true);
            setLoading(false);
          }
        }).catch(error => {
          setError1(true);
          console.log(error);
          setLoading(false);
        })
    }

    return(
        <>
            {/*section start*/}
            <section style={{backgroundColor:'#fff'}} className="login-page section-b-space">
                <div className="container bg-img2">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <a href="/main"><h2 className="text-center mb-3" style={{fontSize: '42px'}}>ARKWRX</h2></a>
                            <div className="theme-card py-5 px-4" style={{border:'none',borderRadius:'10px',boxShadow: "0 .1rem 1rem .25rem rgba(0,0,0,.05)"}}>
                            <Formik initialValues={initialValuesLogin}
                              onSubmit={userLogin}
                              validationSchema={validationSchemaLogin}
                              setFieldValue>
                                <Form className="custom-form" style={{float: 'none'}}>  
                                    <div className='text-center mb-10'>
                                        <h4 className='text-dark mb-3 fw-600'>Sign In to ARKWRX</h4>
                                        <h5 className='text-gray-400 fw-600 fs-4'>
                                            New Here?{' '}
                                            <Link to='/Register' className='link-primary fw-bolder'>
                                               Create an Account
                                            </Link>
                                        </h5>
                                    </div>  
                                    <div className="form-group mt-4">
                                        <label className="fw-600" htmlFor="email">Email</label>
                                        <Field type="email" name="user_email" className="form-control" id="user_email" placeholder="Email" required />
                                        <ErrorMessage name='user_email' component="span" className='text-danger'/>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-600" htmlFor="review">Password</label>
                                        <Field type="password" name="user_pass" className="form-control" id="user_pass" placeholder="Enter your password" required />
                                        <ErrorMessage name="user_pass" component="span" className="text-danger" />
                                    </div>
                                    {error1 && <p className='text-center text-danger'>Your username or password is incorrect</p> }
                                    <div className="form-group">
                                       <button style={{width:'100%'}} type="submit" className="btn btn-mine mx-0 mt-3">Login</button>                           
                                    </div>
                                </Form>
                            </Formik>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                        {/* <div className="col-lg-6 right-login">
                            <h3>New Customer</h3>
                            <div className="theme-card authentication-right">
                                <h6 className="title-font">Create A Account</h6>
                                <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be
                                    able to order from our shop. To start shopping click register.</p>
                                <Link to="/register" className="btn btn-solid">Create an Account</Link>                 
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/*Section ends*/}
            <ToastContainer autoClose={1000}/>

        </>
    )
}