import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from 'react-router-scroll-to-top';

//Scroll Top

import Home from './components/landingpage/home/home';
import ContactUS from './components/landingpage/contactus/contact';
import Gallery from './components/landingpage/gallery/gallery';
import OrderList from  './components/landingpage/order_list/order_list';
import Order from  './components/landingpage/order/order';

//Cart Pages
import MainCart from './components/products/main/main';
import SingleProduct from "./components/products/product/view_products";
import Cart from './components/products/cart/cart';
import CheckOut from "./components/products/checkout/checkout";

import OrderListView from "./components/products/order_view/order_list";
import Order_details from "./components/products/order_details/order_details";
import OrderView from "./components/products/order_list/order_list"
import AssetsUpload from "./components/products/assets_upload/assets_upload";
import Main from "./components/products/landing/product_landing";

//Login and Registration
import Login from "./components/products/login/login";
import Register from "./components/products/register/register";

import Profile from "./components/products/profile/profile";

import Paytm from "./components/paytest";
import PageNotFound from "./components/error_page";
import http, { baseUrl } from "./http-common";
import { getCookie } from "typescript-cookie";

//Credit Page
import ResellerCreditPage from "./components/products/reseller_credit_page/reseller_credit_page";

interface AppProps {}
 
const App: React.FunctionComponent<AppProps> = () => {
  const email = getCookie('user_email');
  const [getUserId, SetUserId] = useState(0);
  const [getUserType, setUserType] = useState('');
  const [getUserstatus, setUserStatus] = useState(false);
  useEffect(()=>{
    http.get('/users/byUserEmail/'+email).then(Response => {
        SetUserId(Response.data.id);
        setUserType(Response.data.user_type);
    })
  },[])

  return ( 
      <>
        {/*<leftnav/>*/}
        <Router>
          <ScrollToTop>
            <Routes>

              {/* Non authenticated route */}
              <Route path="/" element={<Home />} />              
              <Route path="/contact_us" element={<ContactUS />} />              
              <Route path="/gallery_list" element={<Gallery />} />
              <Route path="/live_shop/" element={<MainCart />} />          
              <Route path="/single-product/:productId" element={<SingleProduct />} />      
              <Route path="/cart" element={<Cart />} />      
              <Route path="/check-out" element={<CheckOut />} />  
              <Route path="/main" element={<Main />}/>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>
              {/* <Route path="/orders_list1" element={<OrderList />} />               */}
               
              {/* authenticated route */}
              {getUserId != 0 &&
              <>
                <Route path="/order_details" element={<Order />} />              
                <Route path="/order_view/:order_id" element={<OrderListView />} />      
                <Route path="/order_details_view/:order_id" element={<Order_details />}/>      
                <Route path="/assets_upload/:order_id" element={<AssetsUpload />}/>      
                <Route path="/orders_list" element={<OrderView />}></Route>
                {getUserType == 'ResellerType' &&
                  <Route path="/credit_page" element={<ResellerCreditPage />}></Route>
                }
              </> 
              }
              
              <Route path="/profile" element={<Profile />}></Route>

              {/* <Route path="/paytm" element={<Paytm />}></Route> */}
              <Route path="/404" element={<PageNotFound />}></Route>
              <Route path="*" element={<Main />}></Route>
              
            </Routes>
          </ScrollToTop>
        </Router>
      </>
   );
}
 
export default App;