import { Link } from "react-router-dom";

export default function BreadCrumb(){
    return(
        <>
            {/* breadcrumb start */}
            <div className="breadcrumb-section py-0">
            <div className="container">
                <div className="row p-3">
                <div className="col-12 m-auto">
                    <div className="page-title text-dark">
                        <h6 className="m-0 p-0">
                            <Link to="/home" className="fas fa-home text-dark"></Link> 
                            &nbsp; <i style={{fontSize:'12px'}} className="fas fa-chevron-right"></i>
                            &nbsp; <Link to="/live_shop" className="fas text-dark">Shop</Link>
                            &nbsp; <i style={{fontSize:'12px'}} className="fas fa-chevron-right"></i>
                            &nbsp; <a className="fas text-dark">All</a>
                        </h6>
                    </div>
                </div>
                {/* <div className="col-6">
                    <nav aria-label="breadcrumb" className="theme-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">products</li>
                    </ol>
                    </nav>
                </div> */}
                </div>
            </div>
            </div>
            {/* breadcrumb end */}

        </>
    )
}