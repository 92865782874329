import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl, baseUrl2 } from "../../../http-common";
import "./admin.css";


const MyFavouries: React.FC = () => {
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const [getProducts, setData] = useState([] as any);

    useEffect(() => {
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
            // console.log(Response);
            http.get("/favourite_videos/byUserId/" + Response.data.id + '/' + 0)
            .then((res: any) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            })            
        })        
    }, []);

    function removefromWhishList(p_id:any){
        let data:any = {};
        data.product_id = p_id;
        if(getUserId == 0){
            toast.info('Please register/login to add favourites!', { position: toast.POSITION.TOP_RIGHT });
        }else{            
            http.delete('/favourite_videos/by_id/'+p_id).then((Response) => {
                toast.success('Video successfully removed from favourites!', { position: toast.POSITION.TOP_RIGHT });
                let pdata = getProducts.filter((data:any) => data.id != p_id);
                setData(pdata);
            }).catch(err => console.log(err));
        }
        
    }

    return(
        <div className='container-fluid'>
            <div className='row'>
                {getProducts?.map ? getProducts.map((order:any, i:0) =>
                    <div key={i} className='col-sm-6 col-md-2 col-xl-4 p-1'>
                        <div className="product-box">
                            <div className="img-wrapper">                        
                                {order.product.video && order.product.video != '' ?
                                    <>
                                        <iframe style={{ width: "100%",height: "100%", minHeight: "180px", maxHeight: "450px" }} src={''+baseUrl2+'/'+order.product.video+''} allowFullScreen={true} />
                                    </>
                                    
                                :
                                order.product.image && order.product.image != '' ?
                                    <>
                                        <div className="front">
                                            <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url('+baseUrl2+'/'+order.product.image+')', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "180px", maxHeight: "450px"}}>
                                                <img src={''+baseUrl2+'/'+order.product.image+''} className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                            </a>
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className="front">
                                                <a href="#" className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("/assets2/images/pro3/35.jpg")', backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block',height:'100%', minHeight: "180px", maxHeight: "450px"}}>
                                                    <img src="/assets2/images/pro3/35.jpg" className="img-fluid blur-up lazyload bg-img" alt="" style={{display: 'none'}} />
                                                </a>
                                        </div>
                                    </>
                                }
                                <div className="cart-info cart-wrap"> 
                                    <button onClick={() => removefromWhishList(order.id)} title="Remove from Wishlist" tabIndex={0}><i className="ti-heart" aria-hidden="true" /></button>
                                </div>
                            </div>
                            <div className="product-detail">
                                <div className="rating"><i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /> <i className="fa fa-star" /></div>
                                <Link to={"/single-product/" + order.product.id}>
                                    <h6>{order.product.product_name}</h6>
                                </Link>
                                {

                                }
                                <h5><del>₹{order.product.mrp}</del><span style={{ color: '#6e179f' }}> {Math.round(((order.product.mrp - order.product.product_price) / order.product.mrp) * 100)}% off</span></h5>
                                <h4>₹{order.product.product_price}</h4>
                            </div>
                            <h6 className='card-title'>{order.product.product_name}</h6>
                        </div>
                    </div>
                ) : ''}
            </div>
        </div>
    );
};

export default MyFavouries;