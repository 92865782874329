import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getCookie, setCookie } from 'typescript-cookie';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import http, {baseUrl} from "../../../http-common";



export default function HeadStart(){
    //console.log(getCookie('product_id'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [sucess, setSuccess] = useState(false);
    let unique_id = getCookie('unique_id');    

    let email = getCookie('user_email');
    function logOut(){
        setCookie('user_email', '', { expires: 90});
        toast.success('Logout Successfully !',{position:toast.POSITION.TOP_RIGHT});
        window.location.reload();
        email = '';
    }
    return(
        <>
            {/* header start */}
            <header>
            <div className="mobile-fix-option" />
                <div className="container py-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="main-menu">
                                <div className="menu-left">
                                    <div className="navbar">                                
                                        <div id="mySidenav" className="sidenav">
                                            <a href="javascript:void(0)" className="sidebar-overlay" />
                                        </div>
                                    </div>
                                    <div className="brand-logo">
                                        <Link to="/main">
                                            <img src="/assets/images/logo.jpg" style={{height:'40px'}} className="img-fluid blur-up lazyload" alt="" />
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <form className="form_search ajax-search the-basics" role="form" method="post" >
                                        <input type="search" placeholder="Search" name="search_keyword"
                                            className=" nav-search nav-search-field typeahead" aria-expanded="true" />
                                        <button type="button" name="nav-submit-button" className="btn-search" >
                                            <i className="ti-search" style={{color:"#fff"}}></i>
                                        </button>
                                    </form>
                                </div>
                                <div className="menu-right pull-right">
                                    <div>
                                    </div>
                                    <div>
                                    <div className="icon-nav">
                                        <ul className="d-flex">                                    
                                            <li className="onhover-div mobile-cart right-85">
                                                {email == '' || email == undefined || email == null ? 
                                                    <Link to="/login">
                                                        <img src="/assets2/images/icon/users.png" className="img-fluid blur-up lazyload user_icon" alt="" />                                            
                                                    </Link>
                                                :
                                                    <Link to="/profile">
                                                        <img src="/assets2/images/icon/users.png" className="img-fluid blur-up lazyload user_icon" alt="" />                                            
                                                    </Link> 
                                                }
                                            </li>
                                            <li className="onhover-div mobile-cart" style={{right:'10%'}}>
                                                <Link to="/cart">
                                                    <img style={{padding:'8px'}} src="/assets2/images/icon/shopping-cart.png" className="img-fluid blur-up lazyload" alt="" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* header end */}
            <ToastContainer autoClose={1000}/>
        </>
    )
}