import { Link } from "react-router-dom"

export default function gallery_content(){
    return(
        <>
            {/*wrapper*/}
            <div id="wrapper" className="single-page-wrap">
            {/* Content*/}
            <div className="content">
                {/* section*/}
                <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                <div className="bg par-elem" data-bg="/assets/images/b2.jpg" data-scrollax="properties: { translateY: '30%' }" />
                <div className="overlay" />
                <div className="pattern-bg" />
                <div className="container">
                    <div className="section-title">
                    <h2>Our <span>Professionals</span> <br /> At your service</h2>
                    <p> book your videos from wide variety of selection. </p>
                    <div className="horizonral-subtitle"><span>Category</span></div>
                    </div>
                    <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down" /></a>
                </div>
                </section>
                {/* section end*/}
                {/* section*/}
                <section data-scrollax-parent="true" id="sec1">
                <div className="section-subtitle" data-scrollax="properties: { translateY: '150px' }">Category<span>//</span>
                </div>
                <div className="container-fluid">
                    {/* widget-wrap */}
                    <div className="col-md-3">
                    <div className="widget-wrap fl-wrap">
                        <h4 className="widget-title"> Categories</h4>
                        <div className="widget-container fl-wrap">                            
                            <div className="gallery-filters">
                                <ul className="cat-item">
                                    <li style={{zIndex: '9999!important'}}><Link to="#" className="gallery-filter  gallery-filter-active" data-filter="*">All</Link>
                                    </li>
                                    <li style={{zIndex: '9999!important'}}><a href="#" className="gallery-filter" data-filter=".engagment">Engagment</a></li>
                                    <li style={{zIndex: '9999!important'}}><a href="#" className="gallery-filter" data-filter=".wedding">Weddings</a></li>
                                    <li style={{zIndex: '9999!important'}}><a href="#" className="gallery-filter" data-filter=".couple">Birthdays</a></li>
                                    <li style={{zIndex: '9999!important'}}><a href="#" className="gallery-filter" data-filter=".baby">Festivals</a></li>
                                    <li style={{zIndex: '9999!important'}}><a href="#" className="gallery-filter" data-filter=".branding">Personal Events</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* widget-wrap end  */}
                    {/* portfolio start */}
                    <div className="col-md-9">
                    <div className="gallery-items spad  vis-box-det white-det three-column">
                        {/* gallery-item*/}
                        <div className="gallery-item web engagment">
                        <div className="grid-item-holder">
                            <img src="/assets/images/eng.jpg" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Engagement
                                    Invitation Video</a>
                                </h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹2400</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item*/}
                        <div className="gallery-item web wedding">
                        <div className="grid-item-holder">
                            <img src="/assets/images/wed.jpg" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Wedding Invitation
                                    Video</a>
                                </h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹2400</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item end*/}
                        {/* gallery-item*/}
                        <div className="gallery-item web couple">
                        <div className="grid-item-holder">
                            <img src="/assets/images/bir.jpg" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Birthday Video</a>
                                </h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹4400</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item end*/}
                        {/* gallery-item*/}
                        <div className="gallery-item web baby">
                        <div className="grid-item-holder">
                            <img src="/assets/images/sa.jpg" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Sankranthi
                                    Festival</a>
                                </h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹3500</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item end*/}
                        {/* gallery-item*/}
                        <div className="gallery-item web baby">
                        <div className="grid-item-holder">
                            <img src="/assets/images/ug.png" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Ugadi Festival</a></h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹3300</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item end*/}
                        {/* gallery-item*/}
                        <div className="gallery-item web branding">
                        <div className="grid-item-holder">
                            <img src="/assets/images/per.jpg" alt="" />
                            <div className="box-item hd-box">
                            <div className=" fl-wrap full-height">
                                <div className="hd-box-wrap">
                                <h2><a href="#">Personal
                                    Event Video</a>
                                </h2>
                                <p style={{color: '#000', fontSize: '16px'}}>₹6200</p>
                                <a href="#" style={{border: '1px solid darkgray', padding: '3px', backgroundColor: 'royalblue', color: 'oldlace'}}>Book
                                    Now
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* gallery-item end*/}
                    </div>
                    {/* portfolio end */}
                    </div>
                </div>
                <div className="sec-lines" />
                </section>
                {/* section*/}
                {/* section*/}
                <section className="dark-bg2 small-padding order-wrap">
                <div className="container">
                    <div className="row">
                    <div className="col-md-8">
                        <h3>Want us to capture your stories ?</h3>
                    </div>
                    <div className="col-md-4"><Link to="/contact_us" className="btn flat-btn color-btn">Get In Touch</Link> </div>
                    </div>
                </div>
                </section>
                {/* section end*/}
            </div>
            {/* Content end */}
            </div>
            {/*   content end */}

        </>
    )
}