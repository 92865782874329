import { Link } from "react-router-dom";
export default function contact() {    
    return (
        <>
                {/* Content*/}
                <div className="content">
                    <div className="single-page-decor" />
                    <div className="single-page-fixed-row">
                    <div className="scroll-down-wrap">
                        <div className="mousey">
                        <div className="scroller" />
                        </div>
                        <span>Scroll Down</span>
                    </div>
                    <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left" /> <span>Back to home</span></Link>
                    </div>
                    {/* section*/}
                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                    <div className="bg par-elem" data-bg="/assets/images/bg/1.jpg" data-scrollax="properties: { translateY: '30%' }" />
                    <div className="overlay" />
                    <div className="pattern-bg" />
                    <div className="container">
                        <div className="section-title">
                        <h2>My <span>Contact </span>   Page</h2>
                        <p>Loved Our Work !!!. <br />  We are open 24x7 and in just a click away</p>
                        <div className="horizonral-subtitle"><span>Hire Us</span></div>
                        </div>
                    </div>
                    <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down" /></a>
                    </section>
                    {/* section end*/}
                    {/* section end*/}
                    <section data-scrollax-parent="true" id="sec1">
                    <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }">Get in Touch<span>//</span></div>
                    <div className="container">
                        {/* contact details */}
                        <div className="fl-wrap   mar-bottom">
                        <div className="row">
                            <div className="col-md-3">
                            <div className="pr-title fl-wrap">
                                <h3>Contact  Details</h3>
                                <span>Dail Us / Write Us</span>
                            </div>
                            </div>
                            <div className="col-md-9">
                            {/* features-box-container */}
                            <div className="features-box-container single-serv fl-wrap">
                                <div className="row">
                                {/*features-box */}
                                <div className="features-box col-md-4">
                                    <div className="time-line-icon">
                                    <i className="fal fa-mobile-android" />
                                    </div>
                                    <h3>01. Phone</h3>
                                    <a href="#">+91-8008848222</a>
                                    <a href="#">+91-7032821402</a>
                                </div>
                                {/* features-box end  */}
                                {/*features-box */}
                                <div className="features-box col-md-4">
                                    <div className="time-line-icon">
                                    <i className="fal fa-compass" />
                                    </div>
                                    <h3>02. Location</h3>
                                    <a href="#">My Home Avatar Hyderabad</a>
                                </div>
                                {/* features-box end  */}
                                {/*features-box */}
                                <div className="features-box col-md-4">
                                    <div className="time-line-icon">
                                    <i className="fal fa-envelope-open" />
                                    </div>
                                    <h3>03. Email</h3>
                                    <a href="#">hello@Arkwrx.com</a>
                                </div>
                                {/* features-box end  */}
                                </div>
                            </div>
                            {/* features-box-container end  */}
                            </div>
                        </div>
                        </div>
                        <div className="fl-wrap mar-bottom">
                        <div className="row">
                            <div className="col-md-3">
                            <div className="pr-title fl-wrap">
                                <h3>Get In Touch</h3>
                                <span>We are just a click away</span>
                            </div>
                            </div>
                            <div className="col-md-7">
                            <div id="contact-form">
                                <form className="custom-form" method="post" id="contactform">
                                <fieldset>
                                    <div className="row">
                                    <div className="col-md-12">
                                        <select name="event" id="event" data-placeholder="event" className="chosen-select sel-dec">
                                        {/*                                                    <option value="-1">-- Select Event --</option>*/}
                                        <option value="wedding">Wedding</option>
                                        <option value="engagement">Engagement</option>
                                        <option value="couple_shoot">Couple Shoot</option>
                                        <option value="baby_shoot">Baby Shoot</option>
                                        <option value="birthday_event">Birthday Event</option>
                                        <option value="commercial_branding">Commercial &amp; Branding</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className="clearfix" />
                                    <button className="btn float-btn flat-btn color-btn" id="submit">Request Quote</button>
                                </fieldset>
                                </form>
                            </div>
                            {/* contact form  end*/}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="bg-parallax-module" data-position-top={70} data-position-left={20} data-scrollax="properties: { translateY: '-250px' }" />
                    <div className="bg-parallax-module" data-position-top={40} data-position-left={70} data-scrollax="properties: { translateY: '150px' }" />
                    <div className="bg-parallax-module" data-position-top={80} data-position-left={80} data-scrollax="properties: { translateY: '350px' }" />
                    <div className="bg-parallax-module" data-position-top={95} data-position-left={40} data-scrollax="properties: { translateY: '-550px' }" />
                    <div className="sec-lines" />
                    </section>
                    {/* section end*/}
                    {/* section*/}
                    <section className="dark-bg2 small-padding order-wrap">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-8">
                            <h3>Find me on social networks : </h3>
                        </div>
                        <div className="col-md-4">
                            <ul>
                            <li><Link to="https://www.facebook.com/Arkwrx" target="_blank"><i className="fab fa-facebook-f" /></Link></li>
                            <li><Link to="https://www.instagram.com/Arkwrx/" target="_blank"><i className="fab fa-instagram" /></Link></li>
                            <li><Link to="https://www.google.com/search?q=Arkwrx&oq=Arkwrx" target="_blank"><i className="fab fa-google" /></Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </section>
                    {/* section end*/}
                </div>
                {/* Content end */}

        </>
    )
}