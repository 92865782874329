import DataTable, { TableColumn } from 'react-data-table-component';
import Card from "@material-ui/core/Card";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useState, useEffect } from 'react';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl,baseUrl2 } from "../../../http-common";
import "./admin.css";
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Countdown from "react-countdown";
import { width } from '@mui/system';

const sortIcon = <ArrowDownward />;
// Random component
const Completionist = () => <span>You are good to go!</span>;
// Renderer callback with condition

function pad(num:any, size:any) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

type DataRow = {
    title: string;
    year: string;
    cart_products_count:string;
    payment_status:string;
    payment_type:string;
    status:string;
    createdAt:string;
    order_total:string;
    id:string;
    cart_products:[];
    index:number;
};
const columns: TableColumn<DataRow>[] = [
    {
        name: '#',
        cell: (row, index) => <>{index + 1}</>,
        // sortable:true
        width: '75px',
      },
    {
        name: <>Date<br/> Order Id</>,
        cell: (row) => <>{moment(row.createdAt).format('MMMM Do, YYYY')} <br/> OR {pad(row.id, 5)}</>,
        // sortable:true
        width: '150px'
    },
    {
        name: <>Products</>,
        cell: (row) => 
            <div className='row'>
                {row.cart_products.map( (cartProducts:any, k) => 
                    <>
                        {cartProducts.products.map((products:any) => 
                            <>  
                                    {k == 0 &&
                                        <div className='col-4 m-auto'>
                                            {products.image != '' ?
                                                <>
                                                    <img width={"60px"} src={''+baseUrl2+'/'+products.image+''} className="rounded" alt=""/>                                                    
                                                </>
                                            :
                                                <>
                                                    <img height={"60px"} src="/assets2/images/pro3/35.jpg" className="rounded" alt=""/>
                                                </>
                                            }                          
                                        </div>
                                    }
                            </>    
                        )}
                    </>
                )}
                <div className='col-8 m-auto p-2'>
                    {row.cart_products.map( (cartProducts:any, j) => 
                        <span key={j}>
                            {cartProducts.products.map((products:any, x:0) => 
                                <span key={x}>
                                    {j==0 && 
                                        <>
                                            {j+1}. {products.product_name} 
                                        </>
                                    }
                                </span>    
                            )}
                        </span>
                    )}
                    &nbsp; {row.cart_products && row.cart_products.length > 1 && ', +'+(row.cart_products.length-1)}
                </div>
            </div>,
        width:'300px'
    },
    {
        name: <>CUSTOMER</>,
        cell: (row:any) => <>
            <div style={{display:'grid'}}>
                <div className='d-flex align-items-center'>
                    {/*begin:: Avatar */}
                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                        <Link to={'/order_view/' + row.id}>
                        <div className='symbol-label'>
                            {row.created_user != null ? (
                            <>
                                {row.created_user.user_image != '' ? (
                                <>
                                    <img
                                    src={
                                        baseUrl +
                                        '/' +
                                        row.created_user.user_image
                                    }
                                    style={{width:'45px', height:'45px'}}
                                    className='rounded-circle'
                                    />
                                </>
                                ) : (
                                <img
                                    src='/assets2/images2/blank.png'
                                    style={{width:'45px', height:'45px'}}
                                    className='rounded-circle'
                                />
                                )}
                            </>
                            ) : (
                            <img
                                src='/assets2/images2/blank.png'
                                style={{width:'45px', height:'45px'}}
                                className='rounded-circle'
                            />
                            )}
                        </div>
                        </Link>
                    </div>
                    {/*end::Avatar*/}&nbsp;&nbsp;
                    <div className='ms-5'>
                        {/*begin::Title*/}
                        <Link
                        to={'/order_view/' + row.id}
                        className='text-primary-800 text-hover-primary fs-5 fw-bolder'
                        >
                        {row.created_user != null
                            ? row.created_user.user_name
                            : ''}
                        </Link>
                        {/*end::Title*/}
                    </div>
                </div>
            </div>
        </>
    },   
    {
        name: <>Amount <br/>Payment Status</>,
        cell: (row:any) => <>₹ {row.order_total}.00 /-<br/>{row.payment_status}<br/>{row.payment_type}</>,
        width:'150px'
    },
    {
        name: <>Doc Status</>,
        cell: (row:any) => <div style={{display:'grid'}} className="py-2">
            {/*begin::Badges*/}
            {row.status == 'Ordered' && (
                <>
                <div className='badge badge-secondary p-1'>Doc</div><br/>
                <div className='badge badge-secondary p-1'>Media</div>
                </>
            )}
            {row.status == 'Accets Uploaded' && (
                <>
                <div className='badge badge-success p-1'>Doc</div><br/>
                <div className='badge badge-secondary p-1'>Media</div>
                </>
            )}
            {row.status == 'Processing' && (
                <>
                <div className='badge badge-success p-1'>Doc</div><br/>
                <div className='badge badge-secondary p-1'>Media</div>
                </>
            )}
            {row.status == 'Completed' && (
                <>
                <div className='badge badge-success p-1'>Doc</div><br/>
                <div className='badge badge-success p-1'>Media</div>
                </>
            )}
            {row.status == 'Cancelled' && (
                <>
                <div className='badge badge-secondary p-1'>Doc</div><br/>
                <div className='badge badge-secondary p-1'>Media</div>
                </>
            )}
            {/*end::Badges*/}
        </div>,
        width:'150px'
    },
    {
        name: <>Order Status <br/>Remaining time to <br/> Uploas Assets</>,
        cell: (row) => <div style={{display:'grid'}}>
            {row.status == 'Ordered' && (
                <div className='badge badge-secondary p-1'>Pending</div>
            )}
            {row.status == 'Accets Uploaded' && (
                <div className='badge badge-warning p-1'>
                Accets Uploaded
                </div>
            )}
            {row.status == 'Processing' && (
                <div className='badge badge-info p-1'>Processing</div>
            )}
            {row.status == 'Completed' && (
                <div className='badge badge-success p-1'>Completed</div>
            )}
            {row.status == 'Rejected' && (
                <div className='badge badge-warning p-1'>Rejected</div>
            )}
            {row.status == 'Accepted' && (
                <div className='badge badge-success p-1'>Accepted</div>
            )}
            {row.status == 'Cancelled' && (
                <div className='badge badge-danger p-1'>Cancelled</div>
            )}
            {row.status == 'Ordered' &&
                <span className='text-center font-weight-bold text-danger'>                
                    {/* {new Date().getTime() - new Date(row.createdAt).getTime()}<br/> */}
                    <Countdown date={Date.now() + (86400000 - (new Date().getTime() - new Date(row.createdAt).getTime()))} />
                </span>
            }
        </div>,
        width:"200px",
    },
    {
        name: "Actions",
        cell: (row) => <Link to={"/order_view/"+row.id} className="btn btn-light btn-sm rounded" style={{backgroundColor:'#e7eaec', color:'#777'}}><i className="fas fa-eye"></i></Link>,        
    }
];
function handleAction(e:any, id:any){
    console.log(e);
    console.log(id);
}

const MyComponent: React.FC = () => {
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const [getOrdersData, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
            // console.log(Response);
            http.get("/order_details/getByUserID/"+Response.data.id)
            .then((res: any) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("error", err);
            })
        })
        
    }, []);

    return(
        <>
        <DataTable
        //   title="Orders"
          columns={columns}
          data={getOrdersData}
          sortIcon={sortIcon}
          pagination
          fixedHeader={true}
          responsive={true}
          striped={true}
          highlightOnHover={true}
        //   theme={'dark'}
          //   selectableRows
        />
        </>
    );
};

export default MyComponent;