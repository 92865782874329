import { Helmet } from "react-helmet";
// import '../css/bootstrap.scss';
import '../css/color1.scss';

export default function cart_header(){
    return(
        <>
            <Helmet>            
                {/*Google font*/}
                <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900" rel="stylesheet" />
                {/* Icons */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/fontawesome.css" />
                {/* <!-- Dropzone css--> */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/dropzone.css"></link>
                {/*Slick slider css*/}
                <link rel="stylesheet" type="text/css" href="/assets2/css/slick.css" />
                <link rel="stylesheet" type="text/css" href="/assets2/css/slick-theme.css" />
                <link rel="stylesheet" type="text/css" href="/product/content.css" />
                {/* Animate icon */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/animate.css" />
                {/* Price range icon */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/price-range.css" />
                {/* Themify icon */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/themify-icons.css" />
                {/* <!-- Datatables css--> */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/datatables.css"></link>
                {/* Bootstrap css */}
                <link rel="stylesheet" type="text/css" href="/assets2/css/bootstrap.css" />

                
                
            </Helmet>
        </>
    )
}