import Header from "../headers/header";
import HeadStart from "../headers/head_start";
import ProductFooter from "../footer/footer";
import Content from "./content";
import BreadCrumbs from "../headers/breadcrumb";

export default function OrderList(props:any) {

    return(
        <>
        <div id="shoping-Pages">
            <Header />
            <HeadStart />
            <BreadCrumbs/>
            <Content />
            <ProductFooter />
        </div>
        </>

    );

}