import Loader from '../loader';
import LeftNav from '../navs/left_nav';
import MainNav from '../navs/main_nav';
import MainContent from './main_content';
import Footer from '../footer/footer';
import Header from '../header/header';

function home(){
    return(
           <>
              <div id='LandingPage'>
                <Loader/>
                <Header/>                
                <div id="main">
                    <div id="wrapper">
                      <LeftNav/>
                      <MainNav/>
                      <MainContent/>
                      <Footer/>
                    </div>
                </div>
              </div>
           </>
    )
}

export default home;
