import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookie } from "typescript-cookie";
import http, { baseUrl } from "../../../http-common";
import CreditDataTable from "./credit_datatable";

//Payment Gateway
function isDate(val: any) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
}

function isObj(val: any) {
    return typeof val === 'object'
}

function stringifyValue(val: any) {
    if (isObj(val) && !isDate(val)) {
        return JSON.stringify(val)
    } else {
        return val
    }
}

function buildForm(details:any) {
    console.log(details);
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action',details.action)

    Object.keys(details.params).forEach(key => {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', stringifyValue(details.params[key]))
        form.appendChild(input)
    })

    return form
}

function post(details: { action: any; params: any, body_rec:any }) {
    const form = buildForm(details)
    console.log(form);
    document.body.appendChild(form);
    form.submit()
    form.remove()
}

//PAyment Gateway close

export default function ResellerContent(){
    let email = getCookie('user_email');
    const [getUserData, setUserData] = useState([] as any);
    const navigate = useNavigate();
    const [GetCreditDetails, SetCreditDetails] = useState([]);

    const [getCreditTableData, setCreditTableData] = useState([] as any);
    const [getreseller_credit_id, setreseller_credit_id] = useState(0);
    const [getCreditData, setCreditData] = useState(0);
    const [getOldActualAmount, setOldActualAmount] = useState(0);
    const [getCreditLimitStatus, setCreditLimitStatus] = useState(false);
    
    let date_now = Date.now();

    useEffect(() => {
        if(!getCookie('user_email') || email == ''){
            navigate('/login');
        }else{
            http.get('/users/byUserEmail/' + email).then((Response:any) => {
                setUserData(Response.data);
                //Getting All Transaction And Credit Amount
                http.get('/cart/get_creditdetails/'+Response.data.id).then(Response => {
                    setCreditTableData(Response.data);
                    console.log(Response.data);
                    setreseller_credit_id(Response.data.credit_data.id);
                    if(Response.data.due_data.credit_amount != null){
                       setCreditData(Response.data.due_data.credit_amount);
                    }
                    if(Response.data.due_data.actual_amount != null){
                       setOldActualAmount(Response.data.due_data.actual_amount);
                    }
                    if(date_now < Response.data.credit_data.due_date){
                        setCreditLimitStatus(true);
                    }
                })
                .catch(error => console.log('user credit error : '+error))

            }).catch((err:any) => console.log(err))   
        }
    }, [])

    const getData=(data: { amount: number; email: any, phone: any, data:any })=>
    {   
        return fetch(``+baseUrl+`/paytm_payments/credit_payment`,{
            method:"POST",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json"
            },
            body:JSON.stringify(data)
        }).then(response=>response.json()).catch(err=>console.log(err))
    }

    function pay_bill(){
        let data = {payment_status:'',user_id:'', payment_id:''};
        data.payment_status = 'Pending';
        data.user_id = getUserData.id;
        data.payment_id = getCreditTableData.credit_data.id;
        console.log(data);
        getData({amount:Number(getCreditData),email:email, phone:getUserData.phone, data:data}).then(response=>{            
            console.log(response);
            var information={
                action:"https://securegw-stage.paytm.in/theia/processTransaction",
                params:response,
                body_rec:data
            }
            post(information);
        })
    }
    return(
        <>
            <section className="container-fluid mb-4">
                <div className="container bg-white border rounded p-0">
                    <div className="row p-4">
                        <div className="col-sm-2 text-center">
                            {!getUserData.user_image && getUserData.user_image === "" ? 
                              <img src={"/assets/images/logo.jpg"} style={{width:'100%', maxWidth:'100px'}} alt="Profile Image" className="rounded text-center border p-1" />
                             :
                              <img src={baseUrl+"/"+getUserData.user_image} style={{width:'100%', maxWidth:'100px'}} alt="Profile Image" className="rounded text-center" />
                            }
                        </div>
                        <div className="col-sm-10">
                            <h4 className="fw-700">{getUserData.user_name && getUserData.user_name}</h4>
                            <div className="row">
                                <div className="col-sm-2">
                                    <h6><i className="fas fa-user"></i> &nbsp; {getUserData.user_type && <>{getUserData.user_type == "ResellerType" ? 'Reseller' : "User"}</>}</h6>
                                </div>
                                <div className="col-sm-2">
                                    <h6><i className="fas fa-phone"></i> &nbsp; {getUserData.phone && getUserData.phone}</h6>
                                </div>
                                <div className="col-sm-3">
                                    <h6><i className="fas fa-at"></i> &nbsp; {getUserData.user_email && getUserData.user_email}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 border rounded">
                                    <h5 className="d-flex p-3 mb-0"><i className="fas fa-credit-card text-center my-auto"></i> &nbsp; <h4 style={{fontSize:'1.6rem'}} className="mb-0 fw-600">&nbsp; ₹ {getCreditData}.00/-</h4></h5>
                                    {getCreditTableData.length !=0 && date_now > getCreditTableData.credit_data.due_date ?
                                        <>
                                            <h6 className="px-3 fw-500 mb-0 pb-3">Used Credit <button onClick={() => pay_bill()} className="btn btn-sm btn-primary float-right">Pay Now</button></h6>
                                            <p className="fw-600 px-3">Due Date - {getCreditTableData.length !=0 && <>{getCreditTableData.credit_data.due_date && moment(getCreditTableData.credit_data.due_date).format('MMMM Do, YYYY')}</>}</p>
                                        </>
                                    :
                                        <h6 className="px-3 fw-500 mb-0 pb-3">Used Credit</h6>
                                    }
                                </div>
                                <div className="col-md-4 border rounded">
                                    <h5 className="d-flex p-3 mb-0"><i className="fas fa-credit-card text-center my-auto"></i> &nbsp; <h4 style={{fontSize:'1.6rem'}} className="mb-0 fw-600">&nbsp; ₹ {getUserData.user_credit && Number(getUserData.user_credit) - Number(getCreditData)}.00/-</h4></h5>
                                    <h6 className="px-3 fw-500">Reamaining Credit</h6>
                                </div>
                                <div className="col-md-4 border rounded">
                                    <h5 className="d-flex p-3 mb-0"><i className="fas fa-credit-card text-center my-auto"></i> &nbsp; <h4 style={{fontSize:'1.6rem'}} className="mb-0 fw-600">&nbsp; ₹ {getUserData.user_credit && getUserData.user_credit}.00/-</h4></h5>
                                    <h6 className="px-3 fw-500">Total Credit</h6>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <hr/>
                    <div className="row p-4">
                        <div className="col-md-12">
                            <h5 className="fw-700 pl-3">Credit Details</h5>
                        </div>
                        <div className="col-md-12">
                            <CreditDataTable/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}