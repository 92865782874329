import {useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getCookie } from 'typescript-cookie';
import http, { baseUrl } from "../../../http-common";
import './style.css'

export default function Content(){
    const email = getCookie('user_email');
    const [getUserId, SetUserId] = useState(0);
    const params = useParams();
    const getCartProductID = params.order_id;
    const [getVersonData, setVersonData] = useState([]);
    const [getVersonComments, setVersonComments] = useState([]);
    const [getCartProduct, setCartProduct] = useState([] as any);
    const [getUrl, setUrl] = useState('');
    const [getLanguage, setLanguage] = useState('');
    const [getVerson, setVerson] = useState('');

    useEffect(()=>{
        http.get('/users/byUserEmail/'+email).then(Response => {
            SetUserId(Response.data.id);
        })
        http.get('/product_verson/byCartProductID/'+getCartProductID).then(Response => {
            setVersonData(Response.data);
            // console.log(Response.data);
            let language_id:any = 0
            Response.data.map((data:any, i:any) => {
                i = 0;
                {i == 0 && setUrl(data.assets_name)}
                {i == 0 && setVerson(data.version_number)}
                {i == 0 && (language_id = data.language_id)}
                i = Number(i)+1;
            })
            http.get('/cartproduct/cartproductId/'+getCartProductID).then(Response => {
                setCartProduct(Response.data);
                console.log(Response.data);
                Response.data.cart_product_languages.map((data:any, j:any) => {
                    j = 0;
                    {language_id == data.language.id && setLanguage(data.language.language_name)}
                    j = Number(j)+1;
                })
            }).catch(error => console.log(error));
        }).catch(error => {
            console.log(error);
        });
        if(getVerson == ''){

        }else{
            http.get('/verson_comments/byVersonID/'+getVerson).then(Response => {
                setVersonComments(Response.data);
            })
        }        
    },[getVerson])
    function submitComment(getId:any){
        let aid:any = document.getElementById(getId);
        // console.log(aid.value);
        if(aid == ''){

        }else{
            // console.log(aid.value);
            const data = {
                user_id : getUserId,
                admin_id:0,
                comment : aid.value,
                version_id : getVerson,
                user_type : 'user',
                created_by : getUserId
            }
            http.post('/verson_comments', data).then(response => {
                setVersonComments(response.data);
                aid.value= '';
            })
        }
    }
    return(
        <>
                {/* section start */}
                <section className="mb-5 pt-4">
                    <div className="collection-wrapper">
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                            <div className="col-md-12 mb-2">
                                    <div className="tab-product product-right product-description-box">
                                        <div className="d-flex justify-content-between">
                                            <h2>{getCartProduct == null || getCartProduct == '' ? '' : getCartProduct.Product.product_name}</h2>
                                            <button disabled={true} type="button" className="btn btn-primary btn-sm">
                                                Download
                                            </button>
                                        </div> 
                                    </div>
                                </div>
                                <div className="product-slick">
                                    <div>
                                        {getUrl == '' ? <><p className='text-center'>Video not uploaded</p></> :
                                            <>
                                                <iframe style={{ width: "100%", height: "70vh",  }} src={baseUrl+'/'+getUrl} allow=" encrypted-media" allowFullScreen />
                                                <p className='p-4'><b>{getLanguage}</b> - V {getVerson}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {/* Nav tabs */}
                                        <ul className="nav nav-tabs" role="tablist">
                                            {getCartProduct == null || getCartProduct == '' ? '' : 
                                                <>
                                                    {getCartProduct.cart_product_languages?.map ? getCartProduct.cart_product_languages.map((language:any, i:any) => 
                                                        <>  
                                                            <p style={{display:'none'}}>{i=Number(i)+1}</p>
                                                            <li className="nav-item">                                                            
                                                                {i == 1 ?
                                                                    <a  className={"nav-link active"} data-toggle="tab" href={"#language"+language.id}>{language.language.language_name}</a>  
                                                                : 
                                                                    <a  className={"nav-link"} data-toggle="tab" href={"#language"+language.id}>{language.language.language_name}</a>
                                                                }                                                            
                                                            </li>
                                                        </>
                                                    )
                                                    :''}
                                                </>
                                            }
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content">
                                            {getCartProduct == null || getCartProduct == '' ? '' : 
                                                <>
                                                    {getCartProduct.cart_product_languages?.map ? getCartProduct.cart_product_languages.map((language:any, i:any, classActive:any) => 
                                                        <>  
                                                            <p style={{display:'none'}}>{i=Number(i)+1}{classActive = ''}
                                                                {i == 1 && <>{classActive = 'active'}</>}
                                                            </p>
                                                            
                                                            <div id={"language"+language.id} style={{overflowX:'auto'}} className={"container tab-pane "+classActive}><br />
                                                                <div style={{display:'inline-flex'}}>
                                                                    {getVersonData != null ? 
                                                                        <>
                                                                            {getVersonData?.map ? getVersonData.map((verson:any, i) => 
                                                                                <>
                                                                                    {language.language.id == verson.language_id ? 
                                                                                        <>  
                                                                                            <a key={i} href='#' onClick={(e) => setUrl(verson.assets_name)} style={{width:'100px', marginLeft:'5px', marginBottom:'5px'}} className='img-fluid blur-up lazyload'>
                                                                                                <img style={{width:'100%', marginBottom:'5px'}}   src="/assets2/images/pro3/1.jpg" alt="" className="" />
                                                                                                <p className='text-center'>V {verson.version_number}</p>
                                                                                            </a>
                                                                                        </>
                                                                                    : ''}
                                                                                </>
                                                                            ) : ''}
                                                                        </>                                                            
                                                                    : ''}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                    :''}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="col-md-12 mb-2">
                                    <div className="pt-0 tab-product product-right product-description-box">
                                        <div className="d-flex justify-content-between">
                                            <h2>Product Details</h2>
                                        </div> 
                                    </div>
                                    <div className="border-product">
                                    <h6 className="product-title">{getCartProduct == null || getCartProduct == '' ? '' : getCartProduct.Product.short_description}</h6>
                                </div>
                                <div className="single-product-tables border-product detail-section">
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                        {getCartProduct.Cart_product_details?.map ? getCartProduct.Cart_product_details.map((data:any) => 
                                            <>
                                                {data.Product_option_values?.map ? data.Product_option_values.map((getValues:any) => 
                                                    <>
                                                        <tr>
                                                            <td>{getValues.Product_option.option_name}</td>
                                                            <td>{getValues.option_value}</td>
                                                        </tr>
                                                    </>
                                                ) : ''}
                                            </>
                                        ):''}
                                        <tr>
                                            <td>Languages:</td>
                                            <td>
                                                {getCartProduct.cart_product_languages?.map ? getCartProduct.cart_product_languages.map((data:any) => 
                                                    <>
                                                    {data.language.language_name}, &nbsp;
                                                    
                                                    </>
                                                ):''}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4">
                            <div className="product-right product-form-box p-1">
                                <h3>Comment</h3>
                                <ul className="comment-section scroll mb-2">
                                    {getVersonComments == null ? '' : 
                                        <>
                                            {getVersonComments?.map ? getVersonComments.map((versonData:any, i) => 
                                                <li key={i}>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            {versonData.user_type == 'user' && 
                                                                <a href="#"><h5><i className="fa fa-user" aria-hidden="true" />  {versonData.user.user_name} &nbsp;<span>({ (new Date(versonData.createdAt)).toDateString() } : { (new Date(versonData.createdAt)).toLocaleTimeString() })</span></h5></a>
                                                            }
                                                            {versonData.user_type == 'admin' && 
                                                                <a href="#"><h5><i className="fa fa-user" aria-hidden="true" />  {versonData.admin.user_name} &nbsp;<span>({ (new Date(versonData.createdAt)).toDateString() } : { (new Date(versonData.createdAt)).toLocaleTimeString() })</span></h5></a>
                                                            }
                                                            <p>{versonData.comment}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ) : ''}
                                        </>
                                    }
                                </ul>
                                <ul>
                                    <li style={{width: '100%', textAlign: 'end'}}>
                                        <form>
                                            <div className="form-group mb-2" style={{marginBottom: 0}}>
                                                <textarea className="form-control" rows={1} id="comment" name="text" defaultValue={""} />
                                            </div>
                                            <button type="button" onClick={() => submitComment('comment')} className="btn btn-primary">Post Comment</button>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                {/* Section ends */}


        </>
    )
}

function componentDidMount() {
    throw new Error('Function not implemented.');
}
