import { Link } from "react-router-dom";
import { useEffect as Useeffect } from "react";

const UseScript = (url: string) => {
    Useeffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
};


export default function footer(){
        
    UseScript('/assets/js/jquery.min.js');
    UseScript('/assets/js/plugins.js');
    UseScript('/assets/js/scripts.js');
        return(
            <>
                
                <div className="height-emulator fl-wrap" />
                <footer className="main-footer fixed-footer">
                    {/*footer-inner*/}
                    <div className="footer-inner fl-wrap">
                    <div className="container">
                        <div className="partcile-dec" data-parcount={90} />
                        <div className="row">
                        <div className="col-md-2">
                            <div className="footer-title fl-wrap">
                            <span>Like Us</span>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="footer-header fl-wrap"><span>Keep</span>In Touch</div>
                            <div className="footer-box fl-wrap">
                            {/*                            <div class="twitter-swiper-container fl-wrap" id="twitts-container"></div>*/}
                            <p>We would love to hear your feedback and keep sharing your compliments</p>
                            <Link to="/contact_us" className="btn float-btn flat-btn color-btn">Contact</Link>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="footer-header fl-wrap">FIND US</div>
                            <div className="footer-box fl-wrap">
                            <div className="footer-contacts fl-wrap">
                                <ul>
                                <li><i className="fal fa-phone" /><span>Address</span><a href="#">3131
                                    Chestnut Ave,
                                    Brooklyn, NY 11238, USA</a>
                                </li>
                                <li><i className="fal fa-envelope" /><span>Email :</span><a href="#">hello@Arkwrx.com</a>
                                </li>
                                <li><i className="fal fa-map-marker" /><span>Phone : </span><a href="#">+1 518 719
                                    0102
                                    </a>
                                </li>
                                </ul>
                            </div>
                            <div className="footer-contacts fl-wrap">
                                <ul>
                                <li><i className="fal fa-phone" /><span>Address</span><a href="#">Hitech City, Main
                                    Westin Hotel.</a>
                                </li>
                                <li><i className="fal fa-envelope" /><span>Email :</span><a href="#">hello@Arkwrx.com</a>
                                </li>
                                <li><i className="fal fa-map-marker" /><span>Phone : </span><a href="#">+1 518 719
                                    0102
                                    </a>
                                </li>
                                </ul>
                            </div>
                            {/* nav-contacts end */}
                            {/* footer-socilal */}
                            <div className="footer-socilal fl-wrap">
                                <ul>
                                    <li><Link to="https://www.facebook.com/Arkwrx" target="_blank"><i className="fab fa-facebook-f" /></Link></li>
                                    <li><Link to="https://www.instagram.com/Arkwrx/" target="_blank"><i className="fab fa-instagram" /></Link></li>
                                    <li><Link to="https://www.google.com/search?q=Arkwrx&oq=Arkwrx" target="_blank"><i className="fab fa-google" /></Link></li>
                                </ul>
                            </div>
                            {/* footer-socilal end */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/*footer-inne endr*/}
                    {/*subfooter*/}
                    <div className="subfooter fl-wrap">
                    <div className="container">
                        {/* policy-box*/}
                        <div className="policy-box">
                        <span>© Arkwrk 2022 / All rights reserved. </span>
                        </div>
                        {/* policy-box end*/}
                        <a href="#" className="to-top color-bg"><i className="fal fa-angle-up" /><span /></a>
                    </div>
                    </div>
                    {/*subfooter end*/}
                </footer>
                {/* footer end*/}
                {/* contact-btn */}
                <a className="contact-btn color-bg" href="/main"><i className="fal fa-shopping-cart" /><span>Live Shop</span></a>
                {/* contact-btn end */}

            </>
        )
    
}