import Loader from '../loader';
import LeftNav from '../navs/left_nav';
import MainNav from '../navs/main_nav';
import OrderContent from './order_content';
import Footer from '../footer/footer';
import Header from '../header/header';

export default function OrderList() {
    return (
        <>
            <div id='LandingPage'>
                <Loader/>
                <Header/>
                <div id="main">
                    <div id="wrapper">
                        <LeftNav/>
                        <MainNav/>
                        <div id="wrapper" className="single-page-wrap">
                            <OrderContent/>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
